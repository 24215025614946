import { connect } from 'react-redux';
import clsx from 'clsx';

import { InfoTooltip } from 'components/v3';
import SmartFixIndicator from 'components/SmartFix/SmartFixIndicator';

import styles from 'components/sidebar/sidebar.scss';

import KnowledgeGraphIcon from 'svg/knowledge-graph.svg';
import { LinkWithQuery } from 'components/LinkWithQuery';
import { veezooRoutes } from 'components/app/routes';

import useMediaQuery from 'utils/mediaQueries';

const KnowledgeGraphTab = ({
  showGraph,
  disabled,
  kgMeta,
  hasWritePermission,
  sidebarIsMinimized,
  suggestionsForBadNames,
  t
}) => {
  const isMobile = useMediaQuery();

  const hasBadNames = Object.keys(suggestionsForBadNames).length > 0;

  const button = (
    <button
      className={clsx('button-secondary', showGraph ? styles.selected : '', styles.sidebarButton)}
      disabled={disabled}
      data-test="KnowledgeGraphSidebarOpen"
    >
      <KnowledgeGraphIcon className={clsx(styles.opaque, disabled ? 'disabled' : '')} />
      {(!sidebarIsMinimized || isMobile) && (
        <span className={clsx(styles.sidebarButtonLabel, styles.opaque, disabled ? 'disabled' : '')}>
          {t('sidebar.knowledge-graph')}
        </span>
      )}
      {hasWritePermission && hasBadNames && (
        <SmartFixIndicator
          className={clsx(styles.smartFixIndicator, sidebarIsMinimized ? '' : styles.expandedSidebar)}
        />
      )}
    </button>
  );
  if (disabled) return button;
  return (
    <LinkWithQuery
      data-overview-tutorial="knowledge-graph"
      className={styles.link}
      id="KnowledgeGraphIconHook"
      to={veezooRoutes.chat}
      updateSearchParams={{ id: kgMeta?.id, kgSidebar: true }}
    >
      <InfoTooltip text={t('tooltip.kg-icon')} placement="right">
        {button}
      </InfoTooltip>
    </LinkWithQuery>
  );
};

const mapStateToProps = state => ({
  kgMeta: state.knowledgeGraphMeta.meta,
  hasWritePermission: state.knowledgeGraphMeta.meta.hasWritePermission,
  suggestionsForBadNames: state.recipes.suggestionsForBadNames
});

export default connect(mapStateToProps)(KnowledgeGraphTab);
