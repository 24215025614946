import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@material-ui/core/Checkbox';
import Progress from 'studio/components/Progress';

import useMediaQuery from 'utils/mediaQueries';

import TextField from 'studio/components/TextField';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Alert from 'studio/components/Alert';
import SampleRowsTable from 'components/TableImport/SampleRowsTable';
import services from 'services';
import _ from 'lodash';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import TreeItem from '@material-ui/lab/TreeItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useTreeStyles from 'components/Tree/Tree.styles';
import classNames from 'classnames';
import { Divider } from '@material-ui/core';
import { pluralize as plrl } from 'studio/helpers';

import { InfoTooltip } from 'components/v3';

import {
  useListItemStyles,
  useListItemTextStyles,
  useTypographyItemStyles,
  useCheckboxStyles,
  useListItemIconStyles
} from './TableImport.styles';

import styles from './index.module.scss';

const formatFolders = folders => {
  return folders.map(item => ({
    schemaName: item.schemaName,
    databaseName: item.databaseName,
    // Currently we only display 1 level, either schema or database. If schema is shown, database is implicit.
    name: item.schemaName || item.databaseName,
    tables: undefined,
    isLoadingTables: false,
    numberOfImportedTables: item.numberOfImportedTables,
    expanded: false
  }));
};

const formatTables = tables => {
  return tables.map(item => ({
    name: item.data.name,
    alreadyImported: item.data.alreadyImported,
    numberOfImportedColumns: item.data.numberOfImportedColumns,
    columns: undefined
  }));
};

const formatColumns = (columns, checked) => {
  return columns.map(item => ({
    name: item.data.name,
    tableName: item.data.tableName,
    alreadyImported: item.data.alreadyImported || false,
    checked: checked || false,
    sampleValues: item.data.sampleValues
  }));
};

const getTotalNumberOfColumnsSelectedForImport = databaseObjects => {
  let total = 0;
  databaseObjects
    .flatMap(folder => folder.tables || [])
    .forEach(table => {
      total += table.columns?.filter(item => !!item.checked && !item.alreadyImported)?.length || 0;
    });
  return total;
};

const moreThanMaxColumnsSelected = (databaseObjects, oneStepImportLimit) =>
  getTotalNumberOfColumnsSelectedForImport(databaseObjects) > oneStepImportLimit;

const getColumnsText = table => {
  const imported = table.numberOfImportedColumns;
  const selected = table.columns?.filter(item => !!item.checked && !item.alreadyImported)?.length || 0;

  if (imported > 0) {
    const importedColumnsText = `${imported} ${plrl(imported, 'column')} imported`;
    if (selected < 1) return importedColumnsText;
    return importedColumnsText + `, ${selected} selected`;
  } else {
    if (selected < 1) return null;
    const total = table.columns.length;
    if (selected >= total) return 'All columns selected';
    return `${selected} ${plrl(selected, 'column')} selected`;
  }
};

const ColumnsList = ({ columns, checkColumn, checkboxColor, disabled }) => {
  const isMobile = useMediaQuery();
  const listItemClasses = useListItemStyles();
  const listItemTextClasses = useListItemTextStyles({ isMobile });
  const checkboxClasses = useCheckboxStyles({ isMobile, checkboxColor });
  const listItemIconClasses = useListItemIconStyles({ isMobile });

  return columns.map((column, index) => (
    <ListItem
      classes={listItemClasses}
      disabled={disabled || column.alreadyImported}
      dense
      button
      key={`list_item_${index}`}
      onClick={!disabled ? () => checkColumn(column) : () => {}}
    >
      <ListItemIcon classes={listItemIconClasses}>
        <Checkbox
          classes={checkboxClasses}
          edge="start"
          tabIndex={-1}
          disableRipple
          size="small"
          checked={column.alreadyImported || column.checked}
          onChange={() => checkColumn(column)}
          onClick={event => event.stopPropagation()}
          disabled={disabled || column.alreadyImported}
        />
      </ListItemIcon>
      <ListItemText primary={column.name} classes={listItemTextClasses} />
    </ListItem>
  ));
};

const CustomTreeItem = ({ id, disabled, selected, ...props }) => {
  // Use styles of our Tree component, but not the whole component, as it has some unnecessary features and dependencies
  //  (in particular the ContextMenu has to be provided)
  const treeItemClasses = useTreeStyles();
  return (
    <TreeItem
      nodeId={id}
      disabled={disabled}
      className={classNames({
        selected: selected,
        disabled: disabled
      })}
      classes={{
        // Use the same classes as our Tree component
        root: treeItemClasses.item,
        content: treeItemClasses.content,
        expanded: treeItemClasses.expanded,
        selected: treeItemClasses.selected,
        group: treeItemClasses.group,
        label: treeItemClasses.label
      }}
      {...props}
    />
  );
};

/**
 * Component that allows to select tables and columns from a database to import into a knowledge graph.
 *
 * Important: make sure the arguments are not changed unnecessarily to avoid a re-render of the outer component,
 * as otherwise we might enter an infinite loop due to the setImportFunction callback.
 * In particular, handleFetchingError and handleFolderFetchingError should not be changed (use useCallback).
 *
 * @param useSchemas whether the DB supports / uses schemas
 * @param disabled whether the component should be disabled (e.g. because the import is currently running)
 * @param knowledgeGraph the knowledge graph to import the tables into
 * @param checkboxColor overwrite for the color of the checkbox when checked
 * @param setImportFunction callback that is called with the import function when the import is possible or undefined otherwise.
 *                          This allows the outer component to do the import and use the state inside.
 *                          It calls the import endpoint and handles the state inside after importing.
 * @param handleFetchingError callback taking the fetch result and a message that is called when an error occurs when querying the backend
 * @param handleFolderFetchingError callback that is called when an error occurs when querying the backend for the folder structure (and not nested tables).
 *                                  This callback is always called after handleFetchingError, and allows to handle the error differently,
 *                                  e.g. when the DB connection has not been set up correctly.
 * @param dataTest data-test attribute for the root element
 */
const TableImport = ({
  usesSchemas,
  disabled,
  knowledgeGraph,
  checkboxColor,
  setImportFunction,
  handleFetchingError,
  handleFolderFetchingError,
  dataTest
}) => {
  const [oneStepImportLimit, setOneStepImportLimit] = useState(null);

  // List of folders containing tables, with in turn contain columns
  const [databaseObjects, setDatabaseObjects] = useState([]);

  // Folders in form { databaseName, schemaName } that have been expanded in the tree view on the left
  const [expandedFolders, setExpandedFolders] = useState([]);
  // Table that has been selected to import columns from
  // Contains an object of the form { databaseName, schemaName, tableName } if a table is selected, schemaName is optional
  const [selectedTable, setSelectedTable] = useState(null);

  // Indicator whether root of left panel is being loaded (contains folders and tables)
  const [loadingLeftPanel, setLoadingLeftPanel] = useState(true);
  // Indicator whether columns are being loaded for a table (in the right panel)
  const [loadingColumns, setLoadingColumns] = useState(false);

  // Search queries for tables/folders and columns
  const [tableAndFolderSearchQuery, setTableAndFolderSearchQuery] = useState('');
  const [columnSearchQuery, setColumnSearchQuery] = useState('');

  const isMobile = useMediaQuery();
  const itemTypographyClasses = useTypographyItemStyles();
  const checkboxClasses = useCheckboxStyles({ isMobile, checkboxColor });

  const LoaderContainer = () => (
    <div className={styles.loaderContainer}>
      <Progress />
    </div>
  );

  // Modifies a folder in the state
  const modifyFolder = useCallback((databaseName, schemaName, modifier) => {
    setDatabaseObjects(previous =>
      previous.map(folder =>
        folder.schemaName === schemaName && folder.databaseName === databaseName ? modifier(folder) : folder
      )
    );
  }, []);

  // Modifies a table in the state
  const modifyTable = useCallback((databaseName, schemaName, tableName, modifier) => {
    const mapTables = tables => tables?.map(table => (table.name === tableName ? modifier(table) : table));
    modifyFolder(databaseName, schemaName, folder => ({ ...folder, tables: mapTables(folder.tables) }));
  }, []);

  // Fetches the database tables
  const fetchDatabaseTables = useCallback(
    async (databaseName, schemaName) => {
      // Mark folder as being loaded, this is also indicated in the frontend
      modifyFolder(databaseName, schemaName, schema => ({ ...schema, isLoadingTables: true }));

      const result = await services.getDatabaseTables(knowledgeGraph.id, databaseName, schemaName);

      if (result.success) {
        // Put tables inside the folder
        modifyFolder(databaseName, schemaName, folder => ({
          ...folder,
          isLoadingTables: false,
          tables: formatTables(result.data.data)
        }));
      } else {
        const message = result?.response?.data?.data?.message || 'An error occurred while loading tables.';
        handleFetchingError(result, message);
        modifyFolder(databaseName, schemaName, schema => ({ ...schema, isLoadingTables: false, tables: [] }));
      }
    },
    [knowledgeGraph, handleFetchingError, handleFolderFetchingError]
  );

  const fetchDatabaseFolders = useCallback(async () => {
    setLoadingLeftPanel(true);
    const result = await services.getDatabaseFolders(knowledgeGraph.id);
    setLoadingLeftPanel(false);
    if (result.success) {
      const folders = result.data.data.map(item => item.data);
      setDatabaseObjects(formatFolders(folders));

      // Expand folder if there is only one with tables already imported or if there is only one folder in total
      const alreadyImportedFolders = folders.filter(folder => folder.numberOfImportedTables > 0);
      let folderToExpand;
      if (alreadyImportedFolders.length === 1) {
        folderToExpand = alreadyImportedFolders[0];
      } else if (folders.length === 1) {
        folderToExpand = folders[0];
      }

      // Expand folder if there is only one with tables already imported
      setExpandedFolders(previousExpandedFolders => {
        const filteredExpandedFolders = previousExpandedFolders.filter(item =>
          folders.some(folder => folder.databaseName === item.databaseName && folder.schemaName === item.schemaName)
        );
        if (filteredExpandedFolders.length === 0 && folderToExpand) {
          fetchDatabaseTables(folderToExpand.databaseName, folderToExpand.schemaName);
          return [folderToExpand];
        } else {
          return filteredExpandedFolders;
        }
      });
    } else {
      const message = result?.response?.data?.data?.message || 'An error occurred while loading schemas.';
      handleFetchingError(result, message);
      handleFolderFetchingError?.();
    }
  }, [knowledgeGraph, handleFetchingError, handleFolderFetchingError, fetchDatabaseTables]);

  // Fetches the import limit (the number of columns that can be imported in one step)
  const fetchOneStepImportLimit = useCallback(async () => {
    const result = await services.getImportConfiguration();
    if (result.success) {
      setOneStepImportLimit(result.data?.data?.maxColumnsPerImport);
    } else {
      const message = result?.response?.data?.data?.message || 'An error occurred while loading import limit.';
      handleFetchingError(result, message);
    }
  }, [knowledgeGraph, handleFetchingError]);

  // Fetches the database columns for a table
  const fetchDatabaseColumns = useCallback(
    async (databaseName, schemaName, tableName, checked) => {
      setLoadingColumns(true);
      const result = await services.getDatabaseColumns(knowledgeGraph.id, databaseName, schemaName, tableName);
      setLoadingColumns(false);
      if (result.success) {
        modifyTable(databaseName, schemaName, tableName, table => ({
          ...table,
          columns: formatColumns(result.data.data, checked)
        }));
      } else {
        const message = result?.response?.data?.data?.message || 'An error occurred while loading columns.';
        handleFetchingError(result, message);
      }
    },
    [knowledgeGraph, handleFetchingError]
  );

  // Paths of the selected columns (expected by the API)
  const selectedColumnPaths = useMemo(() => {
    let tablePathsAndColumns = databaseObjects.flatMap(
      folder =>
        folder.tables?.map(table => ({
          path: [folder.databaseName, ...(folder.schemaName ? [folder.schemaName] : []), table.name],
          columns: table.columns
        })) || []
    );
    return tablePathsAndColumns.flatMap(
      ({ path, columns }) =>
        columns?.filter(item => !!item.checked && !item.alreadyImported)?.map(item => [...path, item.name]) || []
    );
  }, [databaseObjects]);

  // Import is disabled if we are currently loading something, or no or too many columns are selected
  const importDisabled =
    loadingLeftPanel ||
    loadingColumns ||
    selectedColumnPaths.length === 0 ||
    moreThanMaxColumnsSelected(databaseObjects, oneStepImportLimit);

  // Calls the backend to import the selected tables/columns
  const importFunction = useCallback(async () => {
    const response = await services.importKnowledgeGraphTables(knowledgeGraph.id, selectedColumnPaths);
    if (response.success) {
      if (response.data.messages.some(item => item.isSuccess)) {
        // Reset state
        setSelectedTable(null);
        setExpandedFolders([]);
        fetchDatabaseFolders();
      }
    }
    return response;
  }, [selectedColumnPaths, knowledgeGraph, fetchDatabaseFolders]);

  // Give outer component access to the import function and also notify it when the import is possible
  useEffect(() => {
    if (importDisabled) {
      setImportFunction(undefined);
    } else {
      // Need to wrap in a function as setting state will call passed functions with the previous state
      setImportFunction(() => importFunction);
    }
  }, [importDisabled, setImportFunction, importFunction]);

  // if the knowledge graph id changes, reset the selected table and expanded folders
  useEffect(() => {
    setSelectedTable(null);
    setExpandedFolders([]);
  }, [knowledgeGraph?.id]);

  // Fetch the import limit when the component is mounted
  useEffect(() => {
    fetchOneStepImportLimit();
  }, []);

  // Fetch the root of the left panel when the component is mounted or certain dependencies change (in particular the KG)
  useEffect(() => {
    fetchDatabaseFolders();
  }, [fetchDatabaseFolders]);

  // Filtered columns of the selected table
  const transformedColumns = useMemo(() => {
    if (!selectedTable) {
      return [];
    } else {
      const tables =
        databaseObjects.find(
          folder => folder.schemaName === selectedTable.schemaName && folder.databaseName === selectedTable.databaseName
        )?.tables || [];
      const table = tables.find(table => table.name === selectedTable.tableName);

      return (
        table?.columns?.filter(column => column.name.toLowerCase().includes(columnSearchQuery.toLowerCase())) || []
      );
    }
  }, [databaseObjects, selectedTable, columnSearchQuery]);

  // Checks or unchecks a column in the right panel
  const checkColumn = useCallback(
    column => {
      modifyTable(selectedTable.databaseName, selectedTable.schemaName, selectedTable.tableName, table => {
        return {
          ...table,
          columns: table.columns.map(item => {
            if (item.name === column.name) {
              return { ...item, checked: !item.checked && !item.alreadyImported };
            } else {
              return item;
            }
          })
        };
      });
    },
    [selectedTable]
  );

  // Selects the table given by schemaName and tableName and fetches its columns
  // Third argument checked allows to set the checked state of all columns of a table
  const selectTable = useCallback(
    (databaseName, schemaName, table, checked = null) => {
      setSelectedTable({ databaseName, schemaName, tableName: table.name });

      if (!table.columns) {
        return fetchDatabaseColumns(databaseName, schemaName, table.name, checked || false);
      }

      if (checked !== null) {
        modifyTable(databaseName, schemaName, table.name, table => ({
          ...table,
          columns: table.columns.map(item => ({ ...item, checked }))
        }));
      }
    },
    [fetchDatabaseColumns]
  );

  // Expands/collapse a folder in the left panel and fetches its tables if necessary
  const toggleFolder = useCallback(
    (databaseName, schemaName) => {
      const expandedFolder = expandedFolders.find(
        folder => folder.databaseName === databaseName && folder.schemaName === schemaName
      );
      if (expandedFolder) {
        setExpandedFolders(expandedFolders.filter(item => item !== expandedFolder));
      } else {
        if (
          databaseObjects.find(folder => folder.databaseName === databaseName && folder.schemaName === schemaName)
            ?.tables === undefined
        ) {
          fetchDatabaseTables(databaseName, schemaName);
        }
        setExpandedFolders([...expandedFolders, { databaseName, schemaName }]);
      }
    },
    [expandedFolders, fetchDatabaseTables]
  );

  // The text field for the folder/table search query
  const tableAndFolderSearchBox = (
    <div className={styles.searchBoxContainer}>
      <TextField
        startIcon={<SearchRoundedIcon />}
        name="search tables"
        placeholder="Search schemas and tables"
        label=""
        value={tableAndFolderSearchQuery}
        margin="none"
        size="small"
        onChange={event => setTableAndFolderSearchQuery(event.target.value)}
      />
    </div>
  );
  // The text field for the column search query
  const columnSearchBox = (
    <div className={styles.searchBoxContainer}>
      <TextField
        startIcon={<SearchRoundedIcon />}
        name="search columns"
        placeholder="Search columns"
        value={columnSearchQuery}
        margin="none"
        size="small"
        onChange={event => setColumnSearchQuery(event.target.value)}
      />
    </div>
  );

  function getFolderTreeItemId(folder) {
    return `folder-${folder.databaseName}-${folder.schemaName}`;
  }

  function getTableTreeItemId(folder, tableName) {
    return `table-${folder.databaseName}-${folder.schemaName}-${tableName}`;
  }

  // Returns the tree item for a table, or undefined if the table does not match the search query
  const getTableTreeItem = (folder, table) => {
    if (table.name.toLowerCase().includes(tableAndFolderSearchQuery.toLowerCase())) {
      const checkbox = (
        <Checkbox
          classes={checkboxClasses}
          edge="start"
          tabIndex={-1}
          disableRipple
          size="small"
          onClick={event => event.stopPropagation()}
          onChange={() => selectTable(folder?.databaseName, folder?.schemaName, table, !getColumnsText(table))}
          checked={table.alreadyImported || !!getColumnsText(table)}
          disabled={disabled || table.alreadyImported}
          data-test="tableAllColumnsCheckbox"
          data-test-table-name={table.name}
        />
      );
      const content = (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: '10px' }}>
          {checkbox}
          <Typography variant="body1" classes={itemTypographyClasses}>
            {table.name}
          </Typography>
          <Typography variant="body2" classes={itemTypographyClasses}>
            {getColumnsText(table)}
          </Typography>
        </div>
      );
      return (
        <CustomTreeItem
          id={getTableTreeItemId(folder, table.name)}
          key={table.name}
          label={content}
          onClick={!disabled ? () => selectTable(folder?.databaseName, folder?.schemaName, table) : () => {}}
          disabled={disabled}
          selected={
            selectedTable?.databaseName === folder?.databaseName &&
            selectedTable?.schemaName === folder?.schemaName &&
            selectedTable?.tableName === table.name
          }
        />
      );
    } else {
      return undefined;
    }
  };

  // Returns the tree item for a folder, or undefined if the folder does not match the search query
  const getFolderTreeItem = folder => {
    const queryMatchesFolderOrTableInside =
      folder.name.toLowerCase().includes(tableAndFolderSearchQuery.toLowerCase()) ||
      folder.tables?.some(table => table.name.toLowerCase().includes(tableAndFolderSearchQuery.toLowerCase()));

    if (queryMatchesFolderOrTableInside) {
      // Icon to use when the folder tables are being loaded
      const progress = folder.isLoadingTables && <CircularProgress size={10} color="inherit" />;

      // Note: different from stub that indicates tables haven't been loaded yet
      const noTablesStub = (
        <CustomTreeItem
          id={`stub-${folder.name}`}
          disabled={true}
          label={
            <Typography variant="body1" classes={itemTypographyClasses}>
              {folder.tables?.length === 0 ? 'No tables in this schema' : 'No table matches the search'}
            </Typography>
          }
        />
      );

      const tableItems = _.compact(folder.tables?.map(table => getTableTreeItem(folder, table)));
      return (
        <CustomTreeItem
          id={getFolderTreeItemId(folder)}
          key={folder.name}
          label={
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: '10px' }}>
              <Typography variant="body1" classes={itemTypographyClasses}>
                {folder.name}
              </Typography>
              <Typography variant="body2" classes={itemTypographyClasses}>
                {folder.numberOfImportedTables === 1
                  ? '1 table imported'
                  : folder.numberOfImportedTables > 0 && `${folder.numberOfImportedTables} tables imported`}
              </Typography>
            </div>
          }
          onClick={() => toggleFolder(folder.databaseName, folder.schemaName)}
          onIconClick={() => toggleFolder(folder.databaseName, folder.schemaName)}
          expandIcon={progress}
          collapseIcon={progress}
          disabled={disabled}
          data-test-folder-name={folder.name}
        >
          {folder.tables ? (tableItems.length === 0 ? noTablesStub : tableItems) : [<div key="stub" />] // This stub is necessary to indicate to the library that the schema has children
          }
        </CustomTreeItem>
      );
    } else {
      return undefined;
    }
  };

  // Put folders with already imported tables first
  const [foldersWithImportedTables, foldersWithoutImportedTables] = _.partition(
    databaseObjects,
    folder => folder.numberOfImportedTables > 0
  );
  const treeItems = _.compact(foldersWithImportedTables.map(folder => getFolderTreeItem(folder)))
    .concat(
      foldersWithImportedTables.length > 0 && foldersWithoutImportedTables.length > 0 ? [<Divider key="divider" />] : []
    )
    .concat(_.compact(foldersWithoutImportedTables.map(folder => getFolderTreeItem(folder))));

  const tableTree = (
    <TreeView
      expanded={expandedFolders.map(folder => getFolderTreeItemId(folder))}
      defaultCollapseIcon={<ExpandMoreRoundedIcon />}
      defaultExpandIcon={<ChevronRightRoundedIcon />}
    >
      {treeItems}
    </TreeView>
  );

  const moreThanMaxColumnsSelectedAlertMessage = (
    <Alert severity="warning">
      During a single import session you can select at most {oneStepImportLimit} columns. Please deselect some columns.
    </Alert>
  );

  const helpTooltip = (
    <InfoTooltip
      text="This is not a limit for your Knowledge Graph. You can always import more columns later."
      helpOverrideStyles={{
        root: {
          fontSize: isMobile ? '18px' : '1.3em',
          color: 'var(--normal-gray)',
          verticalAlign: 'sub',
          marginLeft: '4px'
        }
      }}
    />
  );

  const totalNumberOfColumnsSelectedForImport = useMemo(
    () => getTotalNumberOfColumnsSelectedForImport(databaseObjects),
    [databaseObjects]
  );

  return (
    <div className={styles.importContainer} data-test={dataTest}>
      {moreThanMaxColumnsSelected(databaseObjects, oneStepImportLimit) && moreThanMaxColumnsSelectedAlertMessage}
      {oneStepImportLimit && (
        <div>
          <b>Selected Columns:</b> {totalNumberOfColumnsSelectedForImport} / {oneStepImportLimit} {helpTooltip}
        </div>
      )}
      <div className={styles.searchContainer}>
        {tableAndFolderSearchBox}
        {columnSearchBox}
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.selector} data-test="tableSelector">
          {loadingLeftPanel ? <LoaderContainer /> : tableTree}
        </div>
        <div className={styles.selector}>
          {loadingColumns ? (
            <LoaderContainer />
          ) : (
            transformedColumns.length > 0 && (
              <ColumnsList
                checkboxColor={checkboxColor}
                columns={transformedColumns}
                checkColumn={checkColumn}
                disabled={disabled}
              />
            )
          )}
        </div>
      </div>

      {selectedTable && (
        <>
          <div>
            <b>Sample Rows</b>
          </div>
          <SampleRowsTable
            columns={transformedColumns}
            checkColumn={checkColumn}
            checkboxClasses={checkboxClasses}
            loadingColumns={loadingColumns}
          />
        </>
      )}
    </div>
  );
};

export default TableImport;
