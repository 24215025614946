import React, { createRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/eventTracking';

import { InfoTooltip } from 'components/v3';

import BrowseIcon from 'svg/browse.svg';

import InputModal from 'components/modals/InputModal/InputModal';
import ConfirmationModal from 'components/modals/ConfirmationModal/ConfirmationModal';

import { useConfirmUnsubscribeStyles } from './BoardDropdown.styles';

import {
  changeBoardName,
  changeFolderName,
  createBoardCopy,
  deleteBoard,
  deleteFolder,
  subscribeToBoard,
  unsubscribeFromBoard,
  unshareBoard,
  fetchBoardsList,
  MAKE_VKL_BOARD_REQUEST,
  MAKE_VKL_BOARD_FAILURE,
  MAKE_VKL_BOARD_SUCCESS
} from 'store/modules/board';

import MoreHoriz from '@mui/icons-material/MoreHoriz';

import { showSharedBoardModal } from 'store/modules/sharedModal';
import { changeEntryPoint } from 'store/modules/user';
import { entryPointTypes } from 'config/constants';
import { boardFileTypes } from 'store/utils/boardFileTypes';

import boardStyles from './mini-board.scss';
import services from 'services';
import CodeDialog from 'components/shared/CodeDialog';
import { buildStudioLink } from 'components/buttons/JumpToStudio';
import { withSnackbar } from 'notistack';

const popperConfig = {
  modifiers: {
    hide: {
      enabled: false // set this to false because of warning appearing when we have disabled preventOverflow
    },
    preventOverflow: {
      enabled: false // set this to false because otherwise the positioning is off when we have an overflow container
    }
  }
};

const initialState = {
  isDeleting: false,
  isRenaming: false,
  isCopying: false,
  isUnsubscribing: false,
  isMakingVklBoard: false,
  isPreviewingVkl: false,
  isFetchingVkl: false,
  vkl: null,
  showDropdown: false
};

const BoardDropdown = ({
  id,
  type,
  name,
  definingResource,
  kgId,
  isSharedWithOthers,
  canShare,
  canDelete,
  canRename,
  canRemoveFromSidebar,
  isBoardOwned,
  isFolder,
  canSubscribe,
  canCopy,
  canMakeEntrypoint,
  isSuperUserOrHigher,
  hasWritePermission,
  useHorizontalToggle,
  defaultEntryPoint,
  enqueueSnackbar,
  dispatch,
  t
}) => {
  const [state, setState] = useState(initialState);
  const boardOptionsRef = createRef();

  const startRenaming = () => setState(prev => ({ ...prev, isRenaming: true }));
  const stopRenaming = () => setState(prev => ({ ...prev, isRenaming: false }));

  const startPreviewingVkl = async () => {
    trackEvent('Viewing Board VKL Preview', { boardId: id });
    setState(prev => ({ ...prev, isFetchingVkl: true, isPreviewingVkl: true }));
    const result = await services.getVklBoard(id);
    setState(prev => ({ ...prev, isFetchingVkl: false, vkl: result.data }));
  };
  const stopPreviewingVkl = () => setState(prev => ({ ...prev, isPreviewingVkl: false }));

  const renameBoard = newName => {
    stopRenaming();
    if (type === boardFileTypes.folder) {
      return dispatch(changeFolderName(id, newName));
    }
    dispatch(changeBoardName(id, newName));
  };

  const startCopying = () => setState(prev => ({ ...prev, isCopying: true }));
  const stopCopying = () => setState(prev => ({ ...prev, isCopying: false }));

  const copyBoard = newName => {
    trackEvent('Copied Board via Dropdown', { boardId: id });
    stopCopying();
    dispatch(createBoardCopy(id, newName));
  };

  const startUnsubscribing = () => setState(prev => ({ ...prev, isUnsubscribing: true }));
  const stopUnsubscribing = () => setState(prev => ({ ...prev, isUnsubscribing: false }));

  const confirmSubscribeToBoard = () => {
    trackEvent('Subscribed to board via Dropdown', { boardId: id });
    dispatch(subscribeToBoard(id));
  };

  const confirmUnsubscribeFromBoard = () => {
    trackEvent('Unsubscribed from Board via Dropdown', { boardId: id });
    stopUnsubscribing();
    dispatch(unsubscribeFromBoard(id));
  };

  const switchToBoardEntryPoint = () => dispatch(changeEntryPoint(entryPointTypes.BOARD, id));

  const setDeletingState = () => setState(prev => ({ ...prev, isDeleting: true }));
  const removeDeletingState = () => setState(prev => ({ ...prev, isDeleting: false }));

  const deleteBoardOrFolder = () => {
    setState(prev => ({ ...prev, isDeleting: false }));
    if (type === boardFileTypes.folder) {
      return dispatch(deleteFolder(id));
    }
    dispatch(deleteBoard(id));
  };

  const displaySharedModal = () => {
    trackEvent('Board Sharing Modal Opened via Dropdown', { boardId: id });
    dispatch(showSharedBoardModal(id));
  };

  const makeVklBoard = (id, enqueueSnackbar) => {
    return async dispatch => {
      dispatch({ type: MAKE_VKL_BOARD_REQUEST, id });
      return services.makeVklBoard(id).then(result => {
        dispatch(fetchBoardsList());
        if (!result.success) {
          dispatch({ type: MAKE_VKL_BOARD_FAILURE });
          enqueueSnackbar(result?.response?.data?.error, {
            variant: 'error'
          });
          return;
        }
        enqueueSnackbar(result?.data?.message, {
          variant: 'success'
        });
        dispatch({
          type: MAKE_VKL_BOARD_SUCCESS,
          id: id
        });
      });
    };
  };

  const startMakingVklBoard = () => setState(prev => ({ ...prev, isMakingVklBoard: true }));
  const stopMakingVklBoard = () => setState(prev => ({ ...prev, isMakingVklBoard: false }));
  const handleMakeVklBoard = async () => {
    trackEvent('Board Made VKL via Dropdown', { boardId: id });
    stopMakingVklBoard();
    dispatch(makeVklBoard(id, enqueueSnackbar));
  };

  const openVklBoardInStudio = async () => {
    trackEvent('Open VKL Board in Studio', { boardId: id, resourceUri: definingResource });
    try {
      const response = await services.fetchResource(definingResource);
      const { file, vklQualifiedIdentifier } = response.data;
      const link = buildStudioLink(kgId, file.path, vklQualifiedIdentifier);
      window.open(link, '_blank');
    } catch (err) {
      console.error('Failed fetching resource or opening code in editor', err);
    }
  };

  const dropdownToggle = () => {
    if (!state.showDropdown) {
      setState(prev => ({ ...prev, showDropdown: true }));
    } else {
      setState(prev => ({ ...prev, showDropdown: false }));
    }
  };

  const stopSharing = () => {
    trackEvent('Board Sharing Stopped via Dropdown', { boardId: id });
    dispatch(unshareBoard(id));
  };

  // We portal the dropdown menu because the sidebar container has an overflow set
  // and this would cut-off the menu, if we would leave it in there.
  const dropDownMenu = ReactDOM.createPortal(
    <Dropdown.Menu
      role="menu"
      id="board-actions-menu"
      className={boardStyles.boardDropdownMenu}
      popperConfig={popperConfig}
    >
      {canMakeEntrypoint && (
        <>
          <Dropdown.Item
            as="button"
            role="menuitem"
            data-test="setAsEntrypoint"
            disabled={defaultEntryPoint?.id === id} // disable item if it's already the active entry point
            onClick={switchToBoardEntryPoint}
          >
            {t('dropdown.set-entrypoint')}
          </Dropdown.Item>
          <Dropdown.Divider />
        </>
      )}
      {canShare && (
        <Dropdown.Item eventKey="1" as="button" onClick={displaySharedModal} data-test="shareBoardItem">
          {isSharedWithOthers ? t('sharing.update') : t('sharing.share')}
        </Dropdown.Item>
      )}
      {canShare &&
        (isSharedWithOthers && (
          <Dropdown.Item eventKey="1" as="button" onClick={stopSharing} data-test="stopSharingDropdownItem">
            {t('sharing.stop')}
          </Dropdown.Item>
        ))}
      {canShare && <Dropdown.Divider />}
      {canRename && (
        <Dropdown.Item as="button" onClick={startRenaming} data-test="renameBoardItem">
          {t('rename')}
        </Dropdown.Item>
      )}
      {canCopy && (
        <Dropdown.Item as="button" onClick={startCopying} data-test="copyBoardItem">
          {t('create-copy')}
        </Dropdown.Item>
      )}
      {canDelete && (
        <Dropdown.Item onClick={setDeletingState} as="button" data-test="deleteBoardItem">
          {t('delete')}
        </Dropdown.Item>
      )}
      {canRemoveFromSidebar && (
        <InfoTooltip text={t('sharing.unsubscribe-description')}>
          <Dropdown.Item onClick={startUnsubscribing} as="button">
            {t('browse-boards.unsubscribe-action')}
          </Dropdown.Item>
        </InfoTooltip>
      )}
      {canSubscribe && (
        <InfoTooltip text={t('sharing.subscribe-description')}>
          <Dropdown.Item onClick={confirmSubscribeToBoard} as="button">
            {t('browse-boards.subscribe-action')}
          </Dropdown.Item>
        </InfoTooltip>
      )}
      {isSuperUserOrHigher && !isFolder && <Dropdown.Divider />}
      {isBoardOwned && hasWritePermission && isSuperUserOrHigher && !isFolder && (
        <Dropdown.Item as="button" onClick={startMakingVklBoard}>
          {t('dropdown.transform-to-board-as-code')}
        </Dropdown.Item>
      )}
      {isSuperUserOrHigher && !isFolder && !definingResource && (
        <Dropdown.Item as="button" onClick={startPreviewingVkl}>
          {t('dropdown.board-preview')}
        </Dropdown.Item>
      )}
      {definingResource && isSuperUserOrHigher && (
        <Dropdown.Item as="button" onClick={openVklBoardInStudio}>
          {t('sidebar.open-code-in-editor')} (VKL)
        </Dropdown.Item>
      )}
    </Dropdown.Menu>,
    document.body
  );

  const { classes: confirmUnsubscribeStyles } = useConfirmUnsubscribeStyles();

  // inlines the browse boards icon into the text at the position indicated by character "|" in the translation
  const unsubscribeConfirmationHint = () => {
    const parts = t('confirm-unsubscribe-hint').split('|');
    return (
      <p style={{ fontWeight: 300 }}>
        <span>{parts[0]}</span>
        <BrowseIcon className={confirmUnsubscribeStyles.browseIconInline} />
        <span>{parts[1]}</span>
      </p>
    );
  };

  return (
    <>
      <div
        className={boardStyles.boardDropdown}
        ref={boardOptionsRef}
        data-test={useHorizontalToggle ? 'boardEditDropdown' : 'boardSidebarDropdown'}
      >
        <Dropdown
          data-test="boardContextMenu"
          id="board-actions"
          alignRight={useHorizontalToggle} // horizontal toggle is used on the right side of screen, so lets' make sure the dropdown is not cut off
          // Decide if we actually want to show the menu
          onToggle={dropdownToggle}
          show={state.showDropdown}
          // Blur Dropdown Toggle after selection, otherwise it would stay active, which does not seem reasonable...
          onSelect={() => document.activeElement.blur()}
          as={ButtonGroup}
        >
          <Dropdown.Toggle drop="bottom" split title={t('board.board-actions')}>
            {useHorizontalToggle ? <MoreHoriz fontSize="large" /> : <span className="icon-dots">&nbsp;</span>}
          </Dropdown.Toggle>
          {dropDownMenu}
        </Dropdown>
      </div>
      <InputModal
        title={t('rename')}
        defaultText={name}
        open={state.isRenaming}
        onConfirm={renameBoard}
        onClose={stopRenaming}
      />
      <InputModal
        title={t('create-copy')}
        defaultText={`${name} (${t('copy')})`}
        open={state.isCopying}
        onConfirm={copyBoard}
        onClose={stopCopying}
      />
      <ConfirmationModal
        open={state.isDeleting}
        defaultText={type === boardFileTypes.folder ? t('folder-delete') : t('dashboard-delete')}
        onConfirm={deleteBoardOrFolder}
        onClose={removeDeletingState}
      />
      <ConfirmationModal
        open={state.isUnsubscribing}
        onConfirm={confirmUnsubscribeFromBoard}
        onClose={stopUnsubscribing}
      >
        <p>{t('confirm-unsubscribe')}</p>
        {unsubscribeConfirmationHint()}
      </ConfirmationModal>
      <ConfirmationModal
        open={state.isMakingVklBoard}
        title={t('dropdown.vkl.transform.confirm')}
        onConfirm={handleMakeVklBoard}
        onClose={stopMakingVklBoard}
      >
        <p style={{ fontWeight: 300 }}>
          <b>{t('dropdown.vkl.transform.consequences.title')}</b>
          <ul>
            <li>{t('dropdown.vkl.transform.consequences.creator-edit')}</li>
            <li>{t('dropdown.vkl.transform.consequences.only-studio-edit')}</li>
            <li>{t('dropdown.vkl.transform.consequences.role-share')}</li>
            <li>{t('dropdown.vkl.transform.consequences.kg-share')}</li>
            <li>{t('dropdown.vkl.transform.consequences.lose-ownership')}</li>
            <li>{t('dropdown.vkl.transform.consequences.dev-branches')}</li>
          </ul>
        </p>
      </ConfirmationModal>
      <CodeDialog
        title={t('dropdown.board-preview')}
        open={state.isPreviewingVkl}
        onClose={stopPreviewingVkl}
        code={state.isFetchingVkl ? t('loading') : state.vkl}
        language="vkl"
      />
    </>
  );
};

const mapStateToProps = state => ({
  defaultEntryPoint: state.knowledgeGraphMeta.meta.defaultEntryPoint,
  isSuperUserOrHigher: state.user.isSuperUser || state.user.isAdmin,
  hasWritePermission: state.knowledgeGraphMeta.meta.hasWritePermission,
  kgId: state.knowledgeGraphMeta.meta.id
});

export default withSnackbar(withRouter(connect(mapStateToProps)(BoardDropdown)));
