import { useTheme } from '@mui/material/styles';

const fontFamily = 'var(--font-family)';
const highlightedSignup = 'var(--light-blue)';

export const useTextFieldStyles = ({
  isMobile,
  highlighted,
  multiline,
  inline,
  disabled,
  disabledWhenFilled,
  customClasses
}) => {
  const theme = useTheme();
  return {
    signup: {
      '&.MuiTextField-root': {
        width: '100%'
      },
      '& .MuiInput-input': {
        fontFamily,
        fontSize: isMobile ? '16px' : '20px',
        color: highlighted ? highlightedSignup : 'inherit',
        padding: '6px 0',
        lineHeight: multiline ? '22px' : 'inherit'
      },
      '& .MuiInput-input.Mui-disabled': {
        color: highlighted ? highlightedSignup : 'rgba(0, 0, 0, 0.18)',
        WebkitTextFillColor: highlighted ? highlightedSignup : 'rgba(0, 0, 0, 0.18)',
        ...(disabled ? { disabled } : {})
      },
      '& .MuiInputLabel-root': {
        fontFamily,
        fontSize: isMobile ? '16px' : '20px',
        whiteSpace: 'nowrap',
        '&&+.MuiInput-formControl': {
          marginTop: '16px'
        }
      },
      '& .MuiFormHelperText-root': {
        fontSize: '12px',
        lineHeight: 'inherit'
      },
      '& .MuiFormHelperText-contained': {
        display: 'flex',
        alignItems: 'center'
      },
      '& .MuiInput-underline:before': {
        borderBottom: disabled ? 'solid thin var(--disabled-color)' : 'solid thin black',
        borderBottomStyle: 'solid !important'
      }
    },
    veezoo: {
      '& .MuiOutlinedInput-input': {
        fontFamily,
        fontSize: inline ? '12px' : '14px',
        padding: inline ? '5px 8px' : '8px 12px',
        color: 'inherit',
        lineHeight: '20px',
        ...(multiline ? {} : { height: inline ? '21px' : '32px', boxSizing: 'border-box' })
      },
      '& .MuiOutlinedInput-root': {
        ...(theme.isDark ? {} : { backgroundColor: 'white' })
      },
      '& .MuiOutlinedInput-notchedOutline': {
        top: 0,
        border: 'solid thin var(--light-gray)',
        borderRadius: '4px',
        '& > legend': {
          display: 'none'
        },
        ...(customClasses['MuiOutlinedInput-notchedOutline'] ? customClasses['MuiOutlinedInput-notchedOutline'] : {})
      },
      '& .MuiFormHelperText-root': {
        fontSize: '12px'
      },
      '& .MuiInputLabel-shrink': {
        transform: 'none !important',
        position: 'static'
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: 'transparent',
        ...(disabledWhenFilled ? { color: '#2e8dd5 !important' } : '')
      }
    },
    default: {
      '& .MuiOutlinedInput-input': {
        color: highlighted ? theme.palette.primary.main : 'inherit',
        padding: '8.5px 14px',
        height: '1.4375em'
      },
      '& .MuiOutlinedInput-input.Mui-disabled': {
        backgroundColor: 'transparent',
        ...(highlighted ? { color: theme.palette.primary.main, WebkitTextFillColor: theme.palette.primary.main } : {})
      },
      '& .MuiOutlinedInput-root': {
        ...(theme.isDark ? {} : { backgroundColor: 'white' })
      },
      '& .MuiFormHelperText-contained': {
        display: 'flex',
        alignItems: 'center'
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -5px) scale(0.75) !important',
        display: 'flex',
        alignItems: 'center'
      }
    }
  };
};
