import { useMemo, memo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import { setEntitiesSidebar } from 'store/modules/graph/graph';

import Title from 'components/v2/Typography/Title';

import { Chip, InfoTooltip } from 'components/v3';

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import getTopTenItems from 'utils/getTopTenItems';

import styles from '../styles.scss';

const NodeUniqueValues = ({ uniqueId, node, hideSearch, dispatch, t }) => {
  const formattedUniqueValues = useMemo(() => {
    if (!node.uniqueValues) return [];
    const values = node.uniqueValues;
    return getTopTenItems(values);
  }, [node.uniqueValues]);

  const openEntitiesSidebar = (value = null) => {
    dispatch(
      setEntitiesSidebar({
        uniqueId,
        isOpen: true,
        name: node.name,
        uri: node.uri,
        ontologyType: node.ontologyType,
        ...(value
          ? {
              preventFetch: true,
              inputField: value.name,
              uniqueValues: [value],
              uniqueValuesTotalCount: 1,
              forcedOpenUri: value.uri
            }
          : {})
      })
    );
  };

  return (
    <div className={clsx(styles.section, styles.horizontalPadding)}>
      <div className={styles.subheaderContainer}>
        <Title size="small">{`${node.uniqueValuesTotalCount} ${t('sidebar.x-unique-values')}`}</Title>
        {!hideSearch && (
          <InfoTooltip text={t('entities-sidebar.search-unique-values')} placement="top">
            <SearchRoundedIcon className={styles.searchIcon} onClick={() => openEntitiesSidebar()} />
          </InfoTooltip>
        )}
      </div>
      <div className={styles.chipContainer}>
        {node.uniqueValues.length ? (
          <>
            {formattedUniqueValues.map((value, index) => (
              <div key={`${value.name}_${index}`} className={styles.chipCell}>
                <Chip
                  allowHover
                  showTooltip
                  label={value.name}
                  onClick={hideSearch ? undefined : () => openEntitiesSidebar(value)}
                />
              </div>
            ))}
            {node.uniqueValuesTotalCount > formattedUniqueValues.length && (
              <div className={styles.chipCell}>
                <Chip label={`+${node.uniqueValuesTotalCount - 10}`} />
              </div>
            )}
          </>
        ) : (
          <span className={styles.noItemsFound}>{t('sidebar.no-unique-values-yet')}</span>
        )}
      </div>
    </div>
  );
};

export default withTranslation('veezoo')(connect()(memo(NodeUniqueValues)));
