import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import BoardSidebarListItem from 'components/sidebar/board/BoardSidebarListItem';
import BoardFileTree from 'components/BoardFileTree';
import { InfoTooltip } from 'components/v3';

import CreateBoardModal from './CreateBoardModal';

import { trackEvent } from 'utils/eventTracking';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { boardFileTypes } from 'store/utils/boardFileTypes';
import { createAndMoveBoard, createAndMoveFolder, moveBoardView } from 'store/modules/board';

import BrowseIcon from 'svg/browse.svg';
import PlusIcon from 'svg/v2/plus.svg';
import LoadingIcon from 'svg/v2/loading.svg';

import useStyles from './styles';

import styles from 'components/sidebar/sidebar.scss';

import BrowseBoardsContainer from './BrowseBoardsContainer';

import withRestrictedFunctionality from 'root/hocs/withRestrictedFunctionality';
import { embeddedMessageTypes, receiveMessageFromParentWindow } from 'root/hocs/withEmbedded';

const initialModal = {
  show: false,
  parentId: null
};

// Empty list up here to keep the reference constant (no new array created each run)
const emptyBoardList = [];

const BoardSidebarList = ({
  defaultEntryPoint,
  t,
  handleFetchBoardMessage,
  ownBoards,
  sharedBoards,
  boardsView,
  dispatch,
  restrictedFunctionality,
  isChangingBoardOrFolder
}) => {
  const newStyles = useStyles();

  const availableBoards = sharedBoards && ownBoards ? sharedBoards.concat(ownBoards) : emptyBoardList;
  const [modal, setModal] = useState(initialModal);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [browseBoardsOpen, setBrowseBoardsOpen] = useState(false);

  const formattedBoardTree = useMemo(() => {
    const formatItems = items =>
      items.reduce((result, item) => {
        if (item.type.toLowerCase() === boardFileTypes.folder) {
          const children = formatItems(item.children, item.id);
          return [
            ...result,
            {
              ...item,
              children,
              switcherIcon: <KeyboardArrowRightIcon className={styles.icon} />,
              key: item.id,
              title: item.name
            }
          ];
        }

        const boardInfo = availableBoards.find(board => board.id === item.id);
        if (boardInfo) {
          return [...result, { ...item, ...boardInfo, key: boardInfo.id, title: boardInfo.name }];
        }
        return result;
      }, []);

    if (availableBoards.length && boardsView.length) {
      return formatItems(boardsView);
    }
    return [];
  }, [availableBoards, boardsView]);

  const openCreateModal = (parentId = null) => {
    // Show the signup modal if we are in demo mode
    restrictedFunctionality(() => setModal({ show: true, parentId }));
  };

  const closeCreateModal = () => setModal(initialModal);

  const openBrowseBoardModal = () => {
    // Show the signup modal if we are in demo mode
    restrictedFunctionality(() => {
      trackEvent('Browse Boards Modal Opened');
      setBrowseBoardsOpen(true);
    });
  };

  const onMoveItem = useCallback((id, parentId, aboveId) => dispatch(moveBoardView(id, parentId, aboveId)), [dispatch]);

  const createNewBoard = useCallback(
    ({ name, type }) => {
      let lastItemId = [];
      if (modal.parentId) {
        setExpandedKeys(prev => [...prev, modal.parentId]);
        const parent = formattedBoardTree.find(item => item.id === modal.parentId);
        lastItemId = parent?.children?.length > 0 ? parent.children[parent.children.length - 1].id : null;
      } else {
        lastItemId = formattedBoardTree.length > 0 ? formattedBoardTree[formattedBoardTree.length - 1].id : null;
      }

      if (type === boardFileTypes.board) {
        dispatch(createAndMoveBoard(name, [], undefined, true, modal.parentId, lastItemId, t));
      } else {
        const payload = { name, parentId: modal.parentId, aboveId: lastItemId };
        dispatch(createAndMoveFolder(payload));
      }
      closeCreateModal();
    },
    [formattedBoardTree, modal.parentId]
  );

  const handleExpandedKeys = useCallback(
    node => {
      const isExpanded = expandedKeys.find(key => key === node.id);
      const result = isExpanded ? expandedKeys.filter(key => key !== node.id) : [...expandedKeys, node.id];
      setExpandedKeys(result);
    },
    [expandedKeys]
  );

  // listen to messages from the parent of this iframe and if we get a message with a boardId (call handleFetchBoardMessage)
  // then we will fetch the board with that id
  useEffect(() => {
    receiveMessageFromParentWindow(embeddedMessageTypes.openBoard, data => {
      if (data.boardId) {
        handleFetchBoardMessage(data.boardId);
      }
    });
  }, []);

  // Encode the board view for E2E tests as an attribute
  // We only encode the relevant information, also because otherwise encoding some complex objects would fail
  const boardViewForTests = JSON.stringify(formattedBoardTree, ['children', 'title', 'key']);

  return (
    <div className={styles.boards} data-overview-tutorial="boards">
      <header className={styles.header}>
        <h3 className={styles.title}>{t('dashboard-own')}</h3>

        <div className={styles.controls}>
          {isChangingBoardOrFolder && <LoadingIcon className={styles.loadingIcon} data-test="boardsLoading" />}
          <InfoTooltip text={t('tooltip.browse-boards')} placement="top">
            <button
              className={styles.browseBoards}
              onClick={_ => openBrowseBoardModal()}
              data-test="browseBoardsButton"
              aria-label={t('browse-boards')}
            >
              <BrowseIcon className={newStyles.browseIcon} />
            </button>
          </InfoTooltip>
          <button
            className={styles.addNewBoard}
            data-overview-tutorial="add-new-board"
            onClick={(_, parentId) => openCreateModal(parentId)}
            data-test="addBoardAtRootButton"
            title={t('dashboard-new-board')}
          >
            <PlusIcon className={newStyles.plusIcon} />
          </button>
        </div>
      </header>

      <CreateBoardModal open={modal.show} onConfirm={createNewBoard} onClose={closeCreateModal} />

      {formattedBoardTree.length > 0 && (
        <div className={styles.itemList} data-test="boardFileTree" board-view={boardViewForTests}>
          <ul className={styles.list} data-addwidget-tutorial="step-1">
            <BoardFileTree
              boardsList={formattedBoardTree}
              expandedKeys={expandedKeys}
              setExpandedKeys={setExpandedKeys}
              onMoveItem={onMoveItem}
              loading={isChangingBoardOrFolder}
              renderListItem={board => (
                <BoardSidebarListItem
                  key={board.id}
                  board={board}
                  defaultEntryPoint={defaultEntryPoint}
                  t={t}
                  handleFetchBoardMessage={handleFetchBoardMessage}
                  dispatch={dispatch}
                  handleExpandedKeys={() => handleExpandedKeys(board)}
                  openCreateModal={openCreateModal}
                />
              )}
            />
          </ul>
        </div>
      )}
      <BrowseBoardsContainer open={browseBoardsOpen} onClose={() => setBrowseBoardsOpen(false)} />
    </div>
  );
};

const mapStateToProps = state => ({
  boardsView: state.board.boardsView,
  isChangingBoardOrFolder: state.board.isChangingBoardOrFolder
});

export default connect(mapStateToProps)(withRestrictedFunctionality(BoardSidebarList));
