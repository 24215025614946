import { mediaQueryMobile, mediaQueryTablet, mediaQueryDesktop } from 'styles/mediaQueries.scss';

export const TIMEFORMAT = 'HH:mm';

// shared customer selection status
export const PENDING_STATUS = 'PENDING';
export const ACCEPTED_STATUS = 'ACCEPTED';
export const REJECTED_STATUS = 'REJECTED';

// screen width
export const DESKTOP_DEVICE = mediaQueryDesktop;
export const TABLET_DEVICE = mediaQueryTablet;
export const MOBILE_DEVICE = mediaQueryMobile;

export const SIDEBAR_MIN_SIZE = 67;
export const SIDEBAR_EXPANDED_SIZE = 227;

// tutorials names (and ids are the same)
export const tutorialNames = {
  OVERVIEW_TUTORIAL: 'OVERVIEW_TUTORIAL',
  WHAT_CAN_I_ASK_TUTORIAL: 'WHAT_CAN_I_ASK_TUTORIAL',
  FIRST_ANSWER_TUTORIAL: 'FIRST_ANSWER_TUTORIAL',
  ADDED_WIDGET_TUTORIAL: 'ADDED_WIDGET_TUTORIAL',
  BOOKMARK_TUTORIAL: 'BOOKMARK_TUTORIAL',
  CREATE_ACCOUNT_MODAL: 'CREATE_ACCOUNT_MODAL',
  KNOWLEDGE_GRAPH_TUTORIAL: 'KNOWLEDGE_GRAPH_TUTORIAL',
  CUSTOMERS_OF_THE_DAY_TUTORIAL: 'CUSTOMERS_OF_THE_DAY_TUTORIAL',
  CUSTOMER_SELECTION_TUTORIAL: 'CUSTOMER_SELECTION_TUTORIAL',
  ADD_TO_CUSTOMER_SELECTION_TUTORIAL: 'ADD_TO_CUSTOMER_SELECTION_TUTORIAL',
  SMART_ACTION_TUTORIAL: 'SMART_ACTION_TUTORIAL'
};

// passage types
export const passageTypes = {
  COMPUTATION: 'COMPUTATION',
  TEXT: 'TEXT',
  CLASS: 'CLASS',
  ENTITY: 'ENTITY',
  ENTITY_UNION: 'ENTITY_UNION',
  RELATION: 'RELATION',
  DATE_VALUE: 'DATE_VALUE',
  LITERAL: 'LITERAL'
};

export const graphNodeTypes = {
  CLASS: 'Class',
  ENTITY: 'Entity',
  LITERAL: 'Literal',
  MEASURE: 'Measure'
};

// passage info strategy types
export const passageInfoStrategyTypes = {
  LINKED_PASSAGE_INFO: 'LinkedPassageInfo',
  MARKED_PASSAGE_INFO: 'MarkedPassageInfo',
  ONLY_SHOW_TEXT_PASSAGE: 'OnlyShowTextPassage',
  HIGHLIGHTED_PASSAGE_INFO: 'HighlightedPassageInfo',
  UNRECOGNIZED_WORD: 'UnrecognizedWord',
  CONCEPT_NOT_IN_DATA: 'ConceptNotInData'
};

export const configurableThemeColors = {
  '--primary-color': { label: 'Primary' },
  '--secondary-color': { label: 'Secondary' },
  '--follow-up-color': { label: 'Follow-Up' },
  '--chat-icon-background-color': { label: 'Chat Icon Background' },
  '--header-logo-background-color': { label: 'Logo Background' }
};

// chart colors for hicharts to use as a fallback if no custom chart color configuration exists
export const defaultChartColors = [
  '#153A7B',
  '#01c6e2',
  '#f45b5b',
  '#7798BF',
  '#aaeeee',
  '#ff0066',
  '#eeaaee',
  '#55BF3B',
  '#DF5353',
  '#7798BF',
  '#aaeeee'
];

const CustomMapPrefix = 'CustomChoroplethMap_';
export const isCustomMapType = type => type.startsWith(CustomMapPrefix);
// take everything after CustomMap_ as name, if empty use "Custom Map"
export const customMapName = type => type.replace(CustomMapPrefix, '') || 'Custom Map';
const InvertedSuffix = '_Inverted';
export const isInverted = type => type.endsWith(InvertedSuffix);
export const normalizedType = type => type.replace(new RegExp(InvertedSuffix + '$'), '');

export const isHighChartsType = type => {
  // basically whenever it's not one of the chart types (values) below, we run the highcharts script
  return Object.values(highChartsTypes).includes(normalizedType(type)) || isCustomMapType(type);
};
export const highChartsTypes = {
  BAR_CHART: 'BarChart',
  MULTI_BAR_CHART: 'MultiBarChart',
  STACKED_BAR_CHART: 'StackedBarChart',
  TIMELINE_CHART: 'TimelineChart',
  LINE_CHART: 'LineChart',
  PIE_CHART: 'PieChart',
  ORDERED_CATEGORICALS_MULTI_LINE_CHART: 'OrderedCategoricalsMultiLineChart',
  MULTI_LINE_CHART: 'MultiLineChart',
  RANGE_MULTI_LINE_CHART: 'RangeMultiLineChart',
  MULTI_AXES_LINE_CHART: 'MultiAxesLineChart',
  HISTOGRAM_CHART: 'HistogramChart',
  TOP_COLUMN_CHART: 'TopColumnChart',
  COLUMN_CHART: 'ColumnChart',
  TREE_MAP: 'TreeMap',
  SUNBURST_CHART: 'SunburstChart',
  DRILL_DOWN_COLUMN_CHART: 'DrillDownColumnChart',
  MULTI_DRILL_DOWN_COLUMN_CHART: 'MultiDrillDownColumnChart',
  DRILL_DOWN_PIE_CHART: 'DrillDownPieChart',
  MULTI_AXES_LINE_COLUMN_CHART: 'MultiAxesLineColumnChart',
  MULTI_COLUMN_CHART: 'MultiColumnChart',
  MULTI_AXES_COLUMN_CHART: 'MultiAxesColumnChart',
  MULTI_AXES_COLUMN_CATEGORICAL_CHART: 'MultiAxesColumnCategoricalChart',
  MULTI_AXES_BAR_CATEGORICAL_CHART: 'MultiAxesBarCategoricalChart',
  STACKED_COLUMN_CHART: 'StackedColumnChart',
  HEAT_MATRIX_CHART: 'HeatMatrixChart',
  PERCENT_STACKED_BAR_CHART: 'PercentStackedBarChart',
  PERCENT_STACKED_COLUMN_CHART: 'PercentStackedColumnChart',
  AREA_CHART: 'AreaChart',
  MULTI_AREA_CHART: 'MultiAreaChart',
  STACKED_AREA_CHART: 'StackedAreaChart',
  PERCENT_STACKED_AREA_CHART: 'PercentStackedAreaChart',
  CHOROPLETH_MAP: 'ChoroplethMap',
  LABELED_MULTI_SCATTER_CHART: 'LabeledMultiScatterChart',
  MULTI_SCATTER_CHART: 'MultiScatterChart',
  SCATTER_CHART: 'ScatterChart',
  MULTI_HISTOGRAM_CHART: 'MultiHistogramChart',
  MULTI_BUBBLE_CHART: 'MultiBubbleChart',
  BUBBLE_CHART: 'BubbleChart'
};

export const chartTypes = {
  NUMBER_COMPARISON_CHART: 'NumberComparisonChart',
  TWO_NUMBER_CHART: 'TwoNumberChart',
  SIMPLE_NUMBER_CHART: 'SimpleNumberChart',
  HIERARCHICAL_TABLE_CHART: 'HierarchicalTableChart',
  VEEZOO_MAP_CHART: 'VeezooMapChart',
  TABLE_CHART: 'TableChart',
  ...highChartsTypes
};
export const answerVisualizationPlaceholders = {
  TOTAL_NUMBER_PLACEHOLDER: '$TOTAL_NUMBER_PLACEHOLDER'
};

/**
 * All the types that a Table Chart can have
 */
export const tableColumnTypes = {
  STRING: 'string',
  CATEGORICAL: 'categorical',
  DATE_TIME: 'datetime',
  BOOLEAN: 'boolean',
  LINK: 'link',
  NUMBER: 'number',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  CASE_IDENTIFIER: 'caseIdentifier',
  DATE_INTERVAL: 'dateInterval'
};

export const dateTypes = {
  date: 'date',
  day: 'day',
  month: 'month',
  year: 'year',
  week: 'week',
  quarter: 'quarter',
  hour: 'hour',
  minute: 'minute'
};

export const dateComputations = {
  BEFORE: { name: 'before', value: 'before' },
  BEFORE_OR_IN: { name: 'beforeOrIn', value: 'until' },
  AFTER: { name: 'after', value: 'after' },
  AFTER_OR_IN: { name: 'afterOrIn', value: 'since' },
  BETWEEN: { name: 'between', value: 'isDateInRange' },
  IN: { name: 'in', value: 'isDateIn' }
};

export const datetimeViews = {
  day_month_year: 'day_month_year',
  month_year: 'month_year',
  year: 'year',
  week: 'week',
  quarter: 'quarter',
  hour_minute: 'hour_minute'
};

export const months = {
  1: 'january',
  2: 'february',
  3: 'march',
  4: 'april',
  5: 'may',
  6: 'june',
  7: 'july',
  8: 'august',
  9: 'september',
  10: 'october',
  11: 'november',
  12: 'december'
};

// In Veezoo, the week starts on monday, although it usually starts on sunday.
export const daysOfWeek = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday'
};

// "weekStartOn:1" corresponds to "monday" in date-fns, it is the first day of the week in Veezoo.
export const firstDayOfWeekInVeezoo = 1;

/**
 * Defines URLs linking to the Veezoo website.
 */
export const veezooUrls = {
  contact: 'https://www.veezoo.com/contact',
  pricing: 'https://www.veezoo.com/pricing',
  docs: 'https://docs.veezoo.com/docs',
  slack: 'http://slack.veezoo.com',
  vklDocs: 'https://docs.veezoo.com/docs/intro-to-kg',
  syncDocs: 'https://docs.veezoo.com/docs/kg-sync',
  roleDocs: 'https://docs.veezoo.com/docs/authorization/user-roles'
};

/** Supported languages and their corresponding ISO codes. */
export const languagesIso = {
  german: 'de-DE',
  english: 'en-US',
  french: 'fr-FR',
  italian: 'it-IT',
  portuguese: 'pt-BR'
};

export const defaultLanguage = 'en-US';

export const languageInfo = {
  'de-DE': { flag: 'germany', initial: 'DE' },
  'en-US': { flag: 'usa', initial: 'EN' },
  'fr-FR': { flag: 'france', initial: 'FR' },
  'it-IT': { flag: 'italy', initial: 'IT' },
  'pt-BR': { flag: 'brazil', initial: 'PT' }
};

// For "studio" we use "default", because Studio doesn't have custom CSS. It uses default from material-ui.
export const layouts = {
  signup: 'signup',
  veezoo: 'veezoo',
  default: 'default'
};

export const answerModificationTypes = {
  entity: { value: 'ENTITIES_MOD' },
  datetime: { value: 'DATE_MOD' },
  boolean: { value: 'BOOLEAN_MOD' },
  template: { value: 'TEMPLATE_MOD' }
};

export const templateModificationTypes = {
  new: { value: 'NEW' },
  edit: { value: 'EDIT' }
};

export const browseBoardsSortingOptions = {
  NAME_ASC: { value: 'name-asc', label: 'browse-boards.sorting.name-asc' },
  NAME_DESC: { value: 'name-desc', label: 'browse-boards.sorting.name-desc' },
  CREATED_NEW_FIRST: { value: 'created-new-first', label: 'browse-boards.sorting.created-desc' },
  CREATED_OLD_FIRST: { value: 'created-old-first', label: 'browse-boards.sorting.created-asc' },
  SHARED_NEW_FIRST: { value: 'shared-new-first', label: 'browse-boards.sorting.shared-desc' },
  SHARED_OLD_FIRST: { value: 'shared-old-first', label: 'browse-boards.sorting.shared-asc' },
  SUBSCRIBERS_MOST_FIRST: { value: 'subscribers-most-first', label: 'browse-boards.sorting.subscribers-most-first' },
  SUBSCRIBERS_LEAST_FIRST: { value: 'subscribers-least-first', label: 'browse-boards.sorting.subscribers-least-first' }
};

export const browseBoardsSubscribedOptions = {
  ALL_BOARDS: { value: '', label: t => t('browse-boards.all-boards') },
  SUBSCRIBED: { value: 'subscribed', label: t => t('browse-boards.subscribed') },
  NOT_SUBSCRIBED: { value: 'not_subscribed', label: t => t('browse-boards.not-subscribed') },
  SHARED_BY_YOU: {
    value: 'shared_by_you',
    label: t => t('browse-boards.shared-by', { sharer: t('browse-boards.shared-by-you') })
  }
};

export const entryPointTypes = {
  BOARD: 'BOARD',
  DATA: 'DATA',
  CUSTOMER_SELECTION: 'CUSTOMER_SELECTION',
  CUSTOMERS_OF_THE_DAY: 'CUSTOMERS_OF_THE_DAY'
};

export const chatUrlEntryPointTypes = {
  customersOfTheDay: 'customersOfTheDay',
  sharing: 'sharing',
  board: 'board',
  ask: 'ask'
};

export const possibleStates = {
  suggestions: 'suggestions',
  discovery: 'discovery',
  knowledgeGraph: 'knowledgeGraph'
};
