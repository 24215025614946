import { withTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/v3';

import useMediaQuery from 'utils/mediaQueries';

import styles from './styles.scss';

const UnrecognizedSpan = ({ children, t, offsetKey }) => {
  const isMobile = useMediaQuery();
  return (
    <InfoTooltip text={t('tooltip.unknown-word')} open={!isMobile} className={styles.inputTooltip}>
      <span data-offset-key={offsetKey} className={styles.unrecognized} id="UnrecognizedWordForTest">
        {children}
      </span>
    </InfoTooltip>
  );
};

export default withTranslation('veezoo')(UnrecognizedSpan);
