import clsx from 'clsx';
import Datepicker from 'components/v2/Datepicker';
import Timepicker from 'components/v2/Timepicker/Timepicker';

import TimeIcon from 'svg/v2/clock-icon.svg';
import IconClose from 'svg/v2/close.svg';

import useStyles from 'styles/JSS/Popover';

export const DatetimeComponent = ({
  views,
  value,
  setDisplayTimeInput,
  onChange,
  displayDate,
  displayTime,
  timeIsMandatory,
  t
}) => {
  const styles = useStyles();

  return (
    <>
      {displayDate && (
        <div className={styles.mb5}>
          <Datepicker
            fullWidth
            value={value.date}
            onChange={value => onChange('date', value)}
            title={t('date')}
            views={views}
          />
        </div>
      )}
      {displayTime &&
        (timeIsMandatory || value.allowTime ? (
          <div className={clsx(styles.timeComponentContainer, styles.mb5)}>
            <Timepicker value={value.time} onChange={value => onChange('time', value)} title={t('hour')} />
            {!timeIsMandatory && (
              <div className={styles.removeTime} onClick={() => setDisplayTimeInput(false)}>
                <IconClose className={styles.timeCloseIcon} data-test="date-modification-remove-time-button" />
              </div>
            )}
          </div>
        ) : (
          <div
            className={styles.addTimeButton}
            data-test="date-modification-add-time-button"
            onClick={() => setDisplayTimeInput(true)}
          >
            <TimeIcon className={styles.timeIcon} />
            <span>{t('date-modifications.add-time')}</span>
          </div>
        ))}
    </>
  );
};

export default DatetimeComponent;
