import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';

import { untoggleFollowUp } from 'store/modules/followUpState';
import { fetchPills } from 'store/modules/pills';
import { parseSuggestionWithStyles } from 'utils/suggestionParser';

import Button from 'components/buttons/Button';
import ScrollOnMountElement from 'components/shared/ScrollOnMountElement';
import QuestionPill from 'components/pills/QuestionPill';
import DrawerSuggestionsList from 'components/DrawerSuggestionsList/DrawerSuggestionsList';

import CloseIcon from 'svg/v2/close.svg';

import UISkeleton from '@material-ui/lab/Skeleton';

import useMediaQuery from 'utils/mediaQueries';

import styles from './question-pills.scss';
import { messageTypes } from 'store/modules/chat-messages';

import SystemZeroSelectButton from './SystemZeroSelectButton';

export const IS_FOLLOW_UP_PILL = 'IS_FOLLOW_UP_PILL';
export const MISUNDERSTOOD_PILL = 'MISUNDERSTOOD_PILL';
export const DATA_WRONG_PILL = 'DATA_WRONG_PILL';
export const SHITTY_VISUALISATION_PILL = 'SHITTY_VISUALISATION_PILL';
export const TRY_AGAIN_PILL = 'TRY_AGAIN_PILL';

const LoadingSkeleton = ({ isMobile }) => {
  if (isMobile)
    return (
      <>
        <div className={styles.skeleton}>
          <UISkeleton variant="rect" width="150px" height="30px" />
        </div>
        <div className={styles.skeleton}>
          <UISkeleton variant="rect" width="150px" height="30px" />
        </div>
      </>
    );

  return (
    <>
      <div className={styles.skeleton}>
        <UISkeleton variant="rect" width="213px" height="30px" />
      </div>
      <div className={styles.skeleton}>
        <UISkeleton variant="rect" width="213px" height="30px" />
      </div>
      <div className={styles.skeleton}>
        <UISkeleton variant="rect" width="135px" height="30px" />
      </div>
    </>
  );
};

const QuestionPills = ({
  user,
  pills,
  followUpState,
  isFollowUp,
  isFetchingMessage,
  isFetchingPills,
  hasFetchedPills,
  recentFeedback,
  isLlmParserEnabled,
  isSystemZeroEnabled,
  onClick,
  dispatch,
  hiddenMessages,
  t
}) => {
  const [suggestionsDrawer, setSuggestionsDrawer] = useState({ open: false, items: [] });
  const isMobile = useMediaQuery();

  useEffect(() => {
    if (!isFetchingPills && !hasFetchedPills) {
      // these are only fetched on initial application load
      dispatch(fetchPills(true));
    }
  }, []);

  useEffect(() => {
    // if it's not the first time we fetch pills, we want to fetch them again when followUpState changes
    if (hasFetchedPills && !isFetchingPills) {
      dispatch(
        fetchPills(false, followUpState.answerId, followUpState.partialAnswerId, followUpState.interpretationId)
      );
    }
  }, [followUpState]);

  useEffect(() => {
    // whenever we get a new message, we want to fetch pills again, unless it's the first time we fetch pills
    // note, this gets called also when the initial board loads, or when have an answer + complentary
    // i.e. in both cases we fetch pills twice (initial + board) or (answer + complementary), we might want to change this
    if (hasFetchedPills && !isFetchingMessage) {
      dispatch(
        fetchPills(false, followUpState.answerId, followUpState.partialAnswerId, followUpState.interpretationId)
      );
    }
  }, [isFetchingMessage]);

  useEffect(() => {
    // if there is a pill that is referencing to a hidden message, we don't want to show it and fetch
    // new pills again, that are not referencing any message
    const hasPillThatReferencesHiddenMessage = pills.some(pill =>
      hiddenMessages.some(
        hiddenMessage => hiddenMessage.answerId && hiddenMessage.answerId === pill.referenceTo?.answerId
      )
    );

    if (hasPillThatReferencesHiddenMessage && !isFetchingPills) {
      dispatch(fetchPills(true));
    }
  }, [pills, hiddenMessages]);

  const negativeFeedbackPills = () => {
    const suggestions = [
      {
        inputOffset: 0,
        passageInfos: [],
        text: t('pills.misunderstood'),
        type: MISUNDERSTOOD_PILL
      },
      {
        inputOffset: 0,
        passageInfos: [],
        text: t('pills.shit-visualisation'),
        type: SHITTY_VISUALISATION_PILL
      },
      {
        inputOffset: 0,
        passageInfos: [],
        text: t('pills.data-is-wrong'),
        type: DATA_WRONG_PILL
      }
    ];

    if (!recentFeedback.wasFollowUp) {
      suggestions.push({
        inputOffset: 0,
        passageInfos: [],
        text: t('pills.follow-up'),
        type: IS_FOLLOW_UP_PILL
      });
    }

    // retry is only avaible if one of the LLM-based features is enabled
    if (isLlmParserEnabled || isSystemZeroEnabled) {
      suggestions.push({
        inputOffset: 0,
        passageInfos: [],
        text: `${t('pills.lets-try-again')} 🪄`,
        type: TRY_AGAIN_PILL
      });
    }

    return suggestions;
  };

  const suggestions = recentFeedback.wasNegative ? negativeFeedbackPills() : pills;

  const formattedSuggestions = useMemo(
    () =>
      suggestions.map((item, index) => {
        const itemUuid = `suggestion_${index}`;
        return {
          ...item,
          id: itemUuid,
          ...(item.options
            ? {
                options: item.options.map((option, optionIndex) => {
                  const optionUuid = `break_down_${index}_${optionIndex}`;
                  return { ...option, isOption: true, id: optionUuid };
                })
              }
            : {})
        };
      }),
    [suggestions]
  );

  const noPillsAvailable = formattedSuggestions.length === 0;

  const openSuggestionsDrawer = items => setSuggestionsDrawer({ open: true, items });
  const closeSuggestionsDrawer = () => setSuggestionsDrawer({ open: false, items: [] });

  const handleClick = suggestion => {
    let currentSuggestion = null;
    if (isMobile) {
      formattedSuggestions.forEach(item => {
        if (item.id === suggestion.id) {
          currentSuggestion = item;
        }
        if (item.options) {
          item.options.forEach(option => {
            if (option.id === suggestion.id) {
              currentSuggestion = {
                ...option,
                text: option.isOption ? item.text + ' ' + option.text : option.text
              };
            }
          });
        }
      });
    } else {
      currentSuggestion = suggestion;
    }

    onClick(currentSuggestion);
  };

  const pillItems = useMemo(() => {
    let count = 0;
    const hasOptions = formattedSuggestions.map(suggestion => {
      if (suggestion.options && suggestion.options.length) {
        let optionsList;
        let optionsCount = 0;
        const breakDownText = suggestion.text + ' ';

        // reverse because we want the FIRST array element to appear LAST in the list, as it's a drop up
        optionsList = suggestion.options
          .slice()
          .reverse()
          .map(option => {
            const newOption = {
              id: option.id,
              text: breakDownText + option.text,
              passageInfos: [...option.passageInfos]
            };

            const parsedSuggestion = parseSuggestionWithStyles(option);

            if (isMobile) return { ...newOption, text: parsedSuggestion };

            return (
              <Dropdown.Item
                className={styles.option}
                onClick={() => handleClick(newOption)}
                value={option}
                key={optionsCount++}
              >
                {parsedSuggestion}
              </Dropdown.Item>
            );
          });

        return !isMobile ? (
          <li key={count++} className={styles.optionsWrap}>
            <Dropdown id="question-pills-dropdown" className={styles.optionsList} drop="up" role="menuitem">
              <Dropdown.Toggle
                className={`${styles.pill} ${suggestion.isFollowUp ? styles.followUp : null}`}
                data-test="questionPill"
              >
                <span className="icon-follow-up" />
                <span className="text">{suggestion.text}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {optionsList}
                <ScrollOnMountElement />
              </Dropdown.Menu>
            </Dropdown>
          </li>
        ) : (
          <li key={count++} className={styles.item}>
            <QuestionPill
              additionalClassName={`${recentFeedback.wasNegative ? styles.negativeFeedback : null} ${
                suggestion.isFollowUp ? styles.followUp : null
              }`}
              iconClassName="icon-follow-up"
              onClick={() => openSuggestionsDrawer(optionsList)}
              suggestion={suggestion}
            />
          </li>
        );
      }
    });

    const hasNoOptions = formattedSuggestions.map(suggestion => {
      if (!suggestion.options) {
        return (
          <li key={count++} className={styles.item}>
            <QuestionPill
              additionalClassName={`${recentFeedback.wasNegative ? styles.negativeFeedback : null} ${
                suggestion.isFollowUp ? styles.followUp : null
              }`}
              iconClassName={suggestion.isFollowUp ? 'icon-follow-up' : undefined}
              onClick={() => handleClick(suggestion)}
              suggestion={suggestion}
            />
          </li>
        );
      }
    });

    return (
      <>
        {hasNoOptions}
        {hasOptions}
      </>
    );
  }, [isMobile, formattedSuggestions, recentFeedback.wasNegative]);

  const cancelButton = isFollowUp && (
    <Button
      onClick={() => dispatch(untoggleFollowUp())}
      text={!isMobile ? t('pills.cancel') : ''}
      className={`${'button-primary'} ${styles.buttonCancel}`}
    >
      {isMobile ? <CloseIcon className={styles.closeIcon} /> : ''}
    </Button>
  );

  return (
    <div className={`${styles.pills} ${isFollowUp ? 'selectedForFollowUp' : null}`} data-test="questionPills">
      <>
        {user.isSystemZeroEnabled && !isFollowUp ? (
          <SystemZeroSelectButton />
        ) : (
          !isMobile && <span className="icon-example" />
        )}
        <div className={styles.wrapper} data-overview-tutorial="step-5">
          {isFetchingPills ? (
            <LoadingSkeleton isMobile={isMobile} data-test="loadingSkeleton" />
          ) : noPillsAvailable ? (
            <em className={styles.emptyPillsLabel}>{t('pills.no-suggestions')}</em>
          ) : (
            <>
              <ul className={styles.list}>{pillItems}</ul>
            </>
          )}
        </div>
        {cancelButton}
        {isMobile && (
          <DrawerSuggestionsList
            isOpen={suggestionsDrawer.open}
            onClose={closeSuggestionsDrawer}
            items={suggestionsDrawer.items}
            onItemClick={handleClick}
          />
        )}
      </>
    </div>
  );
};

// if we would do this dynamically in the mapStateToProps
// we would get many unnecessary render calls
const EmptyObject = {};

const mapStateToProps = state => ({
  pills: state.pills.list,
  hiddenMessages: state.chatMessages.filter(m => m.type === messageTypes.USER_HIDDEN_MESSAGE),
  followUpState: state.followUpState || EmptyObject,
  isFollowUp: state.followUpState !== null,
  isFetchingMessage: state.network.isFetchingMessage,
  isFetchingPills: state.pills.isFetchingPills,
  hasFetchedPills: state.pills.hasFetchedPills,
  recentFeedback: state.recentFeedback,
  isLlmParserEnabled: state.user.isLlmParserEnabled,
  isSystemZeroEnabled: state.user.isSystemZeroEnabled,
  user: state.user
});

export default withTranslation('veezoo')(connect(mapStateToProps)(QuestionPills));
