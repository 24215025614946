import { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Button from 'components/v2/Button';
import PopoverButton from 'components/Popover/PopoverButton';
import MultiselectList from 'components/MultiselectList/MultiselectList';

import uuid1 from 'uuid/v1';
import services from 'services';
import { modifyTableColumnAnswer } from 'store/modules/chat-messages';

import styles from './modify-column-button.scss';
import useMediaQuery from 'utils/mediaQueries';

import { getSidebarNodes } from 'store/modules/graph/graph';

const initialState = {
  loading: false,
  modifications: [],
  existing: [],
  possible: []
};

const ModifyColumnButton = ({ answer, dispatch, nodes, sidebarNodeLayouts, t }) => {
  const [state, setState] = useState(initialState);
  const isMobile = useMediaQuery();
  const sidebarNodes = useMemo(() => getSidebarNodes(nodes, sidebarNodeLayouts), [nodes, sidebarNodeLayouts]);
  const options = [...state.existing.map(i => ({ ...i, selected: true })), ...state.possible];

  const fetchModifications = async () => {
    const { answerId, interpretationId } = answer;
    setState(prev => ({ ...prev, loading: true }));
    const result = await services.getTableAnswerColumnModifications(answerId, interpretationId);
    if (result.success) {
      const existing = result.data.existing.map(item => ({ ...item, uniqueId: uuid1() }));
      const possible = result.data.possible.map(item => ({ ...item, uniqueId: uuid1() }));

      setState(prev => ({ ...prev, existing, possible, options: [...existing, ...possible] }));
    }
    setState(prev => ({ ...prev, loading: false }));
  };

  const modifyColumns = async selected => {
    const columnsToAdd = state.possible.filter(possible => selected.some(item => item.uniqueId === possible.uniqueId));
    const columnsToRemove = state.existing.filter(
      existing => !selected.some(item => item.uniqueId === existing.uniqueId)
    );

    const modifications = [...columnsToAdd, ...columnsToRemove].map(({ uniqueId, text, value, ...rest }) => ({
      type: 'COLUMN_MOD',
      columnModification: rest
    }));

    const message = `${t('modifications.modify-columns')}: ${answer.title}`;
    const { answerId, partialAnswerId, interpretationId } = answer;
    const payload = {
      answerId,
      partialAnswerId,
      interpretationId,
      modifications
    };

    dispatch(modifyTableColumnAnswer(state.modifications, message, payload, t));
  };

  useEffect(() => {
    fetchModifications();
  }, []);

  const optionsWithLinks = options.filter(o => o.link);

  // We want to show nodes only once in the whole tree, preferring parents.
  let handledUris = sidebarNodes.map(s => s.uri);

  const optionsWithSidebarNode =
    sidebarNodes &&
    sidebarNodes.map(s => {
      // Prefer parentLink matching if existing
      const optionsSortedByParentLink = optionsWithLinks.sort(
        (a, b) => (b.parentLink === s.uri) - (a.parentLink === s.uri)
      );
      const childrenWithOptions = s.children
        // Remove children that also appear as parents
        .filter(child => !handledUris.includes(child.uri))
        .map(child => {
          // Prefer matching over relationUri to get correct names for object role relations.
          const option =
            optionsSortedByParentLink.find(o => o.link === child.relationUri) ||
            optionsSortedByParentLink.find(o => o.link === child.uri);

          return {
            childUri: child.uri,
            option: option
          };
        })
        .filter(c => c.option);
      handledUris = handledUris.concat(childrenWithOptions.map(c => c.childUri));

      const parentOption = optionsWithLinks.find(o => o.link === s.relationUri) ||
        optionsWithLinks.find(o => o.link === s.uri) || {
          name: s.name,
          disabled: true
        }; // Always add a parent (even if disabled) if a child appears as a column option

      return {
        option: parentOption,
        children: childrenWithOptions
      };
    });
  // Only keep the KG sidebar concepts that appear as column modifications, e.g. drop 'Customer' for 'Top 3 Customers'.
  const filteredOptionsWithSidebarNode = optionsWithSidebarNode.filter(
    s => s.children.length > 0 || !s.option?.disabled
  );
  // Get out of the objects all the options
  const sidebarOptionsFlattened = optionsWithSidebarNode
    ?.map(s => [s.option, ...(s.children.map(c => c.option) || [])])
    .reduce((acc, curVal) => acc.concat(curVal), []);
  const optionsWithoutSidebarNode = options.filter(o => !sidebarOptionsFlattened.includes(o)).map(o => ({ option: o }));

  // combine those we could map to a sidebar node with those we couldn't, e.g. transformed values (average duration)
  const allOptions = sidebarNodes && [...filteredOptionsWithSidebarNode, ...optionsWithoutSidebarNode];
  // sort so we can get selected clusters at the top
  const getSelectedCount = option => [option, ...(option.children || [])].filter(c => c.option?.selected).length;
  const sorted = allOptions?.sort((a, b) => getSelectedCount(b) - getSelectedCount(a));
  // flatten the column options into an array, but still keeping the information if it's a child or not
  const flattened = sorted
    ?.map(s => [
      s.option,
      ...(s.children?.map(c => ({ ...c.option, isChild: true })) || []).sort((a, b) => !!b.selected - !!a.selected)
    ])
    .reduce((acc, curVal) => {
      return acc.concat(curVal);
    }, []);

  // if it's a child, then add padding so we get the sense of hierarchy
  const formattedOptions = flattened?.map(item => ({
    ...item,
    text: item.name,
    value: item.uniqueId,
    ...(item.isChild ? { classes: { container: { paddingLeft: '10px' } } } : {})
  }));

  const formattedSelectedOptions = state.existing.map(item => ({ ...item, text: item.name, value: item.uniqueId }));

  // to not take a lot of space in mobile, we don't show any text
  const formattedLabel = isMobile
    ? ''
    : t('modifications.x-of-y-columns', {
        x: state.existing.length || '-',
        y: options.length || '-'
      });

  return (
    <div className={styles.tableOptionsBar}>
      <PopoverButton
        button={
          <Button
            className={styles.customSize}
            label={formattedLabel}
            disabled={state.loading}
            loading={state.loading}
            showArrow
          />
        }
        allowBackgroundInteraction
        popoverClosingActionsFromChild={{ onConfirm: modifyColumns }}
        buttonProps={{ 'data-test': 'modifyColumnsButton' }}
      >
        <MultiselectList
          options={formattedOptions}
          selectedOptions={formattedSelectedOptions}
          hasPredefinedSelectedOptions
          confirmButtonText={t('apply')}
          cancelButtonText={t('cancel')}
          loading={state.loading}
          itemDataTest="selectColumnsItem"
        />
      </PopoverButton>
    </div>
  );
};

export function mapStateToProps(state, ownProps) {
  return {
    nodes: state.graph.nodes,
    sidebarNodeLayouts: state.graph.sidebarNodeLayouts
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(ModifyColumnButton));
