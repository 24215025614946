import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isValidQuarter, isValidYear, getQuarterOfTheYear } from 'utils/datetimeUtils';

import CheckIcon from 'svg/checkmark.svg';

import { Button, Select } from 'components/v3';

import useStyles from './QuarterPicker.styles';

// In case we don't get a date through "value" prop, we
// start with today's date;
const today = new Date();
const { year: currentYear, quarter: currentQuarter } = getQuarterOfTheYear(today);

// Populates the "year" Select using today's date as base
// and adding 50 years before and 50 years after
// as I (Marcus) suppose this might is enough.
const getYearOptions = () => {
  const years = [];

  for (let i = currentYear - 50; i <= currentYear + 50; i++) {
    const year = i.toString();
    years.push({ label: year, value: year });
  }
  return years;
};

const QuarterSelector = ({ quarterDateValue, onConfirm, onClose }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const [yearValue, setYearValue] = useState(currentYear.toString());
  const [quarterValue, setQuarterValue] = useState(currentQuarter.toString());

  const quarterInitial = t('quarter-initial');

  // Populates the "quarter" Select;
  const quarterOptions = [
    { label: `${quarterInitial}1`, value: '1' },
    { label: `${quarterInitial}2`, value: '2' },
    { label: `${quarterInitial}3`, value: '3' },
    { label: `${quarterInitial}4`, value: '4' }
  ];

  const yearOptions = useMemo(() => getYearOptions(), []);

  useEffect(() => {
    const [year, quarter] = quarterDateValue.split(`-${quarterInitial}`);

    if (isValidYear(parseInt(year)) && yearOptions.some(item => item.value === year)) {
      setYearValue(year);
    }

    if (isValidQuarter(parseInt(quarter))) {
      setQuarterValue(quarter);
    }
  }, [quarterDateValue]);

  const onYearSelectChange = event => {
    const value = event.target.value;
    setYearValue(value);
  };

  const onQuarterSelectChange = event => {
    const value = event.target.value;
    setQuarterValue(value);
  };

  const handleConfirm = () => {
    onConfirm(`${yearValue}${quarterInitial}${quarterValue}`);
    onClose();
  };

  return (
    <>
      <Select
        layout="veezoo"
        value={yearValue}
        onChange={onYearSelectChange}
        options={yearOptions}
        sx={styles.yearSelect}
      />
      <Select
        layout="veezoo"
        value={quarterValue}
        onChange={onQuarterSelectChange}
        options={quarterOptions}
        sx={styles.quarterSelect}
      />
      <Button
        layout="veezoo"
        mode="dark"
        size="inputInline"
        icon={<CheckIcon />}
        iconOnly
        onClick={handleConfirm}
        classes={styles.confirmButton}
      />
    </>
  );
};
export default QuarterSelector;
