import { memo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

import { trackEvent } from 'utils/eventTracking';

import styles from './button.scss';
import { studioRoutes } from 'components/app/routes';

/**
 * Builds the Studio editor URL for a given KG ID, file path, and optional concept ID.
 */
export function buildStudioLink(kgId, filePath, vklQualifiedIdentifier) {
  let link = `${studioRoutes.editor}?id=${kgId}&file=${filePath}`
    // remove the leading slash s.t. the (potential) context path from the <base> tag is taken into account
    .replace(/^\//, '');
  if (vklQualifiedIdentifier) {
    link += `&concept=${vklQualifiedIdentifier}`;
  }
  return link;
}
/** A button that opens studio for the given concept in the given file
 *
 * The button is only shown if the user is at least a superuser and the knowledge graph is not a demo graph.
 *
 * @param filePath file to open in studio (required)
 * @param vklQualifiedIdentifier VKL identifier of the concept to jump to (optional)
 * @param isStandalone whether the button is standalone, otherwise it is assumed to be used as a footer
 * @param isSuperUserOrHigher whether the user is a superuser or higher (otherwise it will not be shown)
 * @param kgMeta metadata of the knowledge graph
 * @param t translation function
 */
const JumpToStudio = ({ filePath, vklQualifiedIdentifier, isStandalone, isSuperUserOrHigher, kgMeta, t }) => {
  const trackBeforeRedirecting = () =>
    trackEvent('Jump to Studio Button Clicked', {
      kgId: kgMeta.id,
      filePath,
      vklQualifiedIdentifier
    });

  const link = buildStudioLink(kgMeta.id, filePath, vklQualifiedIdentifier);
  return (
    isSuperUserOrHigher &&
    !kgMeta.isDemo && (
      <a
        className={styles.jumpToLinkA}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        data-test="kgDetailViewJumpToEditor"
        onClick={trackBeforeRedirecting}
      >
        <div className={classnames(styles.jumpToLinkContainer, isStandalone && styles.jumpToLinkContainerStandalone)}>
          <span className={styles.jumpToLinkText}>{t('sidebar.open-code-in-editor')} &nbsp;&nbsp;</span>
          <span className={`${styles.rightIcon} icon-new-window`} />
        </div>
      </a>
    )
  );
};

const mapStateToProps = state => ({
  isSuperUserOrHigher: state.user.isSuperUser || state.user.isAdmin,
  kgMeta: state.knowledgeGraphMeta.meta
});

export default withTranslation('veezoo')(connect(mapStateToProps)(memo(JumpToStudio)));
