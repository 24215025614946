export default () => ({
  popover: {
    '& .MuiPopover-paper': {
      padding: '7px 15px '
    }
  },
  calendar: {
    cursor: 'pointer'
  },
  yearSelect: {
    '&.MuiTextField-root': {
      width: '80px'
    }
  },
  quarterSelect: {
    '&.MuiTextField-root': {
      width: '67px',
      marginLeft: '10px'
    }
  },
  confirmButton: {
    root: {
      marginLeft: '10px',
      marginTop: '4px'
    }
  }
});
