import clsx from 'clsx';

import { CustomTable, Button } from 'components/v3';

import CheckIcon from 'svg/checkmark.svg';
import GroupIcon from '@mui/icons-material/Group';
import ShareIcon from '@mui/icons-material/Share';

import { datetimeToTranslatableObject } from 'utils/datetimeUtils';

import { useStyles, sx } from './BrowseBoards.styles';
import LoadingIcon from 'components/loaders/LoadingIcon';
import { pluralize as plrl } from 'studio/helpers';

import { layouts } from 'config/constants';

const DividerDot = () => {
  const { classes: styles } = useStyles();
  return (
    <div className={styles.dividerDot}>
      <span />
    </div>
  );
};

const EnabledLabel = ({ t }) => {
  const { classes: styles } = useStyles();
  return (
    <>
      <CheckIcon className={styles.subscribedIcon} />
      <span className={styles.subscribedLabel}>{t('browse-boards.subscribed')}</span>
    </>
  );
};

const SharerLabel = ({ t }) => {
  const { classes: styles } = useStyles();
  return (
    <>
      <ShareIcon sx={sx.shareIcon} />
      <span className={styles.sharerLabel}>
        {t('browse-boards.shared-by', { sharer: t('browse-boards.shared-by-you') })}
      </span>
    </>
  );
};

const CreatorDateLabel = ({ dateTranslated, creator, t }) => {
  if (creator) {
    return <span>{t('browse-boards.created-on-by', { time: dateTranslated, creator: creator })}</span>;
  } else {
    // for VKL-boards defined in the KG, the creator is not defined
    return (
      <>
        <span>{t('browse-boards.created-on', { time: dateTranslated })}</span>
        <DividerDot />
        <span>{t('browse-boards.defined-in-kg')}</span>
      </>
    );
  }
};

const SubscribersLabel = ({ t, subscribersCount }) => {
  return subscribersCount > -1 ? (
    <>
      <GroupIcon sx={sx.subscribersIcon} />
      <span data-test="boardSubscribersCount">
        {subscribersCount} {plrl(subscribersCount, t('browse-boards.subscriber'), t('browse-boards.subscribers'))}
      </span>
    </>
  ) : (
    <LoadingIcon size={9} color="var(--normal-gray)" />
  );
};

const SubscribeButton = ({ subscribed, toggleSubscribe, t }) => {
  const color = subscribed ? 'var(--error-color)' : 'var(--success-color)';
  const label = subscribed ? t('browse-boards.unsubscribe-action') : t('browse-boards.subscribe-action');

  const customClasses = {
    root: {
      color,
      borderColor: color,
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: color,
        color: 'white'
      }
    }
  };

  return (
    <Button
      layout={layouts.veezoo}
      size="small"
      classes={customClasses}
      onClick={_ => toggleSubscribe(!subscribed)}
      data-test="changeBoardSubscriptionButton"
    >
      {label}
    </Button>
  );
};

const CustomInfoCell = ({
  title,
  ownerFullName,
  createdOn,
  sharedOn,
  isEnabled,
  isSelfOwned,
  subscribersCount,
  toggleSubscribe,
  onPreviewClick,
  t
}) => {
  const { classes: styles } = useStyles();

  const currentDate = new Date();
  const dateCreated = datetimeToTranslatableObject({ first: currentDate, second: createdOn });
  const dateCreatedTranslated = t(`datetime.${dateCreated.text}`, { value: dateCreated.value || 0 });
  const dateShared = datetimeToTranslatableObject({ first: currentDate, second: sharedOn });
  const dateSharedTranslated = t(`datetime.${dateShared.text}`, { value: dateShared.value || 0 });

  return (
    <div className={styles.customCelContainer} data-test="browseBoardsListEntry">
      <span className={styles.customCellTitle}>{title}</span>
      <br />
      <span className={styles.customCellDetails}>
        {isEnabled && !isSelfOwned && (
          <>
            <EnabledLabel t={t} />
            <DividerDot />
          </>
        )}
        {isSelfOwned && (
          <>
            <SharerLabel t={t} />
            <DividerDot />
          </>
        )}
        <span>{t('browse-boards.shared-on', { time: dateSharedTranslated })}</span>
        <DividerDot />
        <CreatorDateLabel
          t={t}
          dateTranslated={dateCreatedTranslated}
          creator={isSelfOwned ? t('browse-boards.shared-by-you') : ownerFullName}
        />
        {/* don't display the subscribers label if there was an error retrieving the subscribers */}
        {subscribersCount !== -1 && (
          <>
            <DividerDot />
            <SubscribersLabel t={t} subscribersCount={subscribersCount} />
          </>
        )}
      </span>
      <div className={clsx(styles.customCellButtonsContainer, styles.displayOnlyOnHover)}>
        <div className={styles.button}>
          <Button layout={layouts.veezoo} size="small" color="var(--primary-color)" onClick={onPreviewClick}>
            {t('preview')}
          </Button>
        </div>
        {!isSelfOwned && (
          <div className={styles.button}>
            <SubscribeButton subscribed={isEnabled} toggleSubscribe={toggleSubscribe} t={t} />
          </div>
        )}
      </div>
    </div>
  );
};

const BrowseBoardsList = ({ user, boards, subscribersCounts, toggleSubscribe, onPreviewClick, t }) => {
  const columns = [
    {
      key: 'name',
      value: 'Name',
      textAlign: 'left',
      custom: board => (
        <CustomInfoCell
          t={t}
          title={board.boardName}
          ownerFullName={board.owner?.fullName}
          createdOn={board.createdOn}
          sharedOn={board.sharedOn}
          isEnabled={board.isEnabled}
          isSelfOwned={board.owner?.id === user.id}
          subscribersCount={subscribersCounts[board.id]}
          onPreviewClick={() => onPreviewClick(board)}
          toggleSubscribe={isEnabled => toggleSubscribe(board.boardId, isEnabled)}
        />
      )
    }
  ];
  return (
    <CustomTable rows={boards} columns={columns} emptyTableMessage={t('browse-boards.no-boards-found')} hideTitle />
  );
};

export default BrowseBoardsList;

BrowseBoardsList.defaultProps = {
  t: text => text
};
