import React, { memo, useContext, useMemo } from 'react';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './filter-pill-container.scss';
import FilterPill from 'components/shared/FilterPill';

import Q2QEditableEntityPassage from './Q2QEditableEntityPassage';
import { Q2QStateContext } from './Q2QStateContext';
import Q2QEditableBooleanPassage from './Q2QEditableBooleanPassage';
import Q2QEditableDatePassage from './Q2QEditableDatePassage';
import Q2QEditableTemplatePassage from './Q2QEditableTemplatePassage';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { InfoTooltip } from 'components/v3';
import { toggleFollowUp } from '../../store/modules/followUpState';

import AddCircleIcon from 'svg/v2/plus.svg';
import { trackEvent } from 'utils/eventTracking';

const useAddButtonStyles = makeStyles({
  root: {
    padding: 0,
    margin: 0
  }
});

const FilterPillContainer = ({ list, title, t, dispatch, answerData, messageId, answerId, possibleToAddNewFilter }) => {
  const q2qStateContext = useContext(Q2QStateContext);

  const structuredFilters =
    q2qStateContext.answerData?.structuredFilters || q2qStateContext.answerData?.commonFiltersStructured || [];

  const formattedFilters = useMemo(
    () =>
      structuredFilters.map((filter, index) => {
        let label = null;

        if (filter.outgoingDateFilterEdit) {
          label = <Q2QEditableDatePassage answerData={q2qStateContext.answerData} filter={filter} />;
        } else if (filter.booleanTypeLiteralFilter) {
          label = <Q2QEditableBooleanPassage key={index} answerData={q2qStateContext.answerData} filter={filter} />;
        } else if (filter.editInfos?.length > 0 && filter.editInfos[0].type === 'TEMPLATE') {
          label = (
            <Q2QEditableTemplatePassage
              key={index}
              answerData={q2qStateContext.answerData}
              passages={filter.passages}
              editInfo={filter.editInfos[0]}
            />
          );
        } else {
          label = <Q2QEditableEntityPassage key={index} passages={filter.passages} />;
        }

        return {
          ...filter,
          index,
          label
        };
      }),
    [structuredFilters, q2qStateContext.hoveredId]
  );

  const filters = useMemo(() => {
    if (formattedFilters?.length > 0) return formattedFilters;
    if (list?.length > 0) return list;
    return null;
  }, [formattedFilters, list]);

  const addButtonClasses = useAddButtonStyles();

  const addNewFilterButton = (
    <InfoTooltip text={t('tooltip.add-new-filter')}>
      <IconButton
        classes={addButtonClasses}
        aria-label={t('tooltip.add-new-filter')}
        onClick={() => {
          dispatch(
            toggleFollowUp(answerData.interpretationId, answerData.partialAnswerId, answerId, messageId),
            trackEvent('Add New Filter Button Clicked', {
              answerId: answerId,
              interpretationId: answerData.interpretationId,
              isBoardFollowUp: !answerData.interpretationId
            })
          );
        }}
      >
        <AddCircleIcon className={styles.AddCircleIcon} />
      </IconButton>
    </InfoTooltip>
  );

  return (
    <div className="list-container">
      {title && <p className={styles.title}>{title}:&nbsp;</p>}
      <ul className={styles.list}>
        {filters?.map((filter, index) => {
          return (
            <FilterPill
              key={index}
              isGreyedOut={filter.isGreyedOut}
              isHighlighted={filter.isHighlighted}
              isRemoved={filter.isRemoved}
              selected={!!q2qStateContext.options.anchor && q2qStateContext.options.highlightedPill === filter.index}
              removeInfo={filter.removeInfo}
              text={filter.passages?.map(passage => passage.text).join('')}
            >
              {filter.label}
            </FilterPill>
          );
        })}
        {possibleToAddNewFilter && addNewFilterButton}
      </ul>
    </div>
  );
};

FilterPillContainer.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  answerData: PropTypes.object,
  answerId: PropTypes.string,
  messageId: PropTypes.string,
  possibleToAddNewFilter: PropTypes.bool
};

export default memo(withTranslation('veezoo')(FilterPillContainer));
