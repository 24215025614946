import { get, post, del, patch } from 'services/http';

const createBoard = (boardName, widgets, answer) =>
  post('/boards', {
    name: boardName,
    widgets: widgets,
    fromAnswer: answer
  });

const getBoards = () => get('/boards');

const renameBoard = (id, name) => patch(`/boards/${id}`, { id, name });

const makeVklBoard = id => post(`/boards/make-vkl/${id}`);

const getVklBoard = id => get(`/boards/${id}/vkl`);

const copyBoard = (id, name) => post('/boards/copy', { boardId: id, copiedBoardName: name });

const getBoardPreview = id => get(`/boards/preview/${id}`);

const publishBoardChanges = id => post(`/boards/publish/${id}`, {});

const deleteBoard = id => del(`/boards/${id}`);

const setBoardEnabled = (id, isEnabled) => post(`/boards/discovery/${id}`, { isEnabled });

const moveBoardView = (id, parentId, aboveId) =>
  post(`/boards/view/item/move/${id}`, { parentId: parentId || 'root', aboveId });

const getBoardsView = () => get('/boards/view');

const createFolder = ({ name, parentId = 'root', aboveId }) =>
  post('/boards/view/folder', { name, parentId: parentId || 'root', aboveId });

const renameFolder = (id, name) => post(`/boards/view/folder/name/${id}`, { name });

const deleteFolder = id => del(`/boards/view/folder/${id}`);

const getSharedBoardInfo = boardId => get(`/boards/shared/${boardId}`);

const saveSharedBoard = (boardId, data) => post(`/boards/shared/${boardId}`, data);

const stopSharingBoard = boardId => del(`/boards/shared/${boardId}`);

const getDiscoverableBoards = () => get('/boards/discovery');

const getSharedBoardSubscribers = boardId => get(`/boards/discovery/${boardId}/subscribers`);

const getWidgetsByBoardId = boardId => get(`/boards/${boardId}/widgets`);

const getUserBoards = userId => get(`/studio/api/users/${encodeURIComponent(userId)}/boards`);

const transferBoards = (toUserId, boardIds) => post('/studio/api/boards/transfer', { toUserId, boardIds });

export default {
  getBoards,
  renameBoard,
  copyBoard,
  getBoardPreview,
  publishBoardChanges,
  getBoardsView,
  createBoard,
  createFolder,
  renameFolder,
  moveBoardView,
  makeVklBoard,
  getVklBoard,
  deleteFolder,
  deleteBoard,
  setBoardEnabled,
  getSharedBoardInfo,
  saveSharedBoard,
  stopSharingBoard,
  getDiscoverableBoards,
  getSharedBoardSubscribers,
  getWidgetsByBoardId,
  getUserBoards,
  transferBoards
};
