import { memo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { InfoTooltip } from 'components/v3';
import styles from 'components/sidebar/sidebar.scss';
import { withRouter } from 'react-router-dom';
import withRestrictedFunctionality from 'root/hocs/withRestrictedFunctionality';
import { VeezooStudioIcon } from 'components/Icons';

import useMediaQuery from 'utils/mediaQueries';

const StudioTab = ({ t, kgMeta, disabled, isOpenDemoUser, restrictedFunctionality, sidebarIsMinimized }) => {
  // Only set a click handler if we are in demo mode (otherwise use property of <a> to open studio)
  const handleOnClick = () => restrictedFunctionality(() => undefined);

  const isMobile = useMediaQuery();

  const button = (
    <button className={clsx('button-secondary', styles.sidebarButton)} disabled={disabled}>
      <VeezooStudioIcon className={clsx(styles.opaque, disabled ? 'disabled' : '')} />
      {(!sidebarIsMinimized || isMobile) && (
        <span className={clsx(styles.sidebarButtonLabel, styles.opaque, disabled ? 'disabled' : '')}>
          Veezoo Studio
        </span>
      )}
      <div className={clsx(styles.opaque, styles.rightIcon)}>
        <span className={clsx('icon-new-window', disabled ? 'disabled' : '')} />
      </div>
    </button>
  );

  if (disabled) return button;

  return (
    <a
      data-overview-tutorial="studio"
      className={styles.link}
      id="StudioIconHook"
      // If the KG is a demo graph, don't jump directly to the configuration - but studio overview
      // If the current user is a demo user, don't allow to jump to studio at all
      href={isOpenDemoUser ? undefined : kgMeta.isDemo ? 'studio' : `studio?id=${kgMeta.id}`}
      onClick={handleOnClick}
      target="_blank"
      rel="noopener noreferrer"
      data-test="chatStudioLink"
    >
      <InfoTooltip text={t('tooltip.studio')} placement="right">
        {button}
      </InfoTooltip>
    </a>
  );
};

const mapStateToProps = state => ({
  kgMeta: state.knowledgeGraphMeta.meta,
  isOpenDemoUser: state.user.isOpenDemoUser
});

export default withRouter(memo(connect(mapStateToProps)(withRestrictedFunctionality(StudioTab))));
