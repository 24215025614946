import { createRef, Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

import Chart from 'components/chart/Chart';

import BaseMessage from './BaseMessage';
import AnswerMessageFooter from './AnswerMessageFooter';
import AnswerMessageHeader from './AnswerMessageHeader';
import QuestionPill from 'components/pills/QuestionPill';

import ModifyColumnButton from 'components/chart/ModifyColumnButton';

import styles from './answer-message.scss';
import { answerVisualizationPlaceholders, chartTypes } from 'config/constants';
import ContentLoader from 'components/loaders/ContentLoader';
import domtoimage from 'dom-to-image';
import { shareAnswer, uploadSharingPreviewImage } from 'store/modules/sharing';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import { getAnswerTotalCount } from 'store/modules/chat-messages';
import { trackEvent } from 'utils/eventTracking';
import { CodeBlock } from 'components/shared/CodeDialog';
import { adaptVisualizations, mergeVisualizationOptions } from 'utils/chartUtils';

import InvertButton from './InvertButton';

export class AnswerMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showRawData: false,
      showSql: false,
      renderHiddenAnswerForImagePreview: false,
      activeChartIndex: props.initialActiveChartIndex || 0,
      prevActiveChartIndex: 0,
      isChartInverted: props.initialChartIsInverted || false
    };

    this.answerMessageRef = createRef();
  }

  combinedAnswer = () => {
    // answer contains the parsing result and partial answer contains the execution result with visualizations
    const combinedAnswer = { ...this.props.answer, ...this.props.partialAnswer };

    return {
      ...combinedAnswer,
      visualizations: adaptVisualizations(combinedAnswer.visualizations, this.state.isChartInverted)
    };
  };

  mainTableIndex(props) {
    const chartTypesInOrderOfPreference = [
      chartTypes.HIERARCHICAL_TABLE_CHART,
      chartTypes.TABLE_CHART // default chart type
    ];

    const visualizations = this.combinedAnswer(props).visualizations || [];
    for (const chartType of chartTypesInOrderOfPreference) {
      const index = visualizations.findIndex(visualization => visualization.type === chartType);
      if (index >= 0) {
        return index;
      }
    }

    return -1; // if no table chart type is found
  }

  veezooMapChartIndex(props) {
    return this.combinedAnswer(props).visualizations?.findIndex(v => v.type === chartTypes.VEEZOO_MAP_CHART);
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, sharedAnswer, dispatch } = this.props;
    // define scroll bottom position if type of chart is changing
    if (prevState.activeChartIndex !== this.state.activeChartIndex) {
      this.props.scrollTo && this.props.scrollTo(id);
    }

    if (!prevProps?.sharedAnswer && sharedAnswer) {
      this.setState({ renderHiddenAnswerForImagePreview: true });
    }
    if (!prevProps?.sharedAnswer?.url && sharedAnswer?.url && this.props?.isAllowedToUploadImage) {
      this.getAndUploadSharingPreviewImage(dispatch, sharedAnswer);
    }
    // Show SQL query on error
    if (!prevProps.partialAnswer?.isError && this.props.partialAnswer?.isError && this.props.partialAnswer?.sql) {
      this.setState({ showSql: true });
    }
  }

  getAndUploadSharingPreviewImage(dispatch, sharedAnswer) {
    let node = document.getElementById(this.props.answerId);
    setTimeout(
      () =>
        domtoimage
          .toPng(node, {
            width: 1200,
            height: 630,
            bgcolor: '#eff0f1'
          })
          .then(dataUrl => {
            dispatch(uploadSharingPreviewImage(sharedAnswer.sharedAnswerId, dataUrl));
          })
          .catch(error => {
            console.error('oops, something went wrong sharing the preview image!', error);
          }),
      2000
    );
  }

  currentAnswerType = () => {
    return this.combinedAnswer(this.props)?.visualizations?.[this.state.activeChartIndex]?.type;
  };

  getVisualizationOptionsObject = () => {
    let answer = this.combinedAnswer(this.props);
    const metaInfo = this.props.metaInfo;
    const hasVisualizations = answer.visualizations && answer.visualizations.length > 0;
    const visualizationOptions =
      hasVisualizations && answer.visualizations[this.state.activeChartIndex].visualizationOptions;
    const customVisualizationOptions =
      this.currentAnswerType() === metaInfo?.type ? metaInfo?.visualizationOptions : undefined;
    const modifiedVisualizationsFiltered = this.props.modifiedVisualizations.filter(
      m => m.partialAnswerId === answer.partialAnswerId && m.visualizationType === this.currentAnswerType()
    );

    return mergeVisualizationOptions(visualizationOptions, customVisualizationOptions, modifiedVisualizationsFiltered);
  };

  onShareAnswerClick = () => {
    this.props.dispatch(
      shareAnswer(this.props.answer.partialAnswerId, this.currentAnswerType(), this.getVisualizationOptionsObject())
    );
  };

  addToBoard = () => {
    let answer = this.combinedAnswer(this.props);
    const visualizationOptions = this.getVisualizationOptionsObject();

    const widgetTitle = this.props.question || answer.title;
    const widgetData = {
      interpretationId: answer.interpretationId,
      title: widgetTitle,
      type: this.currentAnswerType(),
      fromPartialAnswer: answer.partialAnswerId
    };

    // Add visualization options to widgetData if they are not empty
    if (Object.keys(visualizationOptions).length > 0) {
      widgetData.visualizationOptions = visualizationOptions;
    }
    this.props.addToBoardCallback(widgetData);
  };

  toggleShowRawData = () => {
    if (this.state.showSql) {
      this.toggleShowSql();
    }
    let answer = this.combinedAnswer(this.props);
    this.setState((prevState, props) => {
      const prevActiveChartIndex = !prevState.showRawData ? prevState.activeChartIndex : prevState.prevActiveChartIndex;
      const activeChartIndex = prevState.showRawData ? prevState.prevActiveChartIndex : this.mainTableIndex(props);
      const from = answer.visualizations?.[prevState.activeChartIndex]?.type;
      const to = answer.visualizations?.[activeChartIndex]?.type;
      trackEvent('Answer Visualization Changed', { from, to });
      return {
        prevActiveChartIndex,
        activeChartIndex,
        showRawData: !prevState.showRawData
      };
    });
  };

  toggleShowSql = () => {
    this.setState((prevState, props) => {
      return {
        showSql: !prevState.showSql
      };
    });
  };

  toggleShowOpenInVeezooMapButton = () => {
    this.setState((prevState, props) => {
      return {
        prevActiveChartIndex: prevState.activeChartIndex,
        activeChartIndex:
          prevState.activeChartIndex === this.veezooMapChartIndex(props)
            ? prevState.prevActiveChartIndex
            : this.veezooMapChartIndex(props),
        showRawData: false
      };
    });
  };

  changeToVisualizationType = type => {
    let answer = this.combinedAnswer(this.props);
    // if we are in raw data mode, break out of it
    if (this.state.showRawData) {
      this.toggleShowRawData();
    } else {
      this.setState(prevState => {
        let newIndex = answer.visualizations.findIndex(v => v.type === type);
        const from = answer.visualizations?.[prevState.activeChartIndex]?.type;
        const to = answer.visualizations?.[newIndex]?.type;
        trackEvent('Answer Visualization Changed', { from, to });

        return {
          prevActiveChartIndex: prevState.activeChartIndex,
          activeChartIndex: newIndex
        };
      });
    }
  };

  handleInvertButtonClick = () => this.setState(prev => ({ ...prev, isChartInverted: !prev.isChartInverted }));

  /**
   * The parameter [[isHiddenAnswerForPreviewImage]] is set so that we can generate a preview image based on the answer,
   * but without displaying it to the user.
   */
  createAnswerContent(isHiddenAnswerForPreviewImage, customVisualizationOptions) {
    const { activeChartIndex, showSql } = this.state;
    const {
      answerId,
      id,
      customHeader,
      customFilters,
      referenceTo,
      localQuestionId,
      questionId,
      customerSelectionId,
      parserAlternatives,
      textAnswer,
      t
    } = this.props;
    let answer = this.combinedAnswer(this.props);
    // Contains text specific to the (partial) answer, compared to the other textAnswer
    let answerTextAnswer = answer.textAnswer;
    let isLoading = !this.props.partialAnswer;

    let chart;
    const hasVisualizations = answer.visualizations && answer.visualizations.length > 0;
    const visualization = hasVisualizations && answer.visualizations[activeChartIndex];

    if (showSql) {
      chart = (
        <div className={styles.sqlContainer}>
          <CodeBlock code={answer.sql} language="sql" />
        </div>
      );
    } else if (hasVisualizations) {
      chart = (
        <div
          data-answer-tutorial="visualization"
          data-visualization-type={visualization.type}
          className={styles.chartContainer}
        >
          {visualization.allowInvert && (
            <InvertButton
              className={styles.invertButton}
              isInverted={this.state.isChartInverted}
              onClick={this.handleInvertButtonClick}
            />
          )}
          <Chart
            type={visualization.type}
            visualizationOptions={visualization.visualizationOptions}
            customVisualizationOptions={customVisualizationOptions}
            metaInfo={this.props.metaInfo}
            key={activeChartIndex}
            isTableInWidget={false}
            isInAnswerMessage={true}
            chart={visualization.chart}
            nonce={visualization.nonce}
            answer={answer}
            id={id + (isHiddenAnswerForPreviewImage ? 'hidden_for_preview_image' : '')}
          />
        </div>
      );
    } else if (isLoading) {
      chart = (
        <div className="sizeHTMLchart">
          <ContentLoader isAnswerToQuestion={!!questionId && !customerSelectionId} />
        </div>
      );
    } else {
      chart = null;
    }

    const newVisualizationSummary = () => {
      if (!hasVisualizations || visualization.summary === '') {
        return '';
      }

      const summaryArray = visualization.summary.split(answerVisualizationPlaceholders.TOTAL_NUMBER_PLACEHOLDER);

      if (summaryArray.length <= 1) {
        return summaryArray[0];
      }

      return (
        <>
          {summaryArray[0]}
          <div style={{ display: 'inline-block' }}>
            <QuestionPill
              onClick={() => this.props.dispatch(getAnswerTotalCount(answer.partialAnswerId))}
              additionalClassName={styles.totalNumberPill}
              suggestion={{
                inputOffset: 0,
                passageInfos: [],
                text: t('total-number')
              }}
              loading={visualization.fetchingTotalCount}
            />
          </div>
          {summaryArray[1]}
        </>
      );
    };

    let visualizationSummary = newVisualizationSummary();
    const summary = (
      <>
        {textAnswer && (
          <>
            {textAnswer}
            <br />
          </>
        )}
        {answerTextAnswer && (
          <>
            {answer.isError ? (
              <div className={styles.errorMessage}>
                <ErrorRoundedIcon className={styles.errorIcon} />
                {answerTextAnswer}
              </div>
            ) : (
              answerTextAnswer
            )}
            <br />
          </>
        )}

        {visualizationSummary}
      </>
    );

    const header = (
      <div className={styles.headerContainer}>
        <div className={styles.answerMessageHeaderContainer}>
          <AnswerMessageHeader
            customHeader={customHeader}
            structuredTitle={answer.structuredTitle}
            filters={customFilters ? customFilters : answer.filters}
            answerId={answerId}
            id={id}
            answerData={answer}
            alternativeInterpretations={answer.alternativeInterpretations}
            confidence={answer.confidence}
            referenceTo={referenceTo}
            localQuestionId={localQuestionId}
            parserAlternatives={parserAlternatives}
            isMobile={this.props.isMobile}
          />
          <div className={styles.pendingBoard}>{summary}</div>
        </div>
      </div>
    );

    const modifyColumnButton = hasVisualizations && (
      <div className={styles.modifyColumnButtonContainer}>
        <div className={styles.modifyColumnButton}>
          <ModifyColumnButton {...this.props} />
        </div>
      </div>
    );

    return (
      <div className={styles.messageAnswer}>
        {header}
        {modifyColumnButton}
        {chart}
        <div className={styles.placeholder} />
      </div>
    );
  }

  render() {
    const { showRawData, renderHiddenAnswerForImagePreview, activeChartIndex } = this.state;
    const {
      id,
      questionId,
      answerId,
      question,
      isComplementary,
      referenceTo,
      disableFooter,
      displayAnswerTutorial,
      customFooter,
      localQuestionId,
      disableFeedback,
      disableRetry,
      disableHideMessageButton,
      timestamp,
      feedback,
      partialAnswer,
      metaInfo
    } = this.props;

    let answer = this.combinedAnswer(this.props);
    const customVisualizationOptions =
      this.currentAnswerType() === metaInfo?.type ? metaInfo?.visualizationOptions : undefined;
    let messageContent = this.createAnswerContent(false, customVisualizationOptions);

    const footer = customFooter ? (
      customFooter
    ) : (
      <AnswerMessageFooter
        answer={answer}
        partialAnswer={partialAnswer}
        answerId={answerId}
        id={id}
        toggleShowRawData={this.toggleShowRawData}
        toggleShowOpenInVeezooMapButton={this.toggleShowOpenInVeezooMapButton}
        showOpenInVeezooMapButton={this.veezooMapChartIndex(this.props) >= 0}
        veezooMapChartIsActive={activeChartIndex === this.veezooMapChartIndex(this.props)}
        showRawData={showRawData}
        tableIndex={this.mainTableIndex(this.props)}
        changeToVisualizationType={this.changeToVisualizationType}
        addToBoard={this.addToBoard}
        onShareAnswerClick={this.onShareAnswerClick}
        isFollowUp={referenceTo}
        targetRef={this.answerMessageRef}
        question={question}
        questionId={questionId}
        feedback={feedback}
        visualizationType={this.currentAnswerType()}
        customVisualizationOptions={customVisualizationOptions}
        visualizations={answer?.visualizations}
      />
    );

    const baseWrapperClass = classnames({
      ComplementaryAnswer: isComplementary,
      MainAnswer: !isComplementary
    });

    let hiddenAnswerForImagePreview;
    if (renderHiddenAnswerForImagePreview) {
      hiddenAnswerForImagePreview = (
        <span style={{ position: 'absolute', left: -2000 }}>
          <div className={baseWrapperClass} style={{ width: 1200, height: 630 }} id={answerId}>
            <BaseMessage
              messageId={id}
              questionId={questionId}
              interpretationId={answer.interpretationId}
              answerId={answerId}
              content={this.createAnswerContent(true, customVisualizationOptions)}
              showVeezooIcon={!(isComplementary || disableFooter)}
              customFooter={null}
              enableStandardFooter={false}
              isFollowUp={!!referenceTo}
              timestamp={timestamp}
              feedback={feedback}
            />
          </div>
        </span>
      );
    }

    return (
      <>
        <div
          ref={this.answerMessageRef}
          className={baseWrapperClass}
          data-display-tutorial={displayAnswerTutorial && !isComplementary ? 'answer' : null} // don't need to display AnswerTutorial for complementary answer
          data-interpretation-id={answer.interpretationId} // these attributes are here s.t. on follow up click we know what it refers to
          data-answer-id={answerId}
          data-question-id={questionId}
        >
          <BaseMessage
            messageId={id}
            questionId={questionId}
            interpretationId={answer.interpretationId}
            answerId={answerId}
            content={messageContent}
            localQuestionId={localQuestionId}
            alternativeInterpretations={answer.alternativeInterpretations}
            showVeezooIcon={!(isComplementary || disableFooter)}
            customFooter={disableFooter ? null : footer}
            enableStandardFooter={!disableFooter}
            isFollowUp={!!referenceTo}
            timestamp={timestamp}
            disableHideMessageButton={disableHideMessageButton}
            feedback={feedback}
            enableFeedback={!disableFeedback && !isComplementary}
            // enable retry for all answer messages that are not modifications, as long as retry is not disabled
            enableRetry={!disableRetry && !answer.isModification}
          />
        </div>
        {hiddenAnswerForImagePreview}
      </>
    );
  }
}

const getMetaInfo = (state, partialAnswerId) => {
  const widget = state.boardWidgets
    .flatMap(bw => bw?.widgets)
    .find(w => w?.answer?.partialAnswerId === partialAnswerId);
  const metaPartialAnswer = state.chatMessages
    .flatMap(m => m?.metaPartialAnswers)
    .filter(p => p !== undefined)
    .find(p => p.partialAnswerId === partialAnswerId);

  return widget || metaPartialAnswer;
};
export function mapStateToProps(state, ownProps) {
  return {
    followUpState: state.followUpState,
    partialAnswer: state.partialAnswers.find(
      w => w.answerId === ownProps.answer.answerId && w.interpretationId === ownProps.answer.interpretationId
    ),
    metaInfo: getMetaInfo(state, ownProps?.answer?.partialAnswerId),
    modifiedVisualizations: state.modifiedVisualizations,
    // We only want to share the preview image if we are using a demo KG
    isAllowedToUploadImage: state.knowledgeGraphMeta.meta.isDemo,
    isDemoKG: state.knowledgeGraphMeta.meta.isDemo,
    knowledgeGraphId: state.knowledgeGraphMeta.meta.id,
    sharedAnswer: state.sharing.sharedAnswers.find(s => s.partialAnswerId === ownProps?.answer?.partialAnswerId)
  };
}

export default withTranslation('veezoo', { withRef: true })(connect(mapStateToProps)(AnswerMessage));
