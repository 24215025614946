import { PENDING_STATUS, REJECTED_STATUS } from 'config/constants';
import { useEffect } from 'react';
import { trackEvent } from 'utils/eventTracking';
import { askLocalQuestion } from 'store/modules/chat-messages';

export const isAwaitingApproval = shareableObject =>
  (shareableObject && shareableObject.userSharingStatus === PENDING_STATUS) || false;

export const wasRejected = shareableObject =>
  (shareableObject && shareableObject.userSharingStatus === REJECTED_STATUS) || false;

/**
 * A hook to handle special hyperlinks in a chat message.
 * A special hyperlink is a hyperlink that triggers a specific action when clicked.
 * Currently, the only special hyperlink is a suggested question hyperlink (e.g., #suggested-question).
 * When a suggested question hyperlink is clicked, a new question is asked with the suggested question.
 *
 * @param containerRef The reference to the container element that contains the custom HTML content.
 * @param username The username of the user of the session
 * @param dispatch The Redux dispatch function
 * @param html The custom HTML content of the chat message, containing the special hyperlinks.
 * @param t The translation function
 */
export const useSpecialLinkHandler = (containerRef, username, dispatch, html, t) => {
  useEffect(() => {
    const handleSpecialLinkClick = event => {
      event.preventDefault();
      event.stopPropagation();

      const question = event.currentTarget.innerHTML;
      const otherParams = {
        isQuestionPill: false,
        isLlmSuggestion: true,
        isSuggested: true,
        isVoice: false,
        linkedEntities: []
      };

      // Track the event
      trackEvent('Suggested Question Hyperlink Clicked', {
        username: username,
        suggestedQuestion: question
      });

      // Dispatch the action
      dispatch(askLocalQuestion(username, question, otherParams, t));
    };

    if (containerRef.current) {
      const specialLinks = containerRef.current.querySelectorAll('a[href^="#suggested-question"]');
      specialLinks.forEach(link => {
        link.addEventListener('click', handleSpecialLinkClick);
        link.removeAttribute('target');
      });

      // Clean up event listeners on unmount
      return () => {
        specialLinks.forEach(link => link.removeEventListener('click', handleSpecialLinkClick));
      };
    }
  }, [containerRef, username, dispatch, html, t]);
};
