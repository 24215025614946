import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Title from 'components/Signup/Title';
import { Button, Stepper } from 'components/v3';
import PageLoader from 'components/Signup/PageLoader';

import TableImport from 'components/TableImport';
import ResultDialog from 'components/ResultDialog';
import MessageModal from 'components/Signup/MessageModal';

import services from 'services';
import { handleError } from 'services/http';

import { trackEvent } from 'utils/eventTracking';

import { layouts } from 'config/constants';

import signupStyles from 'pages/Signup/signup.styles.scss';
import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';

import useMediaQuery from 'utils/mediaQueries';

const ImportData = ({ knowledgeGraph, connector, setStep, setKnowledgeGraphs, allowBack, dispatch }) => {
  const [importFunction, setImportFunction] = useState(undefined);
  const [uploadResult, setUploadResult] = useState(null);
  const [pageLoader, setPageLoader] = useState({
    loading: false,
    completed: false,
    message: 'Loading...'
  });

  const [modal, setModal] = useState({
    open: false,
    message: 'Modal message',
    type: 'info'
  });

  const isMobile = useMediaQuery();

  const closeModal = useCallback(() => {
    setModal(prevModal => ({ ...prevModal, open: false }));
    setPageLoader(prevLoader => ({ ...prevLoader, loading: false }));
  }, []);

  const sendTables = useCallback(async () => {
    setPageLoader(prevLoader => ({
      ...prevLoader,
      loading: true,
      message: 'Analyzing tables...'
    }));

    let response = await importFunction();
    handleError(response, dispatch);
    if (!response.success) {
      response = response.response;
      const message = response.data.data?.message || response.data || 'An error occurred while analyzing tables.';
      trackEvent('Data Import Failed', { knowledgeGraph: { id: knowledgeGraph.id }, error: { message } });
      setModal({
        type: 'error',
        open: true,
        message: <span>{message}</span>
      });
      return;
    }

    const importResult = response.data;
    const hasSuccess = importResult.messages.some(item => item.isSuccess);
    if (!hasSuccess) {
      setUploadResult(importResult);
      setPageLoader(prevLoader => ({ ...prevLoader, loading: false }));
      return;
    }

    setPageLoader(prevLoader => ({ ...prevLoader, loading: true, message: 'Activating Knowledge Graph...' }));
    let switchResult = await services.switchToKnowledgeGraph(knowledgeGraph.id);
    handleError(switchResult, dispatch);
    if (!switchResult.success) {
      setModal({
        type: 'error',
        open: true,
        message: <span>There was an error while activating your Knowledge Graph.</span>
      });
      return;
    }
    trackEvent('Data Imported', { knowledgeGraph: { id: knowledgeGraph.id } });
    setPageLoader(prevLoader => ({ ...prevLoader, loading: false, completed: true }));
  }, [importFunction, knowledgeGraph.id]);

  const handleFetchingError = useCallback(response => handleError(response, dispatch), []);
  const handleFolderFetchingError = useCallback(() => {
    setKnowledgeGraphs([]);
    setStep(step => step - 2);
  }, []);

  // increases the step after the import
  useEffect(() => {
    if (pageLoader.completed) {
      setStep(step => step + 1);
    }
  }, [pageLoader]);

  return (
    <StepperContainer
      customContainerStyle={signupStyles.customContainer}
      customStepper={<Stepper step={3} totalSteps={5} />}
    >
      <div className={signupStyles.centerChildHorizontally}>
        <div className={signupStyles.ChooseDatabaseTablesContainer}>
          {!pageLoader.completed && (
            <div>
              <Title>Select tables and columns</Title>
              <div className={signupStyles.subTitle}>
                Select tables and columns you are interested in asking questions about.
                <br />
                <b>Note:</b> We recommend that you select just a few columns to get going - you can always add more
                columns later.
              </div>
            </div>
          )}
          <div className={clsx(signupStyles.pageContent, signupStyles.listContainer)}>
            {!pageLoader.loading && !pageLoader.completed && (
              <TableImport
                usesSchemas={connector.usesSchemas}
                disabled={pageLoader.loading}
                checkboxColor="var(--light-blue)"
                knowledgeGraph={knowledgeGraph}
                setImportFunction={setImportFunction}
                handleFetchingError={handleFetchingError}
                handleFolderFetchingError={handleFolderFetchingError}
              />
            )}
            {pageLoader.loading && (
              <div className={signupStyles.loaderContainer}>
                <PageLoader message={pageLoader.message} />
              </div>
            )}
          </div>
          <div>
            {!pageLoader.loading && !pageLoader.completed && (
              <>
                {allowBack && (
                  <Button
                    layout={layouts.signup}
                    disabled={pageLoader.loading}
                    onClick={() => setStep(step => step - 1)}
                    data-test="changeDataButton"
                    mode="light"
                    size={isMobile ? 'mobile' : ''}
                    classes={{ root: { marginRight: '20px' } }}
                  >
                    Back
                  </Button>
                )}
                <Button
                  layout={layouts.signup}
                  disabled={pageLoader.loading || importFunction === undefined}
                  onClick={sendTables}
                  data-test="importDataButton"
                  mode="dark"
                  size={isMobile ? 'mobile' : ''}
                >
                  Import Data
                </Button>
              </>
            )}
          </div>
          <MessageModal
            message={modal.message}
            open={modal.open}
            onConfirm={closeModal}
            closeModal={closeModal}
            type={modal.type}
          />
          <ResultDialog uploadResult={uploadResult} handleClose={() => setUploadResult(null)} />
        </div>
      </div>
    </StepperContainer>
  );
};

export default connect()(ImportData);
