import _ from 'lodash';
import { isInverted, normalizedType } from '../config/constants';

export const findVisibleHighChartObject = messageId =>
  Highcharts.charts.find(x => x?.renderTo.id.includes(messageId) && x.renderTo.getBoundingClientRect().width > 0);

/**
 * Merges all Visualization Options applied at various levels
 *
 * @param original visualizationOptions coming with the visualization, currently only metaColumns
 * @param custom visualizationOptions that are stored in the backend e.g. on the widget or shared answer level, includes previous session modifications
 * @param modifications is an array of "modifiedVisualizations" (as in the redux state usually), i.e. modifications in the session
 * @returns {Object}
 */
export const mergeVisualizationOptions = (original, custom, modifications) => {
  try {
    const mergedChartOverride = mergeChartOverrides(original, custom, modifications);

    return {
      // Do not do a deep merge here, only for the chartOverride. Unclear anyways if we want to preserve the custom options
      // coming from widgets or always save the newly generated ones. At this time we only really need to preserve chartOverride.
      ...original,
      ...custom,
      ...(Object.keys(mergedChartOverride).length > 0 && { chartOverride: mergedChartOverride })
    };
  } catch (error) {
    console.error('Error merging visualization options', error);
    return {};
  }
};

/**
 * Merges all chart overrides applied at the various levels
 *
 * @param original visualizationOptions coming with the visualization, currently only metaColumns
 * @param custom visualizationOptions that are stored in the backend e.g. on the widget or shared answer level, includes previous session modifications
 * @param modifications is an array of "modifiedVisualizations" (as in the redux state usually), i.e. modifications in the session
 * @param highchartsOptions chart options retrieved directly from the highcharts object after the chart was created
 * @returns {Object}
 */
export const mergeChartOverrides = (original, custom, modifications, highchartsOptions) => {
  try {
    const originalChartOverride = original?.chartOverride || {};
    const customChartOverride = custom?.chartOverride || {};
    // merge the original options with the custom options
    const mergedCustomOptions = _.merge({}, highchartsOptions, originalChartOverride, customChartOverride);

    // merge the merged options with the modifications from the session
    return modifications.reduce((acc, cur) => {
      // LLM generated modifications get merged with previous ones.
      // If the modification was done via the code dialgoue it was done on the entire options
      // and basically overwriting _all_ the previous options
      const base = cur.mergeWithPrevious ? acc : {};
      return _.merge(base, cur.chartOverride);
    }, mergedCustomOptions);
  } catch (error) {
    console.error('Error merging chart overrides', error);
    return {};
  }
};

/** Merges visualizations with both normal & inverted versions, using the one matching isChartInverted */
export const adaptVisualizations = (visualizations, isChartInverted) => {
  const [invertedVisualizations, baseVisualizations] = _.partition(visualizations, item => isInverted(item.type));

  return baseVisualizations.map(baseVisualization => {
    let adaptedVisualization = baseVisualization;

    invertedVisualizations
      .filter(invertedVisualization => baseVisualization.type === normalizedType(invertedVisualization.type))
      .forEach(invertedVisualization => {
        if (isChartInverted) {
          adaptedVisualization = invertedVisualization;
        }
        adaptedVisualization.allowInvert = true;
      });

    return adaptedVisualization;
  });
};
