import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Logo from 'components/logo/Logo';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ErrorBoundary from 'error-handler/ErrorBoundary';
import { trackEvent } from 'utils/eventTracking';

import styles from './message.scss';
import Timestamp from 'components/time/Timestamp';

import { setFeedback, feedbackStates } from 'store/modules/recentFeedback';

import ThumbUp from 'svg/thumb-up.svg';
import ThumbDown from 'svg/thumb-down.svg';
import RetryIcon from 'svg/retry.svg';

import { withTranslation } from 'react-i18next';
import { showOrHideMessage, fetchRetriedQuestion, fetchAnswerOfInterpretation } from 'store/modules/chat-messages';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import classnames from 'classnames';
import { CustomModal, InfoTooltip } from 'components/v3';

import { layouts } from 'config/constants';

import SaveVqlGroundTruthForm from 'forms/SaveVqlGroundTruthForm';
import SchoolIcon from '@mui/icons-material/School';

const iconButtonSx = {
  '&.MuiIconButton-root': {
    border: 'solid 1px var(--border-color)',
    backgroundColor: 'white',
    padding: '8px',
    fontSize: 22,
    // also set hover style (keep backgroundColor white, not transparent like per default)
    '&:hover': {
      backgroundColor: 'var(--lightest-gray)'
    }
  }
};

export const BaseMessage = ({
  answerId,
  messageId,
  isLlmParserEnabled,
  isSystemZeroEnabled,
  messages,
  alternativeInterpretations,
  partialAnswers,
  interpretationId,
  localQuestionId,
  questionId,
  isSuperUserOrHigher,
  hasWritePermission,
  enableStandardFooter,
  timestamp,
  isFollowUp,
  content,
  customFooter,
  showVeezooIcon,
  baseMessageClasses,
  feedback,
  enableFeedback,
  enableRetry,
  followUpState,
  disableHideMessageButton,
  t,
  dispatch
}) => {
  const hideMessageAreaRef = React.useRef(null);
  const isFeedbackPositive = feedback === feedbackStates.POS;
  const isFeedbackNegative = feedback === feedbackStates.NEG;
  const [displayVqlGroundTruthModal, setDisplayVqlGroundTruthModal] = useState(false);
  // using a ref to keep track of whether the mouse is inside the hideMessageArea should not trigger a re-render
  const [isMouseInsideHideMessageArea, setIsMouseInsideHideMessageArea] = React.useState(false);
  const isSelectedFollowUp =
    followUpState && (messageId === followUpState.messageId && followUpState.interpretationId === interpretationId);

  const handleFeedbackClick = isPositive =>
    dispatch(setFeedback(answerId, messageId, questionId, localQuestionId, isPositive, isFollowUp, feedback));

  const baseMessageStyle = isSelectedFollowUp ? styles.followUpBaseMessage : styles.baseMessage;

  const handleMinimizeClick = useCallback(() => {
    if (localQuestionId) {
      dispatch(showOrHideMessage(true, localQuestionId));
    }
    dispatch(showOrHideMessage(true, messageId));
  }, [dispatch, localQuestionId, messageId]);

  // only show the retry button if retry is actually enabled, and there is at least one mechanism for retry available
  const showRetryButton =
    !!enableRetry && (isLlmParserEnabled || isSystemZeroEnabled || alternativeInterpretations?.length > 0);

  const handleRetryButtonClick = useCallback(() => {
    trackEvent('Retry Button Clicked', { questionId });
    if (isLlmParserEnabled || isSystemZeroEnabled) {
      // use the retry endpoint to try again if any of the LLM-based features are available
      dispatch(fetchRetriedQuestion(questionId, localQuestionId, t));
    } else if (alternativeInterpretations?.length > 0) {
      dispatch(fetchAnswerOfInterpretation(alternativeInterpretations[0].interpretationId, answerId, ''));
    } else {
      // this shouldn't happen as the retry button is hidden in this case
      console.error('Cannot perform retry');
    }
  }, [dispatch, messageId, localQuestionId, messages]);

  const buttonClasses = classnames(styles.hideMessageButtonContainer, {
    [styles.hideMessageButtonContainerOnHover]: isMouseInsideHideMessageArea
  });

  const currentMessage = messages.find(msg => msg.id === messageId);
  const question = isFollowUp ? '' : currentMessage?.question;
  const partialAnswer = partialAnswers.find(pa => pa.answerId === answerId && pa.interpretationId === interpretationId);
  const vql = partialAnswer?.vql;
  const showTeachButton = vql && isSuperUserOrHigher && hasWritePermission;

  // The hideMessageArea has visibility: hidden. Therefore, we cannot use the onMouseEnter and onMouseLeave events.
  // Simply using :hover in CSS doesn't work either, because we want to interact with the mouse with elements inside the hideMessageArea.
  // Instead, we use the mousemove event and check if the mouse is inside the hideMessageArea.
  // If the mouse enters / leaves the hideMessageArea, we show the button corresponding to the event.
  useEffect(() => {
    const handleMouseMove = e => {
      const hideMessageArea = hideMessageAreaRef.current;
      // we check if the mouse is inside the hideMessageArea by checking if the mouse coordinates are inside the hideMessageArea
      if (hideMessageArea) {
        const boundingRect = hideMessageArea.getBoundingClientRect();
        const isMouseInside =
          e.clientX >= boundingRect.left &&
          e.clientX <= boundingRect.right &&
          e.clientY >= boundingRect.top &&
          e.clientY <= boundingRect.bottom;

        setIsMouseInsideHideMessageArea(isMouseInside);
      }
    };
    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const teachButton = (
    <InfoTooltip text={t('save-new-vql-ground-truth.title')}>
      <div onClick={() => setDisplayVqlGroundTruthModal(true)} className={styles.iconButtonContainer}>
        <SchoolIcon className={clsx(styles.iconButtonIcon)} />
        <span className={styles.iconButtonLabel}>{t('teach')}</span>
      </div>
    </InfoTooltip>
  );

  const retryButton = (
    <InfoTooltip text={t('tooltip.try-answering-again')}>
      <div onClick={handleRetryButtonClick} className={styles.iconButtonContainer} data-test="tryAgain">
        <RetryIcon className={clsx(styles.iconButtonIcon)} />
        <span className={styles.iconButtonLabel}>{t('retry')}</span>
      </div>
    </InfoTooltip>
  );

  return (
    <div className={styles.container}>
      <div className={styles.messageContainer}>
        <div className={`${styles.icon} ${styles.hideOnSmallScreens}`}>
          {showVeezooIcon && <Logo displayShortVersion alt="Veezoo logo" />}
        </div>
        <div id="VeezooMessageHook" className={clsx(baseMessageStyle, baseMessageClasses)}>
          <ErrorBoundary>
            {messageId && !disableHideMessageButton && (
              <div className={styles.hideMessageArea} ref={hideMessageAreaRef}>
                <div className={buttonClasses}>
                  <InfoTooltip text={t('hide.tooltip')}>
                    <IconButton
                      sx={iconButtonSx}
                      onClick={handleMinimizeClick}
                      size="medium"
                      aria-label={t('hide.tooltip')}
                    >
                      <VisibilityOff />
                    </IconButton>
                  </InfoTooltip>
                </div>
              </div>
            )}
            {content}
            {customFooter}
          </ErrorBoundary>
        </div>
        <div className={styles.placeholder} />
      </div>
      {enableStandardFooter && (
        <aside className={styles.footer}>
          <Timestamp time={timestamp} />
          {isFollowUp && (
            <>
              <span className={styles.separator}>•</span>
              FollowUp
            </>
          )}
          {!!enableFeedback && (
            <>
              <span className={styles.separator}>•</span>
              <InfoTooltip text={t('tooltip.give-positive-feedback')}>
                <div className={styles.iconButtonContainer}>
                  <ThumbUp
                    className={clsx(
                      styles.iconButtonIcon,
                      styles.iconThumbsUp,
                      isFeedbackPositive ? styles.iconPositive : ''
                    )}
                    onClick={() => handleFeedbackClick(true)}
                  />
                </div>
              </InfoTooltip>
              <InfoTooltip text={t('tooltip.give-negative-feedback')}>
                <div className={styles.iconButtonContainer}>
                  <ThumbDown
                    className={clsx(
                      styles.iconButtonIcon,
                      styles.iconThumbsDown,
                      isFeedbackNegative ? styles.iconNegative : ''
                    )}
                    onClick={() => handleFeedbackClick(false)}
                  />
                </div>
              </InfoTooltip>
            </>
          )}
          {(showRetryButton || showTeachButton) && <span className={styles.separator}>•</span>}
          {showRetryButton && retryButton}
          {showTeachButton && teachButton}
          <CustomModal
            layout={layouts.veezoo}
            title={t('save-new-vql-ground-truth.title')}
            open={displayVqlGroundTruthModal}
            content={
              <SaveVqlGroundTruthForm
                question={question}
                vql={vql}
                onClose={() => setDisplayVqlGroundTruthModal(false)}
                t={t}
              />
            }
            onClose={() => setDisplayVqlGroundTruthModal(false)}
            hideButtons
          />
        </aside>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  followUpState: state.followUpState,
  messages: state.chatMessages,
  partialAnswers: state.partialAnswers,
  isLlmParserEnabled: state.user.isLlmParserEnabled,
  isSystemZeroEnabled: state.user.isSystemZeroEnabled,
  isSuperUserOrHigher: state.user.isSuperUserOrHigher,
  hasWritePermission: state.knowledgeGraphMeta.meta.hasWritePermission
});

export default withTranslation('veezoo', { withRef: true })(connect(mapStateToProps)(memo(BaseMessage)));

BaseMessage.propTypes = {
  answerId: PropTypes.string,
  localQuestionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  messageId: PropTypes.any,
  messages: PropTypes.array,
  partialAnswers: PropTypes.array,
  alternativeInterpretations: PropTypes.array,
  interpretationId: PropTypes.any,
  questionId: PropTypes.any,
  feedback: PropTypes.any,
  isSuperUserOrHigher: PropTypes.bool,
  hasWritePermission: PropTypes.bool,
  enableStandardFooter: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  timestamp: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  isFollowUp: PropTypes.bool,
  followUpState: PropTypes.object,
  customFooter: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  showVeezooIcon: PropTypes.bool,
  disableHideMessageButton: PropTypes.bool,
  enableFeedback: PropTypes.bool,
  enableRetry: PropTypes.bool,
  baseMessageClasses: PropTypes.array,
  isLlmParserEnabled: PropTypes.bool,
  isSystemZeroEnabled: PropTypes.bool
};

BaseMessage.defaultProps = {
  enableStandardFooter: true,
  showVeezooIcon: true,
  enableFeedback: false,
  enableRetry: false,
  feedback: feedbackStates.NONE
};
