import React, { Component, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { boundMethod } from 'autobind-decorator';
import PropTypes from 'prop-types';

import styles from './search-area.scss';
import withEmbedded from 'root/hocs/withEmbedded';
import Button from 'components/v2/Button';
import { changeUrlGivenCurrentQueryParams } from 'components/LinkWithQuery';
import CloseIcon from '@material-ui/icons/CloseRounded';

const propTypes = {
  t: PropTypes.func,
  label: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  shouldFocusOnInput: PropTypes.bool,
  shouldShowCloseButton: PropTypes.bool
};

class SearchArea extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shouldFocusOnInput) {
      this.refs['searchInput_' + this.props.label].focus();
    }
    if (nextProps.query !== this.props.query) {
      this.refs['searchInput_' + this.props.label].value = nextProps.query;
    }
    this.forceUpdate();
  }
  // Show query in input even if the KG was never opened before and someone searched over sidebar
  componentDidMount() {
    if (this.props.query) {
      this.refs['searchInput_' + this.props.label].value = this.props.query;
    }
  }

  @boundMethod
  onChange() {
    let searchQuery = this.refs['searchInput_' + this.props.label].value || '';
    this.props.onChange(searchQuery);
  }

  @boundMethod
  onKeyDown($event) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown($event);
    }
  }

  render() {
    const { t, label, onFocus, shouldShowCloseButton, history } = this.props;
    const closeButton = (
      <Button
        label={t('close')}
        onClick={() => {
          changeUrlGivenCurrentQueryParams(history, {
            visualMode: null
          });
        }}
        icon={<CloseIcon />}
      />
    );

    return (
      <div id="searchBlock" className={styles.searchArea} data-knowledgegraph-tutorial="step-3">
        <div className={styles.searchIcon} />
        <input
          type="text"
          autoComplete="off"
          id="SearchAreaInputHook"
          onChange={this.onChange}
          onFocus={() => onFocus()}
          onKeyDown={this.onKeyDown}
          ref={'searchInput_' + label}
          className={styles.searchAreaInput}
          placeholder={t('search')}
        />
        {shouldShowCloseButton && <span style={{ marginLeft: '20px' }}>{closeButton}</span>}
      </div>
    );
  }
}

SearchArea.propTypes = propTypes;

export default withTranslation('veezoo')(withEmbedded(SearchArea));
