import { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';

import ArrowLeft from 'svg/v2/arrow_left.svg';
import ArrowRight from 'svg/v2/arrow_right.svg';

import { QuarterPicker } from 'components/v3';

import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';

import useStyles, { getDatePickerTheme, useCalendarDayStyles } from './styles';

import { dateTypes } from 'config/constants';

import {
  formatDateToWeekString,
  isValidDate,
  isValidWeek,
  isValidYear,
  startOfWeekInVeezoo,
  endOfWeekInVeezoo,
  getWeekOfTheYear,
  getDateFromYearAndWeek
} from 'utils/datetimeUtils';

const dayMonthYearFormat = { format: 'dd/MM/yyyy', mask: '__/__/____', placeholder: '31/12/2020' };
const monthYearFormat = { format: 'MM/yyyy', mask: '__/____', placeholder: '12/2020' };
const yearFormat = { format: 'yyyy', mask: '____', placeholder: '2020' };

const weekFormat = {
  // we don't use "format" for week, because the "KeyboardDatePicker" component doesn't have
  // a default formatting for it. In this case, we built our custom formatting defined in the "formatWeekSelectLabel" function.
  mask: '____-W__',
  placeholder: '2020-W20'
};

const DatepickerComponent = ({ value, onChange, fullWidth, views, title }) => {
  // If it is week format, the component should have a custom format.
  const isWeek = useMemo(() => views.some(view => view === dateTypes.week), [views]);

  const styles = useStyles();
  const calendarDayClasses = useCalendarDayStyles({ isWeek });

  const formatView = useMemo(() => {
    if (!views || views.length < 1) return dayMonthYearFormat;

    const hasDay = views.some(view => view === dateTypes.date);
    const hasMonth = views.some(view => view === dateTypes.month);
    const hasYear = views.some(view => view === dateTypes.year);

    if (isWeek) return weekFormat;
    if (hasDay) return dayMonthYearFormat;
    if (hasMonth) return monthYearFormat;
    if (hasYear) return yearFormat;

    return dayMonthYearFormat;
  }, [views, isWeek]);

  const theme = useMemo(() => getDatePickerTheme({ fullWidth, isCustom: isWeek }), [fullWidth, isWeek]);

  const handleChange = (date, value) => {
    if (isWeek) {
      // If it is a valid date, return "date";
      if (isValidDate(date)) {
        return onChange(startOfWeekInVeezoo(date));
      }
      // If date is not valid and we also don't have a value, we return the date
      // which will be sent as "Invalid Date" and trigger field validation in DatetimeFilter;
      if (!value) {
        return onChange(date);
      }
      // If date is not valid but we have a value, we validate year and week
      // individually and return them if both are valid.
      const splittedValue = value.split('-W').map(item => item.replace(/_/g, ''));
      const year = parseInt(splittedValue[0]);
      if (isValidYear(year)) {
        const week = splittedValue[1];
        if (isValidWeek(week)) {
          const resultDate = getDateFromYearAndWeek(year, parseInt(week));
          return onChange(resultDate);
        }
      }
    }
    return onChange(date);
  };

  const formatWeekSelectLabel = (date, invalidLabel) => {
    if (!isWeek) return false;

    // In week validation, if we clear the input, we should return an empty string. "null" or "undefined" would break it.
    if (!date) return '';

    if (!isValid(date)) return invalidLabel;

    const weekOfTheYear = getWeekOfTheYear(date);
    return formatDateToWeekString(weekOfTheYear.year, weekOfTheYear.week);
  };

  const hasError = useMemo(() => value && !isValidDate(value), [value]);

  const renderDay = (date, selectedDate, dayInCurrentMonth) => {
    let wrapperClassName, dayClassName;

    // Is first day of week in all weeks, not only on selected;
    const isFirstDayOfWeek = isSameDay(date, startOfWeekInVeezoo(date));
    const weekOfTheYear = getWeekOfTheYear(date);

    if (isWeek) {
      // Week filter highlights the whole week in the calendar, so we have to get
      // first and last day of the week.
      const start = startOfWeekInVeezoo(selectedDate);
      const end = endOfWeekInVeezoo(selectedDate);

      const dayIsBetween = isWithinInterval(date, { start, end });
      const isFirstDay = isSameDay(date, start);
      const isLastDay = isSameDay(date, end);

      wrapperClassName = clsx(calendarDayClasses.wrapperContainer, {
        [calendarDayClasses.highlight]: dayIsBetween,
        [calendarDayClasses.firstHighlight]: isFirstDay,
        [calendarDayClasses.endHighlight]: isLastDay
      });

      dayClassName = clsx(calendarDayClasses.day, {
        [calendarDayClasses.daySelected]: dayInCurrentMonth && (dayIsBetween || isFirstDay || isLastDay),
        [calendarDayClasses.nonCurrentMonthDay]: !dayInCurrentMonth,
        [calendarDayClasses.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
      });
    } else {
      const isSelectedDate = isSameDay(date, selectedDate);

      wrapperClassName = clsx({
        [calendarDayClasses.highlight]: isSelectedDate,
        [calendarDayClasses.uniqueDayHighlight]: isSelectedDate
      });

      dayClassName = clsx(calendarDayClasses.day, {
        [calendarDayClasses.daySelected]: dayInCurrentMonth && isSelectedDate,
        [calendarDayClasses.nonCurrentMonthDay]: !dayInCurrentMonth,
        [calendarDayClasses.highlightNonCurrentMonthDay]: !dayInCurrentMonth && isSelectedDate
      });
    }

    return (
      <div className={wrapperClassName}>
        {isWeek && isFirstDayOfWeek && <div className={calendarDayClasses.weekLabel}>{weekOfTheYear.week}</div>}
        <IconButton className={dayClassName}>
          <span> {format(date, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <KeyboardDatePicker
        autoOk
        error={hasError}
        inputVariant="outlined"
        variant="inline"
        orientation="landscape"
        value={value}
        onChange={handleChange}
        leftArrowIcon={<ArrowLeft className={styles.arrow} />}
        rightArrowIcon={<ArrowRight className={styles.arrow} />}
        KeyboardButtonProps={{ 'aria-label': 'change date' }}
        data-test="keyboard-date-picker"
        renderDay={renderDay}
        mask={formatView.mask}
        inputProps={{ title }}
        placeholder={formatView.placeholder}
        {...(isWeek || !views || views.length < 1 ? {} : { views })}
        {...(isWeek ? { labelFunc: formatWeekSelectLabel } : { format: formatView.format })}
      />
    </ThemeProvider>
  );
};

const Datepicker = props => {
  const isQuarter = useMemo(() => props.views.some(view => view === dateTypes.quarter), [props.views]);

  return isQuarter ? <QuarterPicker {...props} /> : <DatepickerComponent {...props} />;
};

export default Datepicker;

Datepicker.propTypes = {
  value: PropTypes.any,
  views: PropTypes.array,
  onChange: PropTypes.func, // Pay attention that the "onChange" returns two parameters (date, value) that ARE BEING USED outside of this component!!!!
  fullWidth: PropTypes.bool,
  t: PropTypes.func,
  title: PropTypes.string
};

Datepicker.defaultProps = { format: 'dd/MM/yyyy', views: [], title: 'Date field' };
