import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { InfoTooltip } from 'components/v3';

import styles from 'components/message/answer-message-footer.scss';
import Button from 'components/buttons/Button';
import PropTypes from 'prop-types';

const AcceptOrRejectSharingMessageFooter = ({
  id,
  alreadyRejected,
  acceptTooltipText,
  rejectTooltipText,
  acceptOrRejectSharing,
  dispatch,
  t
}) => {
  const acceptOrReject = (id, isAccepted) => dispatch(acceptOrRejectSharing(id, isAccepted));

  return (
    <div className={styles.buttonsContainer}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <InfoTooltip text={acceptTooltipText}>
            <Button
              data-test="acceptShareButton"
              text={t('sharing.accept')}
              className={`${'button-secondary'} ${
                alreadyRejected ? styles.buttonAcceptAlreadyReject : styles.buttonAccept
              }`}
              onClick={() => acceptOrReject(id, true)}
            >
              <span className="icon-accept" />
            </Button>
          </InfoTooltip>
        </li>
        <li className={styles.item}>
          <InfoTooltip text={rejectTooltipText}>
            <Button
              data-test="rejectShareButton"
              text={t('sharing.reject')}
              className={`${'button-secondary'} ${alreadyRejected ? styles.buttonAlreadyReject : styles.buttonReject}`}
              onClick={() => acceptOrReject(id, false)}
            >
              <span className="icon-reject" />
            </Button>
          </InfoTooltip>
        </li>
      </ul>
    </div>
  );
};

export default withTranslation('veezoo')(connect(null)(AcceptOrRejectSharingMessageFooter));

AcceptOrRejectSharingMessageFooter.propTypes = {
  id: PropTypes.string, // the id of the shareable object, e.g. boardId or customerSelectionId
  acceptOrRejectSharing: PropTypes.func, // the API call to accept/reject a shareable object
  alreadyRejected: PropTypes.bool, // whether the shareable object was already rejected (so we can show the button highlighted)
  acceptTooltipText: PropTypes.string,
  rejectTooltipText: PropTypes.string
};
