import React, { useState, Fragment, useMemo, useEffect, useCallback, memo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';

import { SidebarLoader } from './SkeletonLoaders';

import { InfoTooltip } from 'components/v3';
import Drawer from 'components/Drawer';
import Button from 'components/v2/Button';
import { veezooRoutes } from 'components/app/routes';

import flatMap from 'array.prototype.flatmap';

import SidebarListItem from './SidebarListItem';
import Collapse from '@material-ui/core/Collapse';
import Input from 'components/v2/Input';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import clsx from 'clsx';
import styles from 'components/KnowledgeGraphSidebar/styles.scss';
import { KnowledgeGraphIcon } from 'components/Icons';
import { LinkWithQuery } from 'components/LinkWithQuery';

import { getSidebarNodes } from 'store/modules/graph/graph';

import { trackEvent } from 'utils/eventTracking';
import { initialSidebarSizeState, LocalStorageSidebarSizeKey } from 'components/split/SplitSidebarView';
import useLocalStorageState from 'use-local-storage-state';
import { SIDEBAR_EXPANDED_SIZE } from 'config/constants';

export const KnowledgeGraphSidebarDrawerWidth = SIDEBAR_EXPANDED_SIZE - 1;

const useSidebarStyles = makeStyles({
  title: {
    fontSize: '16px',
    fontWeight: 300,
    fontFamily: 'Lexend',
    color: 'white',
    display: 'flex',
    alignItems: 'end',
    '& > svg': {
      width: 21,
      height: 27,
      marginRight: 8
    }
  },
  paperClasses: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'var(--primary-color)',
    width: props => props.width,
    borderRadius: 0,
    height: props => `calc(100% - ${props.deltaHeightOfSidebar}px)`,
    top: props => (props.isEmbedded ? 'unset' : props.heightOfLogo),
    position: props => (props.isEmbedded ? 'relative' : 'fixed'),
    zIndex: 1
  },
  toggle: {
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: props => (props.showVisualGraph ? 'var(--secondary-color)' : 'white'),
    padding: '5px'
  },
  header: {},
  headerPadding: {
    paddingLeft: 0,
    paddingRight: '14px'
  },
  scrollableArea: {
    flex: 1,
    overflow: 'auto',
    // we need to override the webkit scrollbar to use a light color as we have a dark background
    '&::-webkit-scrollbar': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },
    maxHeight: props => (props.isEmbedded ? 'calc(90vh - 125px)' : 'default') // same as in graphInAutoComplete class
  },
  footer: {
    height: '64px' // this makes it perfectly aligned with the input box
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  }
});

// Remove or Add uri to the expandedNodes depending on if it already existed
const toggleSidebarNode = (expandedNodes, uri) => {
  if (expandedNodes.includes(uri)) {
    return expandedNodes.filter(n => n !== uri);
  } else {
    return [...expandedNodes, uri];
  }
};

// Iterate over matching classes (resultCountsPerClass) from graph search results,
// find matching class and returns how many entities it contains.
const numberOfEntityMatches = (graphSearchResults, uri) => {
  return graphSearchResults.entities?.resultCountsPerClass?.find(c => c.uri === uri)?.resultCount || 0;
};

/**
 * Nodes are matched if:
 *  1) They were matched themselves
 *  2) There is an entity matched of this class and this node is not just a reference to a parent node
 */
const isNodeMatched = (graphSearchResults, uri, isRefNode, subClassNodes) => {
  return (
    graphSearchResults.literals?.some(literal => literal.uri === uri) ||
    graphSearchResults.measures?.some(measure => measure.uri === uri) ||
    graphSearchResults.classes?.some(c => c.uri === uri) ||
    subClassNodes?.some(node => node.subClassOf === uri) ||
    // we only want to match entities for the original node that contains these entities, not everywhere there is a ref node
    (numberOfEntityMatches(graphSearchResults, uri) > 0 && !isRefNode)
  );
};

const searchSidebarNodes = (graphSearchResults, sidebarNodes) => {
  // Refs are children nodes that are just references to another parent node, i.e. a relationship to a class (that is already a parent node).
  // E.g. Orders can have Customers as a child node, but if there is a separate parent node Customers, then Orders.Customers is a ref node.
  // Returns: an array containing all children that are refNodes;
  const refNodes = sidebarNodes.flatMap(node =>
    node.children.filter(child => sidebarNodes.some(node => node.uri === child.uri))
  );

  const subClassNodes = sidebarNodes.flatMap(node =>
    node.children.filter(child => sidebarNodes.some(node => node.uri === child.subClassOf))
  );

  return sidebarNodes
    .map(node => {
      const matchedChildren = node.children.filter(child => {
        const childIsRefNode = refNodes.some(ref => ref.uri === child.uri);
        const isChildMatched = isNodeMatched(graphSearchResults, child.uri, childIsRefNode, subClassNodes);
        if (isChildMatched) return isChildMatched;
      });
      if (matchedChildren.length > 0 || isNodeMatched(graphSearchResults, node.uri, false)) {
        return { ...node, children: matchedChildren };
      } else {
        return null;
      }
    })
    .filter(n => n !== null);
};

const SidebarList = memo(
  ({
    sidebarNodeLayouts,
    nodes,
    graphSearchResults,
    openNode,
    unsetHash,
    openChildNode,
    isEmbedded,
    heightOfLogo,
    drawerWidth,
    showVisualGraph,
    showData,
    t
  }) => {
    const classes = useSidebarStyles({ heightOfLogo, width: drawerWidth, showVisualGraph });

    const [didInitializeExpandedNodes, setDidInitializedExpandedNodes] = useState(false);
    const [expandedNodes, setExpandedNodes] = useState([]);

    const hasSearchResults = !!graphSearchResults.isFetching;
    const searchTerm = graphSearchResults.query || '';

    const handleSidebarListItemClick = useCallback(uri => showData(uri), [showData]);

    const onExpandClick = uri => setExpandedNodes(prev => toggleSidebarNode(prev, uri));
    const onShowDataClick = uri => handleSidebarListItemClick(uri);

    const renderSidebarNode = (node, children, subClassChildren, level, index) => {
      // We are expanded if the user actively expanded or whenever we're searching
      const isExpanded = expandedNodes.includes(node.uri) || hasSearchResults;

      return (
        <Fragment key={`node_${index}_${node.uri}`}>
          <SidebarListItem
            uniqueId={`parent_${index}_${node.uri}`}
            sidebarNode={node}
            onExpandClick={() => onExpandClick(node.uri)}
            onShowDataClick={() => onShowDataClick(node.uri)}
            isExpanded={isExpanded}
            level={level}
            isExpandable={level < 1}
            isEmbedded={isEmbedded}
            searchTerm={searchTerm}
            numberOfEntityMatches={numberOfEntityMatches(graphSearchResults, node.uri)}
            forceOpen={node.uri === openNode}
            // unset hash when popover opens, except for the one we force to be open
            onOpened={node.uri === openNode ? null : unsetHash}
            // unset hash when popover closes
            onClosed={unsetHash}
          />
          <Collapse in={isExpanded} timeout="auto">
            {children?.map((child, childIndex) => (
              <SidebarListItem
                uniqueId={`child_${index}_${childIndex}_${node.uri}`}
                key={`child_${childIndex}_${node.uri}`}
                sidebarNode={child}
                level={level + 1}
                isExpandable={false}
                onExpandClick={() => onExpandClick(node.uri)}
                onShowDataClick={() => onShowDataClick(node.uri)}
                isEmbedded={isEmbedded}
                searchTerm={searchTerm}
                numberOfEntityMatches={numberOfEntityMatches(graphSearchResults, child.uri)}
                forceOpen={child.uri === openChildNode}
                // unset hash when popover opens, except for the one we force to be open
                onOpened={child.uri === openChildNode ? null : unsetHash}
                // unset hash when popover closes
                onClosed={unsetHash}
              />
            ))}
            {/* for now there we only support one level of subClasses which might change in the future */}
            {subClassChildren?.map((subClassNode, subClassNodeIndex) =>
              renderSidebarNode(subClassNode, subClassNode.children, null, level + 1, subClassNodeIndex)
            )}
          </Collapse>
        </Fragment>
      );
    };

    // This is the sidebar node tree structure that we want to render, without filters applied;
    const sidebarNodes = getSidebarNodes(nodes, sidebarNodeLayouts);

    const nodesFilteredBySearch = hasSearchResults
      ? searchSidebarNodes(graphSearchResults, sidebarNodes)
      : sidebarNodes;

    let subClassNodes = [];
    let nonSubClassNodes = [];

    // separate subClass nodes from nodes without subclasses
    nodesFilteredBySearch.forEach(node => {
      if (node.subClassOf) {
        subClassNodes.push(node);
      } else {
        nonSubClassNodes.push(node);
      }
    });

    // Design decision to always expand the first two parents on initial load (if e.g. only one exists, doesn't matter)
    useEffect(() => {
      if (!didInitializeExpandedNodes && nonSubClassNodes.length > 0) {
        setDidInitializedExpandedNodes(true);
        setExpandedNodes(nonSubClassNodes.slice(0, 2).map(node => node.uri));
      }
    }, [nonSubClassNodes, didInitializeExpandedNodes]);

    return (
      <List dense={true} disablePadding={true}>
        {nonSubClassNodes.map((node, index) => {
          let childrenWithoutSubClasses = [];
          let subClassChildren = [];

          // Separate subClass children from children without subclasses
          node.children.forEach(child => {
            if (subClassNodes.some(subClassNode => subClassNode.uri === child.uri)) {
              subClassChildren.push(child);
            } else {
              childrenWithoutSubClasses.push(child);
            }
          });

          return (
            <Fragment key={`node_${index}_${node.uri}`}>
              {renderSidebarNode(node, childrenWithoutSubClasses, subClassChildren, 0, index)}
              <Divider classes={{ root: classes.divider }} />
            </Fragment>
          );
        })}
        {nodesFilteredBySearch.length === 0 && (
          <ListItem className={clsx(styles.kgSidebarItem, styles.kgSidebarParentItem)}>
            <div className={styles.kgSidebarParentItemText}>
              <span className={styles.ellipsisSpan}>{t('no-results')}</span>
            </div>
          </ListItem>
        )}
      </List>
    );
  }
);

const KnowledgeGraphSidebar = ({
  knowledgeGraphId,
  sidebarNodeLayouts,
  nodes,
  graphSearchResults,
  open,
  onClose,
  onVisualGraphToggle,
  anchor,
  loading,
  showData,
  t,
  isEmbedded,
  showVisualGraph,
  handleSearch
}) => {
  const location = useLocation();
  const history = useHistory();
  // get drawer width from local storage or use the default value (also monitor local storage changes)
  const [sidebarSize] = useLocalStorageState(LocalStorageSidebarSizeKey, {
    defaultValue: initialSidebarSizeState(),
    storageSync: false
  });
  const showVisualKgToggleLabel = showVisualGraph ? t('sidebar.hide-visual-kg') : t('sidebar.show-visual-kg');
  // The Header with the logo can be a custom height, usually it's 227 (used as fallback shouldn't be needed anyhow)
  // If we are in embedded mode, we don't want to show the logo, so we set the height to 0
  const heightOfLogo = isEmbedded ? 0 : document.getElementById('sidebar-header')?.scrollHeight || 227;
  // Full sidebar is calc(100% - deltaHeightOfSidebar) high
  let deltaHeightOfSidebar = 0;
  if (!isEmbedded) {
    deltaHeightOfSidebar = heightOfLogo;
  }

  const classes = useSidebarStyles({
    heightOfLogo,
    isEmbedded,
    deltaHeightOfSidebar,
    width: isEmbedded ? KnowledgeGraphSidebarDrawerWidth : sidebarSize.width - 1, // -1 is to avoid 1px overlap of the sidebar
    showVisualGraph
  });

  // specifies whether the sidebar has fully entered (i.e its slide-in transition ended)
  const [sidebarEntered, setSidebarEntered] = useState(false);
  // an array containing the open node and child node (if any)
  const [openNodes, setOpenNodes] = useState([null, null]);
  // the open node (if any)
  const openNode = useMemo(() => openNodes[0], [openNodes]);
  // the open child node (if any)
  const openChildNode = useMemo(() => openNodes[1], [openNodes]);
  // the search input in the sidebar, we want control over it in order to update it if someone uses e.g. visual search
  const searchInput = useRef(null);

  // sets the open node based on the hash
  useEffect(() => {
    // we need to wait for the sidebar to have entered to avoid a misplaced popover
    if (sidebarEntered) {
      // the (shortcut) URI of a resource from the hash (if any)
      const uri = location.hash ? location.hash.substring(1) : null;
      // the node corresponding to the URI (if any)
      const nodeUri = sidebarNodeLayouts?.find(node => node.uri === uri)?.uri;
      // the child node corresponding to the URI (if any)
      const childNodeUri =
        sidebarNodeLayouts &&
        flatMap(sidebarNodeLayouts, node => node.children)?.find(childNode => childNode.uri === uri)?.uri;
      // update the state: only set child node if no node found
      setOpenNodes([nodeUri, nodeUri ? null : childNodeUri]);
    }
  }, [sidebarEntered, location.hash, sidebarNodeLayouts, nodes]);

  useEffect(() => {
    if (open && knowledgeGraphId) {
      trackEvent('KG Sidebar Opened', { knowledgeGraphId });
    }
  }, [open, knowledgeGraphId]);

  // unsets the hash if one is set
  const unsetHash = useCallback(() => {
    if (location.hash) {
      history.replace({ search: location.search, hash: null });
    }
  }, [location.hash, history]);

  // Update the search value when there's a query e.g. via visual mode
  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.value = graphSearchResults.query || '';
    }
  }, [graphSearchResults.query, searchInput]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      transitionDuration={50}
      anchor={anchor}
      paperClasses={classes.paperClasses}
      data-test="KnowledgeGraphSidebarHook"
      SlideProps={{
        // called when the slide-in transition ends
        onEntered: _ => setSidebarEntered(true),
        // called when the slide-out transition starts
        onExit: _ => setSidebarEntered(false)
      }}
    >
      {!isEmbedded && (
        <div className={classes.header}>
          <ListItem classes={{ root: classes.headerPadding }}>
            <Button
              label="Knowledge Graph"
              dark
              width={194}
              onClick={onClose}
              icon={<ArrowBack />}
              component={LinkWithQuery}
              // remove kgSidebar from the query params
              to={veezooRoutes.chat}
              updateSearchParams={{ kgSidebar: null, visualMode: null }}
              data-knowledgegraph-tutorial="get-back"
              data-test="KnowledgeGraphSidebarClose"
              data-addwidget-tutorial={open ? 'knowledge-graph-sidebar-open' : undefined}
            />
            <InfoTooltip text={showVisualKgToggleLabel} placement="right">
              <IconButton
                onClick={onVisualGraphToggle}
                classes={{ root: classes.toggle }}
                data-knowledgegraph-tutorial="show-visual-button"
                data-test="KnowledgeGraphSidebarVisualKnowledgeGraphButton"
                aria-label={showVisualKgToggleLabel}
              >
                <KnowledgeGraphIcon />
              </IconButton>
            </InfoTooltip>
          </ListItem>
          <Divider classes={{ root: classes.divider }} />
        </div>
      )}
      {!isEmbedded && (
        <>
          <div>
            <Input
              ref={searchInput}
              layout="knowledge_graph_search"
              startIcon={<SearchRoundedIcon />}
              onChange={handleSearch}
              placeholder={`${t('search')}...`}
              title={`${t('search')}...`}
            />
          </div>
          <Divider classes={{ root: classes.divider }} />
        </>
      )}
      <div className={classes.scrollableArea}>
        {loading ? (
          <SidebarLoader />
        ) : (
          <SidebarList
            sidebarNodeLayouts={sidebarNodeLayouts}
            nodes={nodes}
            graphSearchResults={graphSearchResults}
            isEmbedded={isEmbedded}
            openNode={openNode}
            unsetHash={unsetHash}
            openChildNode={openChildNode}
            heightOfLogo={heightOfLogo}
            drawerWidth={isEmbedded ? KnowledgeGraphSidebarDrawerWidth : sidebarSize.width - 1} // -1 is to avoid 1px overlap of the sidebar
            showVisualGraph={showVisualGraph}
            showData={showData}
            t={t}
          />
        )}
      </div>
      {!isEmbedded && (
        <div className={classes.footer}>
          <ListItem>
            <Button
              label={showVisualKgToggleLabel}
              secondary
              dark
              width={194}
              onClick={onVisualGraphToggle}
              icon={<KnowledgeGraphIcon />}
            />
          </ListItem>
        </div>
      )}
    </Drawer>
  );
};

export default memo(KnowledgeGraphSidebar);

KnowledgeGraphSidebar.defaultProps = {
  anchor: 'left'
};
