import { authFetchGet, authFetchPost } from 'store/utils/authFetch';
import { fetchPost, fetchPatch } from 'services/http';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const LANGUAGES_REQUEST = 'LANGUAGES_REQUEST';
export const LANGUAGES_SUCCESS = 'LANGUAGES_SUCCESS';
export const LANGUAGES_FAILURE = 'LANGUAGES_FAILURE';

export const LANGUAGE_REQUEST = 'LANGUAGE_REQUEST';
export const LANGUAGE_SUCCESS = 'LANGUAGE_SUCCESS';
export const LANGUAGE_FAILURE = 'LANGUAGE_FAILURE';

export const CHANGE_ENTRY_POINT_REQUEST = 'CHANGE_ENTRY_POINT_REQUEST';
export const CHANGE_ENTRY_POINT_SUCCESS = 'CHANGE_ENTRY_POINT_SUCCESS';
export const CHANGE_ENTRY_POINT_FAILURE = 'CHANGE_ENTRY_POINT_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_CLEAR = 'CHANGE_PASSWORD_CLEAR';

export const RESET_PASSWORD_MAIL_REQUEST = 'RESET_PASSWORD_MAIL_REQUEST';
export const RESET_PASSWORD_MAIL_SUCCESS = 'RESET_PASSWORD_MAIL_SUCCESS';
export const RESET_PASSWORD_MAIL_FAILURE = 'RESET_PASSWORD_MAIL_FAILURE';

export const SET_SIGNUP_FINISHED = 'SET_SIGNUP_FINISHED';

export const fetchUserInformation = () => {
  return dispatch => {
    dispatch({ type: USER_REQUEST });

    authFetchGet('/user', dispatch)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch({
            type: USER_FAILURE
          });
          throw Error(response.statusText);
        }
      })
      .then(json => {
        if (json) {
          dispatch({
            type: USER_SUCCESS,
            data: json
          });
        }
      })
      .catch(error => console.log('ERROR', error));
  };
};

export const fetchLanguages = () => {
  return dispatch => {
    dispatch({ type: LANGUAGES_REQUEST });

    authFetchGet('/languages', dispatch)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch({
            type: LANGUAGES_FAILURE
          });
          throw Error(response.statusText);
        }
      })
      .then(json => {
        if (json) {
          dispatch({
            type: LANGUAGES_SUCCESS,
            data: json
          });
        }
      })
      .catch(error => console.log('ERROR', error));
  };
};

export function clearPasswordChangeState() {
  return {
    type: CHANGE_PASSWORD_CLEAR
  };
}

export function changePassword(oldPassword, newPassword) {
  return dispatch => {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });

    fetchPatch('/user/password', { oldPassword: oldPassword, newPassword: newPassword }).then(response => {
      if (response.ok) {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS
        });
      } else if (response.status === 401) {
        dispatch({
          type: CHANGE_PASSWORD_FAILURE
        });
      } else {
        throw Error(response.statusText);
      }
    });
  };
}

export const changeEntryPoint = (entryPointType, id) => {
  return dispatch => {
    dispatch({ type: CHANGE_ENTRY_POINT_REQUEST });

    fetchPatch('/user/entrypoint', { entrypoint: entryPointType, id: id }).then(response => {
      if (response.ok) {
        dispatch({
          type: CHANGE_ENTRY_POINT_SUCCESS,
          entryPointType: entryPointType,
          id: id
        });
      } else if (response.status === 401) {
        dispatch({
          type: CHANGE_ENTRY_POINT_FAILURE
        });
      } else {
        throw Error(response.statusText);
      }
    });
  };
};

export const resetPasswordMail = email => {
  return dispatch => {
    // set the isFetchingAuthentication flag so the UI knows it should show a loader.
    dispatch({ type: RESET_PASSWORD_MAIL_REQUEST });

    fetchPost('/user/password/reset', { email: email })
      .then(response => {
        if (response.ok) {
          dispatch({ type: RESET_PASSWORD_MAIL_SUCCESS });
        } else {
          dispatch({ type: RESET_PASSWORD_MAIL_FAILURE });
        }
      })
      .catch(error => console.log('ERROR', error));
  };
};

// set language
// TODO: why it's not in a separate reducer called language?
export const setLanguage = tag => {
  return dispatch => {
    dispatch({
      type: LANGUAGE_REQUEST
    });

    authFetchPost('/language', dispatch, { tag })
      .then(response => {
        if (response.ok) {
          dispatch({
            type: LANGUAGE_SUCCESS,
            data: tag
          });
        } else {
          dispatch({
            type: LANGUAGE_FAILURE
          });
          throw Error(response.statusText);
        }
      })
      .catch(error => console.log('ERROR', error));
  };
};

export const setFinishedSignup = () => {
  return dispatch => dispatch({ type: SET_SIGNUP_FINISHED });
};

// reducer
export const defaultUserState = {
  id: '',
  language: '',
  languages: [],
  loadingLanguage: false,
  username: '',
  email: '',
  passwordChangeTried: false,
  passwordChangeSucceeded: false,
  passwordResetMailTried: false,
  passwordResetMailSucceeded: false,
  hasFetchedUser: false,
  isSingleSignOn: false,
  isSuperUser: false,
  isAdmin: false,
  isOpenDemoUser: false,
  roles: undefined,
  milestones: undefined,
  isLlmParserEnabled: false,
  isSystemZeroEnabled: false,
  isSystemZeroActivated: false
};

export function user(state = defaultUserState, action) {
  switch (action.type) {
    // set username on initial pageload.
    case USER_SUCCESS: {
      return {
        ...state,
        hasFetchedUser: true,
        id: action.data.id,
        username: action.data.username,
        email: action.data.email,
        isSingleSignOn: action.data.isSingleSignOn || defaultUserState.isSingleSignOn,
        isSuperUser: action.data.isSuperUser || defaultUserState.isSuperUser,
        isAdmin: action.data.isAdmin || defaultUserState.isAdmin,
        isSuperUserOrHigher:
          action.data.isSuperUser || action.data.isAdmin || defaultUserState.isSuperUser || defaultUserState.isAdmin,
        isOpenDemoUser: action.data.isOpenDemoUser || defaultUserState.isOpenDemoUser,
        hasUnfinishedSignup: action.data.hasUnfinishedSignup,
        milestones: action.data.milestones,
        preferredNumberFormat: action.data.preferredNumberFormat,
        numberFormat: action.data.numberFormat,
        isLlmParserEnabled: action.data.isLlmParserEnabled,
        isSystemZeroEnabled: action.data.isSystemZeroEnabled,
        isSystemZeroActivated: action.data.isSystemZeroActivated
      };
    }

    case SET_SIGNUP_FINISHED:
      return {
        ...state,
        hasUnfinishedSignup: false
      };

    case LANGUAGES_SUCCESS:
      return {
        ...state,
        language: action.data.find(l => l.selected).tag,
        languages: action.data
      };

    case LANGUAGE_REQUEST: {
      return { ...state, loadingLanguage: true };
    }

    case LANGUAGE_SUCCESS: {
      let languages = state.languages.map(v => {
        v.selected = v.tag === action.data;
        return v;
      });
      return {
        ...state,
        languages,
        language: action.data,
        loadingLanguage: false
      };
    }

    case LANGUAGE_FAILURE: {
      return { ...state, loadingLanguage: false };
    }

    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        passwordChangeTried: true,
        passwordChangeSucceeded: false
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChangeTried: true,
        passwordChangeSucceeded: true
      };

    case CHANGE_PASSWORD_CLEAR:
      return {
        ...state,
        passwordChangeTried: false,
        passwordChangeSucceeded: false
      };

    case RESET_PASSWORD_MAIL_SUCCESS:
      return {
        ...state,
        passwordResetMailTried: true,
        passwordResetMailSucceeded: true
      };

    case RESET_PASSWORD_MAIL_FAILURE:
      return {
        ...state,
        passwordResetMailTried: true,
        passwordResetMailSucceeded: false
      };

    default:
      return state;
  }
}
