import axios from 'axios';
import { baseURL } from 'config/baseURL';
import {
  headersGET,
  headersPUT,
  headersPATCH,
  headersPOST,
  headersDELETE,
  headersFilePOST
} from 'store/utils/HTTPheaders';
import { fetchEventSource as msFetchEventSource } from '@microsoft/fetch-event-source';

import { handleUnauthorized, handleUnauthorizedIndirect } from 'store/utils/authFetch';

// Errors still need to be handled due to AXA redirects our 401 and transform them into 404.
export const handleError = (error, dispatch) => {
  const status = error?.response?.status || null;

  if (status === 401) return handleUnauthorized(dispatch);
  if (status === 404) return handleUnauthorizedIndirect(dispatch);
  return error;
};

const successResponse = response => ({ success: true, ...response });

/**
 * Converts the specified endpoint to a URL as follows:
 *   - prefixes the base URL to the endpoint
 *   - if the result is a root-relative URL (i.e. starting with a slash), then it removes the leading slash to make it relative
 *     (e.g. '/studio/api/users' -> 'studio/api/users')
 *
 * Using relative URLs is needed in order for the context path from the '<base>' tag to be applied for request. The
 * context path is not applied to requests with root-relative URLs.
 *
 * @param endpoint the path of the endpoint, with a leading slash
 */
const toUrl = endpoint =>
  // prefix the base URL
  (baseURL + endpoint)
    // remove leading slash (if any)
    .replace(/^\//, '');

export const get = (endpoint, params = {}) =>
  axios
    .get(toUrl(endpoint), { headers: headersGET, params })
    .then(successResponse)
    .catch(error => error);

// We need the HTTP request to be POST instead of GET, to send a body
export const postWithBlobResponse = (endpoint, body, params = {}) =>
  axios
    .post(toUrl(endpoint), body, {
      headers: headersPOST,
      responseType: 'blob',
      params
    })
    .then(successResponse)
    .catch(error => error);

export const put = (endpoint, body, additionalHeaders = {}) =>
  axios
    .put(toUrl(endpoint), body, { headers: { ...headersPUT, ...additionalHeaders } })
    .then(successResponse)
    .catch(error => error);

export const patch = (endpoint, body, additionalHeaders = {}, params = {}) =>
  axios
    .patch(toUrl(endpoint), body, { headers: { ...headersPATCH, ...additionalHeaders }, params })
    .then(successResponse)
    .catch(error => error);

export const post = (endpoint, body, params = {}) =>
  axios
    .post(toUrl(endpoint), body, { headers: headersPOST, params })
    .then(successResponse)
    .catch(error => error);

export const postFile = (endpoint, body) =>
  axios
    .post(toUrl(endpoint), body, { headers: headersFilePOST })
    .then(successResponse)
    .catch(error => error);

export const del = (endpoint, params = {}) =>
  axios
    .delete(toUrl(endpoint), { headers: headersDELETE, params })
    .then(successResponse)
    .catch(error => error);

export const fetchGet = (endpoint, init) =>
  fetch(toUrl(endpoint), {
    method: 'GET',
    headers: headersGET,
    ...init
  });

export const fetchPost = (endpoint, body, init) =>
  fetch(toUrl(endpoint), {
    method: 'POST',
    headers: headersPOST,
    body: body && JSON.stringify(body),
    ...init
  });

export const fetchPatch = (endpoint, body, init) =>
  fetch(toUrl(endpoint), {
    method: 'PATCH',
    headers: headersPATCH,
    body: body && JSON.stringify(body),
    ...init
  });

export const fetchPut = (endpoint, body, init) =>
  fetch(toUrl(endpoint), {
    method: 'PUT',
    headers: headersPUT,
    body: body && JSON.stringify(body),
    ...init
  });

export const fetchDelete = (endpoint, init) =>
  fetch(toUrl(endpoint), {
    method: 'DELETE',
    headers: headersDELETE,
    ...init
  });

export const fetchEventSource = (endpoint, config) => msFetchEventSource(toUrl(endpoint), config);
