import React, { memo, useRef, useEffect, useMemo, useCallback, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ChatBoard from 'components/board/ChatBoard';
import classNames from 'classnames';

import MultiAnswerPureMessage from './MultiAnswerPureMessage';
import { InfoTooltip } from 'components/v3';
import BoardAnswerMessageFooter from './BoardAnswerMessageFooter';

import useMediaQuery from 'utils/mediaQueries';

import { fetchBoardWidgets, updateBoard } from 'store/modules/board-widgets';
import { startEditBoard, stopEditBoard } from 'store/modules/board';

import generalStyles from '../../styles/global/general.scss';
import answerMessageStyles from './answer-message.scss';
import styles from './message.scss';
import boardStyles from 'components/board/mini-board.scss';
import BoardDropdown from 'components/board/BoardDropdown';
import { boardFileTypes } from 'store/utils/boardFileTypes';
import Button from 'components/v2/Button';
import { Cancel, Edit, Share } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/SaveRounded';

export const extractMetaPartialAnswers = boardWidgets =>
  // Remove the full answers, if we only want metaPartialAnswers
  boardWidgets?.map(({ answer, ...metaPartialAnswer }) => metaPartialAnswer);

const BoardName = memo(({ board, isExpanded, boardId, dispatch, isEditing, editingState, t }) => {
  // Todo: More or less same logic as in ChatBoardWidget, maybe wanna extract it..
  const initialState = useMemo(
    () => ({
      currentBoardName: board?.name,
      isHover: false,
      isTitleEditing: false
    }),
    [board?.name]
  );
  const [state, setState] = useState(initialState);
  const boardNameRef = useRef(null);

  const isMobile = useMediaQuery();

  const onMouseEnter = () => setState(prevState => ({ ...prevState, isHover: true }));
  const onMouseLeave = () => setState(prevState => ({ ...prevState, isHover: false }));
  const onTitleClick = () => setState(prevState => ({ ...prevState, isTitleEditing: isEditing }));

  const updateCurrentTitle = useCallback(() => {
    const newTitle = boardNameRef.current && boardNameRef.current.value;
    const isValidNewTitle = newTitle !== undefined && newTitle !== null && newTitle.trim() !== '';
    if (isValidNewTitle) {
      setState(prevState => ({ ...prevState, isTitleEditing: false }));
      // Only propagate if it actually changed
      newTitle !== board?.name && setState(prevState => ({ ...prevState, currentBoardName: newTitle }));
    }
  }, [boardNameRef, board?.name, setState]);

  // e.g. when the board name gets updated over the "... dropdown"
  useEffect(() => {
    setState(prevState => ({ ...prevState, currentBoardName: board?.name }));
  }, [board?.name]);

  const handleKeyPress = useCallback(
    event => {
      if (event.key === 'Enter') {
        updateCurrentTitle();
      }
    },
    [updateCurrentTitle]
  );

  const iconClassName = useMemo(
    () => (board?.isEditable ? 'icon-board-message' : 'icon-board-lock-message') + ' icon-message-layout',
    [board?.isOwned]
  );

  const boardTitle = useMemo(() => {
    return isEditing && state.isTitleEditing ? (
      <input
        ref={boardNameRef}
        defaultValue={state.currentBoardName}
        onKeyPress={handleKeyPress}
        className={boardStyles.titleInput}
        onBlur={updateCurrentTitle}
        id="BoardWidgetTitleInputHook"
        autoFocus
      />
    ) : (
      <span
        className={classNames(
          answerMessageStyles.messageTitleWithIcon,
          isEditing ? generalStyles.underlineOnHover : undefined
        )}
        onClick={onTitleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {state.currentBoardName}
      </span>
    );
  }, [isEditing, boardNameRef, handleKeyPress, state]);

  const onSaveBoard = useCallback(
    saveAndPublish => {
      // only update the board name if it changed (i.e. passing undefined if unchanged).
      const updatedBoardName = board?.name === state.currentBoardName ? undefined : state.currentBoardName;
      dispatch(updateBoard(boardId, editingState, updatedBoardName, saveAndPublish));
      dispatch(stopEditBoard());
    },
    [boardId, editingState, dispatch, board?.name !== state.currentBoardName]
  );

  const onCancel = useCallback(() => {
    // Roll back potential changes made to board name
    setState(prevState => ({ ...prevState, currentBoardName: board?.name }));
    dispatch(stopEditBoard());
  }, [boardId, editingState, dispatch, state.currentBoardName, board?.name]);

  const editBar = useMemo(
    () => (
      <>
        <Button
          data-test="boardSaveButton"
          label={t('board.save')}
          showOnlyIcon={isMobile}
          onClick={() => onSaveBoard(false)}
          icon={<SaveIcon />}
        />
        {board?.usesPublishingMode && (
          <Button
            data-test="saveAndPublishBoardButton"
            label={t('board.save-and-publish')}
            showOnlyIcon={isMobile}
            onClick={() => onSaveBoard(true)}
            icon={<Share />}
          />
        )}
        <Button label={t('cancel')} showOnlyIcon={isMobile} onClick={onCancel} icon={<Cancel />} />
      </>
    ),
    [onSaveBoard, onCancel, board?.usesPublishingMode, t]
  );

  const followUpIcon = board?.linkedToBoard && (
    <InfoTooltip text={t('dashboard-linked-to', { linkedBoardName: board.linkedToBoard })}>
      <span className="icon-chain" data-test="linkedBoardIcon" />
    </InfoTooltip>
  );

  const standardBoardIcon = <span className={iconClassName} />;

  const isBoardModifiable = board?.isOwned && !isExpanded;

  return board ? (
    <div className={styles.flexChildrenSpaceBetween}>
      <div className={styles.flexGrow}>
        <InfoTooltip text={isExpanded && t('back')}>
          <span
            data-test="boardNameInBoardAnswerMessage"
            data-test-board-id={boardId}
            className={clsx(isExpanded && generalStyles.underlineOnHover, styles.boardTitle)}
          >
            {board?.linkedToBoard && !isExpanded ? followUpIcon : standardBoardIcon}
            {boardTitle}
          </span>
        </InfoTooltip>
      </div>
      <div>
        {!isEditing && isBoardModifiable && (
          <Button
            data-test="boardEditButton"
            label={t('board.edit')}
            showOnlyIcon={isMobile}
            onClick={() => dispatch(startEditBoard(boardId))}
            icon={<Edit />}
          />
        )}
        {isEditing && isBoardModifiable && editBar}
        {!isExpanded && (
          <BoardDropdown
            id={boardId}
            name={board.name}
            type={boardFileTypes.board}
            useHorizontalToggle={true}
            dispatch={dispatch}
            t={t}
            isSharedWithOthers={board.isSharedWithOthers}
            canShare={board.isOwned}
            canDelete={board.isOwned}
            canRename={board.isEditable}
            canRemoveFromSidebar={!board.isOwned && board.isInSidebar}
            canSubscribe={!board.isOwned && !board.isInSidebar}
            canCopy={true}
            canMakeEntrypoint={board.isInSidebar}
          />
        )}
      </div>
    </div>
  ) : (
    '\u00A0'
  );
});

export const MiniBoard = memo(
  ({
    answerId,
    id,
    boardWidgets,
    boardId,
    isEditing,
    isSharedAndUnpublished,
    widgetsToPartialAnswersIdMap,
    previewMode
  }) => {
    return (
      <ChatBoard
        previewMode={previewMode}
        answerId={answerId}
        messageId={id}
        metaPartialAnswers={extractMetaPartialAnswers(boardWidgets)}
        boardId={boardId}
        isEditing={isEditing}
        isSharedAndUnpublished={isSharedAndUnpublished}
        partialAnswers={
          // We need to transform the answers such that they all contain the answerId and the right partialAnswerId
          // from the Board Answer Message, otherwise they would not load in the ChatBoard.
          boardWidgets
            .filter(w => w.answer)
            .map(w => {
              // the answer id changed
              // mutate answer object in order NOT to trigger a re-render of the whole board if only
              const { answer } = w;
              const widgetId = w.id;

              answer['answerId'] = answerId;
              if (widgetsToPartialAnswersIdMap) {
                const widgetMapEntry = widgetsToPartialAnswersIdMap.find(wpa => wpa.widgetId === widgetId);
                if (widgetMapEntry && widgetMapEntry.partialAnswerId) {
                  answer['partialAnswerId'] = widgetMapEntry.partialAnswerId;
                }
              }
              return answer;
            })
        }
      />
    );
  }
);

const NoWidgets = memo(({ t }) => (
  <div className={styles.paddingBelow}>
    <div>{t('empty-dashboard.1')}</div>
    <div>
      {t('empty-dashboard.2')} <span className="icon-add-dashboard" /> {t('empty-dashboard.3')}
    </div>
    <div>{t('empty-dashboard.4')}</div>
  </div>
));

const LoadingMetaWidgets = memo(({ t }) => (
  <div className={styles.paddingBelow}>
    <div data-test="contentLoading">{t('loading')}</div>
  </div>
));

const MiniBoardContent = memo(
  ({ board, answerId, id, boardWidgets, isEditing, isSharedAndUnpublished, widgetsToPartialAnswersIdMap, t }) => {
    if (!answerId || (board && !board.isLoadingMetaWidgets && !board.isFinishedLoadingMetaWidgets)) {
      return <LoadingMetaWidgets t={t} />;
    }
    if (boardWidgets.length < 1) {
      return <NoWidgets t={t} />;
    }
    return (
      <MiniBoard
        answerId={answerId}
        id={id}
        boardId={board?.id}
        boardWidgets={boardWidgets}
        isEditing={isEditing}
        isSharedAndUnpublished={isSharedAndUnpublished}
        widgetsToPartialAnswersIdMap={widgetsToPartialAnswersIdMap}
      />
    );
  }
);

const Footer = memo(({ board, metaPartialAnswers, answerId, id, messageRef, isEditing, t }) => {
  if (!board) return null;
  return (
    <BoardAnswerMessageFooter
      answerId={answerId}
      messageId={id}
      board={board}
      isEditing={isEditing}
      metaPartialAnswers={metaPartialAnswers}
      targetRef={messageRef}
    />
  );
});

const BoardAnswer = memo(
  ({
    board,
    boardId,
    boardWidgets,
    messageRef,
    commonFilters,
    message,
    answerId,
    expandedMiniWidget,
    isExpanded,
    isEditing,
    editingState,
    isSharedAndUnpublished,
    widgetsToPartialAnswersIdMap,
    id,
    dispatch,
    addToBoardCallback,
    scrollTo,
    t
  }) => {
    return (
      <MultiAnswerPureMessage
        ref={messageRef}
        filters={commonFilters}
        message={message}
        baseMessageClasses={isEditing ? [styles.highlightBaseMessage] : []}
        answerId={answerId}
        expandedMiniWidget={expandedMiniWidget}
        miniBoard={
          <MiniBoardContent
            board={board}
            answerId={answerId}
            id={id}
            isEditing={isEditing}
            isSharedAndUnpublished={isSharedAndUnpublished}
            boardWidgets={boardWidgets}
            widgetsToPartialAnswersIdMap={widgetsToPartialAnswersIdMap}
            t={t}
          />
        }
        title={
          <BoardName
            board={board}
            isExpanded={isExpanded}
            boardId={boardId}
            editingState={editingState}
            isEditing={isEditing}
            dispatch={dispatch}
            t={t}
          />
        }
        messageId={id}
        isFollowUp={false}
        dispatch={dispatch}
        addToBoardCallback={addToBoardCallback}
        customFooter={
          <Footer
            board={board}
            metaPartialAnswers={extractMetaPartialAnswers(boardWidgets)}
            answerId={answerId}
            t={t}
            id={id}
            isEditing={isEditing}
            messageRef={messageRef}
          />
        }
        scrollTo={scrollTo}
        t={t}
      />
    );
  }
);

const BoardAnswerMessage = ({
  answerId,
  boardId,
  dispatch,
  message,
  commonFilters,
  id,
  t,
  isEditing,
  boardsList,
  expandedMiniWidget,
  widgetsToPartialAnswersIdMap,
  scrollTo,
  editingState,
  addToBoardCallback,
  allBoardWidgets
}) => {
  const messageRef = useRef();

  const boardWidgetsFromThisBoard = useMemo(
    () => allBoardWidgets.find(boardWidget => boardWidget.boardId === boardId),
    [allBoardWidgets, boardId]
  );
  const boardWidgets = boardWidgetsFromThisBoard?.widgets || [];

  // get the board to be displayed from the list of boards
  const boardFromList = useMemo(() => boardsList.find(board => board.id === boardId), [boardId, boardsList]);
  let board = {
    id: boardId // Make sure ID is always set
  };
  if (boardFromList) {
    board = {
      ...boardFromList,
      isInSidebar: true
    };
  } else {
    board = {
      ...board,
      name: message?.boardName,
      boardSharingUrl: message?.boardSharingUrl,
      isEditable: false,
      isOwned: false,
      usesPublishingMode: false,
      isInSidebar: false
    };
  }
  // Augment with information from the boardWidgetProperties
  board = {
    ...board,
    isLoadingMetaWidgets: boardWidgetsFromThisBoard?.isLoadingMetaWidgets,
    isFinishedLoadingMetaWidgets: boardWidgetsFromThisBoard?.isFinishedLoadingMetaWidgets,
    linkedToBoard: boardWidgetsFromThisBoard?.linkedToBoard
  };

  const isExpanded = expandedMiniWidget?.answer;
  const isSharedAndUnpublished = board?.usesPublishingMode && !board?.isPublished;

  useEffect(() => {
    if (board && (!board.isLoadingMetaWidgets && !board.isFinishedLoadingMetaWidgets)) {
      dispatch(fetchBoardWidgets(board.id));
    }
  }, [board, board?.isLoadingMetaWidgets, board?.isFinishedLoadingMetaWidgets]);

  return (
    <div data-test="BoardAnswerMessageHook">
      <BoardAnswer
        board={board}
        boardId={boardId}
        boardWidgets={boardWidgets}
        messageRef={messageRef}
        commonFilters={commonFilters}
        message={message}
        answerId={answerId}
        expandedMiniWidget={expandedMiniWidget}
        isExpanded={isExpanded}
        isEditing={isEditing}
        editingState={editingState}
        isSharedAndUnpublished={isSharedAndUnpublished}
        widgetsToPartialAnswersIdMap={widgetsToPartialAnswersIdMap}
        id={id}
        dispatch={dispatch}
        addToBoardCallback={addToBoardCallback}
        scrollTo={scrollTo}
        t={t}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  boardsList: state.board.boardsList,
  isEditing: state.board.editingBoardId === ownProps.boardId,
  editingState: state.board.editingState,
  allBoardWidgets: state.boardWidgets,
  expandedMiniWidget: state.expandedMiniWidgets.find(w => w.messageId === ownProps.id)
});

export default withTranslation('veezoo', { withRef: true })(connect(mapStateToProps)(memo(BoardAnswerMessage)));
