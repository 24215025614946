import React, { useState, useMemo, useEffect, Fragment } from 'react';
import styles from './empty-input-suggestions.scss';
import inputStyles from './input.scss';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';

import Skeleton from '@material-ui/lab/Skeleton';

import { connect } from 'react-redux';

import { Backdrop, ListSubheader, SvgIcon } from '@material-ui/core';
import ChatIcon from 'svg/chat.svg';
import { HistoryIcon, KnowledgeGraphIcon } from 'components/Icons';
import Discovery from 'components/discovery/Discovery';
import { fetchRecentQuestions } from 'store/modules/inputAnalysis';
import Button from 'components/v2/Button';
import parseSuggestion from 'utils/suggestionParser';
import ScrollOnMountElement from 'components/shared/ScrollOnMountElement';
import KnowledgeGraph from 'components/knowledgeGraph/KnowledgeGraph';
import KnowledgeGraphSidebarWrapper from 'components/KnowledgeGraphSidebar/KnowledgeGraphSidebarWrapper';
import { useHistory, useLocation } from 'react-router-dom';
import { changeUrlGivenCurrentQueryParams } from 'components/LinkWithQuery';
import CloseIcon from '@material-ui/icons/CloseRounded';
import DescriptionTooltip from 'components/input/DescriptionTooltip';

import { possibleStates } from 'config/constants';

/**
 * This is the initial component that gets shown when someone focusses on the input field.
 *
 * It has three sections separated by a divider from bottom to top:
 * 1) three classic suggestions as we know it from the typicial auto complete, indicating starting points
 * 2) a list of the most recent searches
 * 3) two buttons:
 *    1) expand into discovery
 *    2) trigger knowledge graph view
 */

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: '570px',
    height: '100%',
    backgroundColor: theme.palette.background.paper
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  iconRoot: {
    fill: 'grey',
    height: '20px',
    width: '20px',
    minWidth: '20px',
    paddingRight: '13px'
  },
  subheaderRoot: {
    lineHeight: 'unset',
    fontSize: '11px',
    letterSpacing: '0.4px',
    paddingTop: '10px',
    paddingBottom: '6px',
    background: 'white'
  },
  skeletonRoot: {
    marginLeft: '3px',
    width: '100%',
    height: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
}));

/**
 * Constructs the navigation bar with title and buttons.
 * @param leftButton - the button on the left side
 * @param title - the title in the middle
 * @param rightButton  - the button on the right side
 */
const constructButtonNavigation = (leftButton, title, rightButton) => {
  return (
    <div className={styles.buttons}>
      <div className={styles.left}>{leftButton}</div>
      {title && (
        <div className={styles.center}>
          <span className={styles.navigationTitle}>{title}</span>
        </div>
      )}
      <div className={styles.right}>{rightButton}</div>
    </div>
  );
};

const EmptyInputSuggestions = ({
  t,
  recentQuestions,
  fetchingRecentQuestions,
  suggestions,
  dispatch,
  onSuggestionSelection,
  isEmbedded,
  onClickAway,
  graph,
  shouldShowDiscoveryButton,
  hasKnowledgeGraphSupport
}) => {
  const classes = useStyles();
  const [state, setState] = useState(possibleStates.suggestions);

  const [selectedSuggestion, setSelectedSuggestion] = useState(-1);
  const [numberOfSuggestionsToShow, setNumberOfSuggestionsToShow] = useState(3);
  const [numberOfRecentQuestionsToShow, setNumberOfRecentQuestionsToShow] = useState(3);

  // whenever we manipulate the url do so using react-router-dom
  const history = useHistory();

  // according to docs here we shouldn't use history.location https://v5.reactrouter.com/web/api/location
  const location = useLocation();

  // the url can initially be set to discovery or knowledge graph, so we want to show the respective view
  // it can be showDialogue=kg or showDialogue=discovery
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const showDialogue = urlParams.get('showDialogue');
    if (showDialogue === possibleStates.discovery) {
      setState(possibleStates.discovery);
    } else if (showDialogue === possibleStates.knowledgeGraph) {
      setState(possibleStates.knowledgeGraph);
    } else {
      // fall back to default state
      setState(possibleStates.suggestions);
    }
  }, [location]);

  // we combine the suggestions and recent questions into one array because we want to be able to navigate through them
  const combinedSuggestions = useMemo(() => {
    const slicedSuggestions = suggestions.slice(0, numberOfSuggestionsToShow);
    const slicedRecentQuestions = recentQuestions.slice(0, numberOfRecentQuestionsToShow);
    return [
      ...slicedSuggestions.map((s, i) => ({ ...s, type: 'suggestion', index: i })),
      ...slicedRecentQuestions.map((s, i) => ({ ...s, type: 'recentQuestion', index: i + slicedSuggestions.length }))
    ];
  }, [suggestions, recentQuestions, numberOfSuggestionsToShow, numberOfRecentQuestionsToShow]);

  // fetch recent questions on initial load
  useEffect(() => {
    dispatch(fetchRecentQuestions());
  }, []);

  // On Key up or down, change the selected suggestion
  const handleKeyDown = e => {
    if (combinedSuggestions.length === 0) {
      return;
    }
    if (e.key === 'ArrowDown') {
      // if selected suggestion is not set yet OR we're at the first one (that is 0) move to the last one
      // if we're not at the first one, move to the previous one
      if (selectedSuggestion === -1 || selectedSuggestion === 0) {
        setSelectedSuggestion(combinedSuggestions.length - 1);
      } else {
        setSelectedSuggestion(selectedSuggestion - 1);
      }
    } else if (e.key === 'ArrowUp') {
      setSelectedSuggestion((selectedSuggestion + 1) % combinedSuggestions.length);
    } else if (e.key === 'Escape') {
      onClickAway();
    }

    // if enter or tab is pressed, select the suggestion
    if ((e.key === 'Enter' || e.key === 'Tab') && selectedSuggestion !== -1) {
      onSuggestionSelection(e, combinedSuggestions[selectedSuggestion]);
    }
  };

  // add the keydown listener and remove it as soon as we leave the possibleStates.suggestions state
  useEffect(() => {
    if (state === possibleStates.suggestions) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state, selectedSuggestion, combinedSuggestions]);

  const closeButton = useMemo(
    () => (
      <Button
        label={t('close')}
        onClick={() => {
          changeUrlGivenCurrentQueryParams(history, {
            showDialogue: null
          });
        }}
        icon={<CloseIcon />}
      />
    ),
    [t, location, history]
  );

  const discoveryButton = useMemo(
    () => (
      <Button
        label={t('welcome-message.what-can-i-ask')}
        onClick={() => {
          changeUrlGivenCurrentQueryParams(history, {
            showDialogue: possibleStates.discovery
          });
        }}
        // if we don't have discovery content, we render it but not visible
        // this is to prevent the buttons from jumping around
        style={{ visibility: shouldShowDiscoveryButton ? 'visible' : 'hidden' }}
        icon={<SvgIcon component={ChatIcon} viewBox="1 -2 24 24" />}
      />
    ),
    [t, location, history]
  );

  const kgButton = useMemo(
    () => (
      <Button
        label={t('welcome-message.what-data-is-there')}
        onClick={() => {
          if (isEmbedded) {
            changeUrlGivenCurrentQueryParams(history, {
              showDialogue: possibleStates.knowledgeGraph
            });
          } else {
            changeUrlGivenCurrentQueryParams(history, {
              kgSidebar: true,
              visualMode: true,
              showDialogue: null
            });
            onClickAway(null);
          }
        }}
        // if we are not allowed to see a knowledge graph, we render it but not visible
        // this is to prevent the buttons from jumping around
        style={{ visibility: hasKnowledgeGraphSupport ? 'visible' : 'hidden' }}
        icon={<KnowledgeGraphIcon />}
      />
    ),
    [t, location, history, isEmbedded, onClickAway]
  );

  const buttonNavigation = useMemo(() => {
    if (state === possibleStates.discovery) {
      return constructButtonNavigation(kgButton, t('discovery-description'), closeButton);
    } else if (state === possibleStates.knowledgeGraph) {
      return constructButtonNavigation(discoveryButton, t('tutorial.overview.knowledge-graph-title'), closeButton);
    } else {
      return constructButtonNavigation(discoveryButton, '', kgButton);
    }
  }, [t, state, location]);

  const recentQuestionsSubheader = useMemo(
    () => (
      <ListSubheader classes={{ root: classes.subheaderRoot }}>
        <span className={styles.listSubtitle}>{t('tooltip.recent-questions')}</span>
        {!fetchingRecentQuestions && recentQuestions.length > numberOfRecentQuestionsToShow && (
          <span className={styles.showMore} onClick={() => setNumberOfRecentQuestionsToShow(recentQuestions.length)}>
            {t('show-more')}
          </span>
        )}
      </ListSubheader>
    ),
    [recentQuestions, numberOfRecentQuestionsToShow]
  );

  const recentQuestionsList = useMemo(() => {
    return (
      <List component="nav" disablePadding={true} dense={true} subheader={recentQuestionsSubheader}>
        {fetchingRecentQuestions ? (
          <>
            <ListItem key="skeleton_one">
              <Skeleton variant="rect" classes={{ root: classes.skeletonRoot }} style={{ maxWidth: '85%' }} />
            </ListItem>
            <ListItem key="skeleton_two">
              <Skeleton variant="rect" classes={{ root: classes.skeletonRoot }} style={{ maxWidth: '30%' }} />
            </ListItem>
          </>
        ) : (
          recentQuestions.length > 0 && (
            <>
              {combinedSuggestions
                .filter(s => s.type === 'recentQuestion')
                .reverse()
                .map(question => {
                  return (
                    <ListItem
                      key={question.index}
                      tabIndex={null} // disable losing focus on input box when selecting a suggestion
                      selected={question.index === selectedSuggestion}
                      autoFocus={question.index === selectedSuggestion}
                      button
                      onClick={e => onSuggestionSelection(e, question)}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.iconRoot
                        }}
                      >
                        <HistoryIcon />
                      </ListItemIcon>
                      <div className={styles.suggestion}>
                        {parseSuggestion(
                          question,
                          inputStyles._plain,
                          inputStyles._linked,
                          inputStyles._linkedDescription,
                          inputStyles._onlyShow,
                          inputStyles._icon
                        )}
                      </div>
                    </ListItem>
                  );
                })}
              {recentQuestions.length === numberOfRecentQuestionsToShow && <ScrollOnMountElement />}
            </>
          )
        )}
      </List>
    );
  }, [combinedSuggestions, selectedSuggestion, recentQuestions, fetchingRecentQuestions]);

  const suggestionsSubheader = useMemo(
    () => (
      <ListSubheader classes={{ root: classes.subheaderRoot }}>
        <span className={styles.listSubtitle}>{t('you-could-start-with')}</span>
        {suggestions.length > numberOfSuggestionsToShow && (
          <span className={styles.showMore} onClick={() => setNumberOfSuggestionsToShow(suggestions.length)}>
            {t('show-more')}
          </span>
        )}
      </ListSubheader>
    ),
    [suggestions, numberOfSuggestionsToShow]
  );

  const suggestionsList = useMemo(
    () => (
      <List component="nav" disablePadding={true} dense={true} subheader={suggestionsSubheader}>
        {combinedSuggestions
          .filter(s => s.type === 'suggestion')
          .reverse()
          .map(suggestion => {
            const suggestionElement = (
              <ListItem
                tabIndex={null} // disable losing focus on input box when selecting a suggestion
                key={suggestion.index}
                selected={suggestion.index === selectedSuggestion}
                autoFocus={suggestion.index === selectedSuggestion}
                button
                onClick={e => onSuggestionSelection(e, suggestion)}
              >
                <div className={styles.suggestion}>
                  {parseSuggestion(
                    suggestion,
                    inputStyles._plain,
                    inputStyles._linked,
                    inputStyles._linkedDescription,
                    inputStyles._onlyShow,
                    inputStyles._icon
                  )}
                </div>
              </ListItem>
            );
            // Works for now only on keyboard navigation, not on mouse hover
            return suggestion.index === selectedSuggestion ? (
              <DescriptionTooltip suggestion={suggestion}>{suggestionElement}</DescriptionTooltip>
            ) : (
              suggestionElement
            );
          })}
        {suggestions.length === numberOfSuggestionsToShow && <ScrollOnMountElement />}
      </List>
    ),
    [combinedSuggestions, selectedSuggestion, suggestions]
  );

  let content;
  if (state === possibleStates.discovery) {
    content = <Discovery />;
  } else if (state === possibleStates.knowledgeGraph) {
    // the two divs (KnowledgeGraphSidebarWrapper and KnowledgeGraph) are side-by-side, so we need to wrap them in a div
    // the KnowledgeGraphSidebarWrapper is the sidebar with the list of nodes and can overflow, in that case
    // we need to style it so that it has a scrollbar. The KnowledgeGraph is the actual graph and should not have a scrollbar.
    content = (
      <div style={{ display: 'flex', height: '100%' }}>
        <KnowledgeGraphSidebarWrapper />
        <KnowledgeGraph graph={graph} isEmbedded={true} />
      </div>
    );
  } else {
    content = (
      <>
        {recentQuestionsList}
        {suggestions.length > 0 && suggestionsList}
      </>
    );
  }

  return (
    <div className={classes.root}>
      {buttonNavigation}
      <Divider />
      <div className={styles.contentContainer}>{content}</div>
      <Backdrop open={true} onClick={onClickAway} />
    </div>
  );
};

const mapStateToProps = state => ({ fetchingRecentQuestions: state.inputAnalysis.fetchingRecentQuestions });

export default connect(mapStateToProps)(EmptyInputSuggestions);
