import uuid1 from 'uuid/v1';
import { authFetchPost } from 'store/utils/authFetch';
import flatMap from 'array.prototype.flatmap';

import { feedbackStates } from './recentFeedback';

import { STOP_CUSTOMER_SELECTION_VIEW } from 'store/modules/customer-selections';
import { BOARD_REMOVE_REQUEST, DISABLE_BOARD_REQUEST } from './board';
import { DISCOVERY_TO_CHAT } from './discovery';

import { answerModificationTypes, answerVisualizationPlaceholders, dateComputations } from 'config/constants';
import { addCommaThousandSeparator } from 'utils/numberFormat';

import services from 'services';
import { fetchUserInformation } from './user';
import { fetchPartialAnswer } from './partialAnswers';
import uuid from 'uuid';
import { handleError } from 'services/http';

// todo export inside Chat as well
export const messageTypes = {
  VEEZOO_LOADING: 'VEEZOO_LOADING',
  VEEZOO_COMPLEMENTARY_LOADING: 'VEEZOO_COMPLEMENTARY_LOADING',
  VEEZOO_ANSWER_MESSAGE: 'VEEZOO_ANSWER_MESSAGE',
  VEEZOO_MULTI_ANSWER_MESSAGE: 'VEEZOO_MULTI_ANSWER_MESSAGE',
  VEEZOO_INFO_MESSAGE: 'VEEZOO_INFO_MESSAGE', // this happens when there is no proper answer on question or it might not exist in the data,
  NEGATIVE_FEEDBACK_MESSAGE: 'NEGATIVE_FEEDBACK_MESSAGE', // this happens when the user gives a negative feedback
  WARNING_INFO_MESSAGE: 'WARNING_INFO_MESSAGE', // this happens when there is not proper answer on question and display possible answer
  SMART_ACTION_MESSAGE: 'SMART_ACTION_MESSAGE',
  VEEZOO_BOARD_ANSWER_MESSAGE: 'VEEZOO_BOARD_ANSWER_MESSAGE',
  VEEZOO_BOARD_ANSWER_MESSAGE_LOADING: 'VEEZOO_BOARD_ANSWER_MESSAGE_LOADING',
  VEEZOO_CUSTOMERS_OF_THE_DAY_LOADING: 'VEEZOO_CUSTOMERS_OF_THE_DAY_LOADING',
  VEEZOO_CUSTOMERS_OF_THE_DAY_MESSAGE: 'VEEZOO_CUSTOMERS_OF_THE_DAY_MESSAGE',
  VEEZOO_CUSTOMER_SELECTION_MESSAGE: 'VEEZOO_CUSTOMER_SELECTION_MESSAGE',
  VEEZOO_CUSTOMER_SELECTION_MESSAGE_LOADING: 'VEEZOO_CUSTOMER_SELECTION_MESSAGE_LOADING',
  USER_HIDDEN_MESSAGE: 'USER_HIDDEN_MESSAGE',
  USER: 'USER',
  VEEZOO_REPHRASE_MESSAGE: 'VEEZOO_REPHRASE_MESSAGE'
};

export const loadingMessageTypes = [
  messageTypes.VEEZOO_LOADING,
  messageTypes.VEEZOO_COMPLEMENTARY_LOADING,
  messageTypes.VEEZOO_CUSTOMERS_OF_THE_DAY_LOADING,
  messageTypes.VEEZOO_BOARD_ANSWER_MESSAGE_LOADING,
  messageTypes.VEEZOO_CUSTOMER_SELECTION_MESSAGE_LOADING
];

/**
 * Messages with these types can be replaced if they are the last message.
 * Example: if you click to see a board and then another board,
 * the second one replaces the first in the Chat, instead of appending.
 */
export const replaceableMessageTypes = [
  messageTypes.VEEZOO_BOARD_ANSWER_MESSAGE_LOADING,
  messageTypes.VEEZOO_BOARD_ANSWER_MESSAGE,
  messageTypes.VEEZOO_CUSTOMERS_OF_THE_DAY_LOADING,
  messageTypes.VEEZOO_CUSTOMERS_OF_THE_DAY_MESSAGE,
  messageTypes.VEEZOO_CUSTOMER_SELECTION_MESSAGE,
  messageTypes.VEEZOO_CUSTOMER_SELECTION_MESSAGE_LOADING
];

/**
 * Different save modes used in answers
 */
export const saveModes = {
  CUSTOMER_SELECTION: 'CUSTOMER_SELECTION',
  BOARD: 'BOARD'
};

export const CHATMESSAGES_REQUEST = 'CHATMESSAGES_REQUEST';
export const CHATMESSAGES_SUCCESS = 'CHATMESSAGES_SUCCESS';
export const CHATMESSAGES_FAILURE = 'CHATMESSAGES_FAILURE';
export const CHATMESSAGES_REMOVE = 'CHATMESSAGES_REMOVE';

export const CHATMESSAGE_HIDE = 'CHATMESSAGE_HIDE';
export const CHATMESSAGE_UNHIDE = 'CHATMESSAGE_UNHIDE';

export const CHATMESSAGE_REQUEST = 'CHATMESSAGE_REQUEST';
export const CHATMESSAGE_SUCCESS = 'CHATMESSAGE_SUCCESS';
export const CHATMESSAGE_FAILURE = 'CHATMESSAGE_FAILURE';

export const CHATMESSAGE_VISUALIZATION_COUNT_REQUEST = 'CHATMESSAGE_VISUALIZATION_COUNT_REQUEST';
export const CHATMESSAGE_VISUALIZATION_COUNT_SUCCESS = 'CHATMESSAGE_VISUALIZATION_COUNT_SUCCESS';
export const CHATMESSAGE_VISUALIZATION_COUNT_FAILURE = 'CHATMESSAGE_VISUALIZATION_COUNT_FAILURE';

export const CHATMESSAGE_SET_FEEDBACK = 'CHATMESSAGE_SET_FEEDBACK';
export const CHATMESSAGE_LOCAL = 'CHATMESSAGE_LOCAL';

export const COMPLEMENTARY_REQUEST = 'COMPLEMENTARY_REQUEST';
export const COMPLEMENTARY_SUCCESS = 'COMPLEMENTARY_SUCCESS';
export const COMPLEMENTARY_FAILURE = 'COMPLEMENTARY_FAILURE';

export const BOARD_CHAT_MESSAGE_REQUEST = 'BOARD_CHAT_MESSAGE_REQUEST';
export const BOARD_CHAT_MESSAGE_SUCCESS = 'BOARD_CHAT_MESSAGE_SUCCESS';
export const BOARD_CHAT_MESSAGE_FAILURE = 'BOARD_CHAT_MESSAGE_FAILURE';

export const replaceTotalCountPlaceholder = (summary, rowCount) =>
  summary.replace(
    answerVisualizationPlaceholders.TOTAL_NUMBER_PLACEHOLDER,
    rowCount ? addCommaThousandSeparator(rowCount) : '???'
  );

export const getAnswerTotalCount = partialAnswerId => {
  return async dispatch => {
    dispatch({ type: CHATMESSAGE_VISUALIZATION_COUNT_REQUEST, partialAnswerId: partialAnswerId });

    const result = await services.getAnswerTotalRowCount(partialAnswerId);

    if (result.success) {
      dispatch({
        type: CHATMESSAGE_VISUALIZATION_COUNT_SUCCESS,
        partialAnswerId: partialAnswerId,
        rowCount: result.data.rowCount
      });
      return;
    }

    dispatch({ type: CHATMESSAGE_VISUALIZATION_COUNT_FAILURE, partialAnswerId: partialAnswerId });
  };
};

const boardQuestion = async (dispatch, boardId) => {
  const result = await services.fetchBoardQuestion(boardId);
  handleError(result, dispatch);

  // For some reason, if we can't fetch a board, instead of receiving an error we
  // are receiving a success "true" 204 "No Content" response. So we need to check if there's something inside "data".
  if (!result.data) {
    throw Error(result.statusText);
  }

  return result.data;
};

export const askQuestion = async (question, otherParams, dispatch) => {
  const payload = { question, ...otherParams };

  const result = await services.postQuestion(payload);
  handleError(result, dispatch);

  if (!result.success) {
    throw Error(result.statusText);
  }

  return result.data;
};

export const retryQuestion = async (questionId, dispatch) => {
  const result = await services.retryQuestion(questionId);
  handleError(result, dispatch);

  if (!result.success) {
    throw Error(result.statusText);
  }

  return result.data;
};

// Fetch (parse) an existing question by its id
export const fetchQuestionById = async (questionId, dispatch) => {
  const result = await services.fetchQuestionById(questionId);
  handleError(result, dispatch);
  if (!result.success) {
    throw Error(result.statusText);
  }
  return result.data;
};

// add local (from frontend side) message to chat
export const addLocalMessage = (
  username,
  message,
  linkedEntities = [],
  requestId = undefined,
  isWarningMessage,
  smartActionUrl,
  isNegativeFeedback,
  isRephraseMessage
) => {
  const timestamp = Date.now();
  let type;

  if (username === 'veezoo' || username === 'Veezoo') {
    if (isWarningMessage) {
      type = messageTypes.WARNING_INFO_MESSAGE;
    } else if (smartActionUrl) {
      type = messageTypes.SMART_ACTION_MESSAGE;
    } else if (isNegativeFeedback) {
      type = messageTypes.NEGATIVE_FEEDBACK_MESSAGE;
    } else if (isRephraseMessage) {
      type = messageTypes.VEEZOO_REPHRASE_MESSAGE;
    } else {
      type = messageTypes.VEEZOO_INFO_MESSAGE;
    }
  } else {
    type = messageTypes.USER;
  }

  return {
    type: CHATMESSAGE_LOCAL,
    requestId: requestId,
    message: {
      type: type,
      isLocal: true,
      authorName: username,
      username: username,
      textAnswer: message,
      id: 'l_' + timestamp,
      timestamp: timestamp,
      linkedEntities: linkedEntities,
      isWarningMessage: isWarningMessage,
      smartActionUrl: smartActionUrl
    }
  };
};

// add incoming server message to chat
export const addIncomingMessage = (message, localQuestionId, requestId, mainAnswerMessageId) => ({
  type: CHATMESSAGE_SUCCESS,
  localQuestionId: localQuestionId,
  requestId: requestId,
  message: {
    ...message,
    timestamp: Date.now(),
    id: mainAnswerMessageId || uuid1()
  }
});

export const fetchBoardMessage = (boardId = undefined, t = text => text) => {
  return dispatch => {
    const requestId = uuid1();

    dispatch({
      type: STOP_CUSTOMER_SELECTION_VIEW // switch from customer selection view
    });

    dispatch({
      type: CHATMESSAGE_REQUEST,
      requestId: requestId,
      boardId: boardId
    });

    // order is important here, should dispatch after 'CHATMESSAGE_REQUEST'
    dispatch({
      type: BOARD_CHAT_MESSAGE_REQUEST,
      boardId: boardId,
      isAlreadyFetchingMessageBoard: true
    });

    boardQuestion(dispatch, boardId)
      .then(json => {
        dispatch(addIncomingMessage(json, -1, requestId));

        dispatch({
          type: BOARD_CHAT_MESSAGE_SUCCESS,
          boardId: boardId,
          isAlreadyFetchingMessageBoard: false
        });
      })
      .catch(error => {
        dispatch({
          type: CHATMESSAGE_FAILURE,
          errorMessageText: `${t('sharing.board-not-found-or-not-authorized')}.`,
          requestId,
          boardId
        });

        dispatch({
          type: BOARD_CHAT_MESSAGE_FAILURE,
          boardId: boardId,
          isAlreadyFetchingMessageBoard: false
        });

        console.log('ERROR', error);
      });
  };
};

// clear the local state of messages (used after signout).
export const removeMessageList = () => {
  return {
    type: CHATMESSAGES_REMOVE
  };
};

export const fetchComplementary = (interpretationId, answerId, localQuestionId, mainAnswerMessageId) => {
  return async dispatch => {
    const requestId = uuid1();
    dispatch({
      type: COMPLEMENTARY_REQUEST,
      interpretationId,
      localQuestionId,
      requestId,
      mainAnswerMessageId
    });

    const result = await services.fetchComplementaryQuestion(answerId, interpretationId);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({
        type: COMPLEMENTARY_FAILURE,
        interpretationId,
        localQuestionId,
        requestId
      });
      throw Error(result.statusText);
    }

    dispatch({
      type: COMPLEMENTARY_SUCCESS,
      interpretationId,
      localQuestionId,
      requestId,
      mainAnswerMessageId,
      message: {
        ...result.data,
        id: uuid1()
      }
    });
  };
};

export const fetchMessage = (question, localQuestionId, otherParams, t = {}) => {
  return dispatch => {
    const requestId = uuid1();

    dispatch({
      type: CHATMESSAGE_REQUEST,
      requestId: requestId
    });
    askQuestion(question, otherParams, dispatch)
      .then(json => {
        processAnswer(json, requestId, localQuestionId, dispatch, t);
        // refresh the user information as asking a question possibly adds a milestone
        dispatch(fetchUserInformation());
      })
      .catch(error => {
        dispatch({ type: CHATMESSAGE_FAILURE, requestId: requestId, errorMessageText: t('error') });
        console.log('ERROR', error);
      });
  };
};

export const fetchRetriedQuestion = (questionId, localQuestionId, t) => {
  return dispatch => {
    dispatch(addLocalMessage('veezoo', `${t('re-asking-the-question')} 🪄`));

    // Retry the question and fetch the answer of the retried question
    const requestId = uuid1();
    dispatch({
      type: CHATMESSAGE_REQUEST,
      requestId: requestId
    });
    retryQuestion(questionId, dispatch)
      .then(json => {
        processAnswer(json, requestId, localQuestionId, dispatch, t);
        // refresh the user information as asking a question possibly adds a milestone
        dispatch(fetchUserInformation());
      })
      .catch(error => {
        dispatch({ type: CHATMESSAGE_FAILURE, requestId: requestId, errorMessageText: t('error') });
        console.log('ERROR', error);
      });
  };
};

// fetch partial answers for both single & multi answers
export const fetchPartialAnswers = (json, dispatch, mainAnswerMessageId) => {
  json?.answer?.interpretationId &&
    dispatch(fetchPartialAnswer(json.answer.interpretationId, json.answerId, mainAnswerMessageId));

  json.metaPartialAnswers.forEach(metaPartial => {
    dispatch(fetchPartialAnswer(metaPartial.interpretationId, metaPartial.answerId));
  });
};

// add local (from frontend side) negative feedback message to chat
export const addNegativeFeedbackMessage = (username, feedback) => {
  const timestamp = Date.now();

  return {
    type: CHATMESSAGE_LOCAL,
    message: {
      type: messageTypes.NEGATIVE_FEEDBACK_MESSAGE,
      isLocal: true,
      authorName: username,
      username: username,
      feedback: feedback,
      id: 'l_' + timestamp,
      timestamp: timestamp
    }
  };
};

// add local (from frontend side) message to chat and ask the question
export const askLocalQuestion = (username, message, otherParams, t) => {
  return dispatch => {
    // display the question locally
    const localMessage = addLocalMessage(username, message, otherParams.linkedEntities);
    dispatch(localMessage);

    // fetch and display the answer
    dispatch(fetchMessage(message, localMessage.message.id, otherParams, t));
  };
};

export const showOrHideMessage = (shouldHide, messageId) => {
  return dispatch => {
    dispatch({ type: shouldHide ? CHATMESSAGE_HIDE : CHATMESSAGE_UNHIDE, messageId: messageId });
  };
};

export const fetchDataForClass = (classUri, t = {}) => {
  return dispatch => {
    const requestId = uuid1();

    dispatch({
      type: CHATMESSAGE_REQUEST,
      requestId: requestId
    });
    dataForClassRequest(classUri, dispatch)
      .then(json => {
        processAnswer(json, requestId, -1, dispatch, t);
      })
      .catch(error => {
        dispatch({ type: CHATMESSAGE_FAILURE, requestId: requestId, errorMessageText: t('error') });
        console.log('ERROR', error);
      });
  };
};

export const dataForClassRequest = (classUri, dispatch) => {
  return authFetchPost('/data/' + classUri + '?sessionId=' + window.veezoo.sessionId, dispatch).then(response => {
    if (response.ok) return response.json();
    throw Error(response.statusText);
  });
};

export const fetchModifiedAnswer = (payload, localQuestionId, t = {}) => {
  return async dispatch => {
    const requestId = uuid1();

    dispatch({ type: CHATMESSAGE_REQUEST, requestId: requestId });

    const result = await services.askModifiedAnswer(payload);

    if (result.success) {
      // The treatment of modified answers in the backend is not very clean at the moment, and the object we receive
      //  here is partially broken (e.g., it has a question ID set but question is an empty string). To avoid a bigger
      //  refactoring of the answer messages in the backend, we just add an isModification flag here for now so we can
      //  keep track of what answers were modifications.
      const dataWithFlag = { ...result.data, answer: { ...result.data.answer, isModification: true } };
      processAnswer(dataWithFlag, requestId, localQuestionId, dispatch, t);
    } else {
      dispatch({ type: CHATMESSAGE_FAILURE, requestId: requestId, errorMessageText: t('error') });
      console.log('ERROR', result?.response);
    }
  };
};

export const modifyAnswer = ({
  username,
  message,
  linkedEntities,
  answerData,
  id,
  representedCandidate,
  representedRelation,
  computationName,
  values,
  type,
  modificationType,
  t
}) => {
  return dispatch => {
    const localMessage = addLocalMessage(username, message, linkedEntities);
    dispatch(localMessage);

    let modification = { type: modificationType, representedCandidate, representedRelation };

    if (modificationType === answerModificationTypes.datetime.value) {
      const structuredFilterMapEntriesForDate = answerData.structuredFiltersMap.filter(filter => filter.id === id);

      const changeFrom =
        structuredFilterMapEntriesForDate && structuredFilterMapEntriesForDate.length > 0
          ? structuredFilterMapEntriesForDate.reduce((total, filter) => {
              const candInterpPairs = filter?.candidateInterpretationPairs.map(pair => ({ ...pair, type })) || [];
              return [...total, ...candInterpPairs];
            }, [])
          : [
              {
                type,
                candidateId: id,
                interpretationId: answerData.interpretationId ? answerData.interpretationId : '',
                ...(representedCandidate ? { representedCandidate } : {}),
                ...(representedRelation ? { representedRelation } : {})
              }
            ];

      const changeTo =
        computationName === dateComputations.BETWEEN.value
          ? [{ computationName, values }]
          : values.map(value => ({ computationName, values: [value] }));

      modification = { ...modification, changeFrom, changeTo };
    } else {
      const structuredFilterMapEntry = answerData.structuredFiltersMap.find(filter => filter.id === id);
      const candidateInterpretationPairs = structuredFilterMapEntry?.candidateInterpretationPairs
        ? structuredFilterMapEntry?.candidateInterpretationPairs
        : [
            {
              candidateId: id,
              interpretationId: answerData.interpretationId,
              ...(modification.representedCandidate ? { representedCandidate: modification.representedCandidate } : {}),
              ...(modification.representedRelation ? { representedRelation: modification.representedRelation } : {})
            }
          ];

      if (modificationType === answerModificationTypes.boolean.value) {
        modification = { ...modification, candidateInterpretationPairs, value: values[0] };
      } else if (modificationType === answerModificationTypes.entity.value) {
        modification = {
          ...modification,
          candidateInterpretationPairs,
          uriVector: linkedEntities.map(entity => entity.value)
        };
      } else if (modificationType === answerModificationTypes.template.value) {
        const changeFrom = candidateInterpretationPairs.map(pair => ({ ...pair, type }));
        modification = { type: modificationType, changeFrom, changeTo: values };
      }
    }

    const payload = {
      answerId: answerData.answerId,
      ...(answerData.partialAnswerId ? { partialAnswerId: answerData.partialAnswerId } : {}),
      ...(answerData.interpretationId ? { interpretationId: answerData.interpretationId } : {}),
      modifications: [modification]
    };

    // fetch and display the answer
    dispatch(fetchModifiedAnswer(payload, localMessage.message.id, t));
  };
};

export const removeFilterFromAnswer = (username, message, answerData, removeInfo, t) => {
  return dispatch => {
    const localMessage = addLocalMessage(username, message);
    dispatch(localMessage);

    const payload = {
      answerId: answerData.answerId,
      ...(answerData.partialAnswerId ? { partialAnswerId: answerData.partialAnswerId } : {}),
      ...(answerData.interpretationId ? { interpretationId: answerData.interpretationId } : {}),
      modifications: [
        {
          type: 'REMOVE_MOD',
          removeInfo
        }
      ]
    };

    // fetch and display the answer
    dispatch(fetchModifiedAnswer(payload, localMessage.message.id, t));
  };
};

export const modifyTableColumnAnswer = (username, message, payload, t) => dispatch => {
  const localMessage = addLocalMessage(username, message);
  dispatch(localMessage);

  dispatch(fetchModifiedAnswer(payload, localMessage.message.id, t));
};

export const fetchAnswerOfInterpretation = (interpretationId, answerId, localQuestionId) => {
  return async dispatch => {
    const requestId = uuid1();
    dispatch({ type: CHATMESSAGE_REQUEST, requestId });

    const result = await services.fetchAnswerOfInterpretation(answerId, interpretationId);
    handleError(result, dispatch);

    if (!result.success) {
      dispatch({ type: CHATMESSAGE_FAILURE, requestId });
      throw Error(result.statusText);
    }

    processAnswer(result.data, requestId, localQuestionId, dispatch, {});
  };
};

// mainAnswerMessageId is the ID of the main answer message (possibly only coming in the future)
// For answers coming from a rephrasing, the mainAnswerMessageId is passed in,
// as the rephrased question message is available before the main answer, and must reference it (and thus already generates an ID)
// Otherwise, a new mainAnswerMessageId is generated here
export const processAnswer = (answerJson, requestId, localQuestionId, dispatch, t, mainAnswerMessageId = uuid()) => {
  // if we have a rephrased question as answer, we ask the rephrased question
  if (answerJson.rephrasedQuestion) {
    processRephrasedQuestion(answerJson, requestId, localQuestionId, mainAnswerMessageId, dispatch, t);
  }
  // otherwise, insert the answer and fetch the partial answers
  else {
    dispatch(addIncomingMessage(answerJson, localQuestionId, requestId, mainAnswerMessageId));
    fetchPartialAnswers(answerJson, dispatch, mainAnswerMessageId);
  }
};

export const processRephrasedQuestion = (
  rephrasedQuestionJson,
  requestId,
  localQuestionId,
  mainAnswerMessageId,
  dispatch,
  t
) => {
  dispatch({
    type: CHATMESSAGE_LOCAL,
    requestId,
    mainAnswerMessageId,
    message: {
      type: messageTypes.VEEZOO_REPHRASE_MESSAGE,
      isLocal: true,
      rephraseForLocalQuestionId: localQuestionId,
      authorName: 'veezoo',
      username: 'veezoo',
      textAnswer: rephrasedQuestionJson.rephrasedQuestion,
      originalQuestion: rephrasedQuestionJson.question,
      id: 'l_' + uuid(),
      timestamp: Date.now()
    }
  });
  fetchQuestionById(rephrasedQuestionJson.questionId, dispatch)
    .then(json => {
      processAnswer(json, requestId, localQuestionId, dispatch, t, mainAnswerMessageId);
      // refresh the user information as asking a question possibly adds a milestone
      dispatch(fetchUserInformation());
    })
    .catch(error => {
      dispatch({ type: CHATMESSAGE_FAILURE, requestId: requestId, errorMessageText: t('error') });
      console.log('ERROR', error);
    });
};

export const chatMessages = (state = [], action) => {
  switch (action.type) {
    case CHATMESSAGES_FAILURE:
      return [...state];

    case CHATMESSAGES_SUCCESS:
      return action.messageList.map(message => {
        // add a default feedback state to all messages
        return {
          ...message,
          feedback: feedbackStates.NONE
        };
      });

    case CHATMESSAGES_REMOVE: {
      return [];
    }

    case CHATMESSAGE_REQUEST: {
      const newMessageCanReplace = action.boardId !== undefined || action.canReplaceLastMessage;
      const lastMessageCanBeReplaced =
        state.length > 0 ? replaceableMessageTypes.includes(state[state.length - 1].type) : false;
      let resultState = [...state];

      if (newMessageCanReplace && lastMessageCanBeReplaced) {
        // if we fetch a board message and the last message is also one
        // replace the last message
        resultState = resultState.slice(0, -1);
      }

      // Show a loader specific to the customers of the day or board message,
      // because it could take a while until we get a result
      // and we do not want to be stuck with the 3 loading dots "..."
      let params;

      if (action.isCustomersOfTheDay) {
        params = {
          type: messageTypes.VEEZOO_CUSTOMERS_OF_THE_DAY_LOADING
        };
      } else if (action.customerSelectionId !== undefined) {
        params = {
          type: messageTypes.VEEZOO_CUSTOMER_SELECTION_MESSAGE_LOADING,
          id: action.requestId,
          customerSelectionId: action.customerSelectionId
        };
      } else if (action.boardId !== undefined) {
        params = {
          type: messageTypes.VEEZOO_BOARD_ANSWER_MESSAGE_LOADING,
          id: action.requestId,
          boardId: action.boardId
        };
      } else {
        params = {
          type: messageTypes.VEEZOO_LOADING,
          id: action.requestId
        };
      }

      // if we ask a question, let's append a loading message after it (at the end)
      return [
        ...resultState,
        {
          requestId: action.requestId,
          ...params
        }
      ];
    }

    case DISCOVERY_TO_CHAT: {
      return [...state, action.message];
    }

    case CHATMESSAGE_SUCCESS: {
      return state.map(message => {
        // replace the loading message (which has to exist) with the received answer
        if (loadingMessageTypes.includes(message.type) && message.requestId === action.requestId) {
          return {
            ...action.message,
            isComplementary: false,
            localQuestionId: action.localQuestionId,
            feedback: feedbackStates.NONE
          };
        } else if (message.complementaryForAnswerId === action.message.id) {
          // display the complementary for it (either loading or final loaded answer),
          // since we now have finished loading the main answer
          return {
            ...message,
            isHidden: false
          };
        } else {
          // if it's not our loading message, keep the message element as is
          return message;
        }
      });
    }

    case CHATMESSAGE_FAILURE:
      return state.map(message => {
        // replace the loading message (which has to exist) with an error message
        if (loadingMessageTypes.includes(message.type) && message.requestId === action.requestId) {
          return {
            ...message,
            textAnswer: action.errorMessageText,
            type: messageTypes.WARNING_INFO_MESSAGE
          };
        } else {
          // if it's not our loading message, keep the message element as is
          return message;
        }
      });

    case COMPLEMENTARY_FAILURE:
      // Remove the loading complementary message silently
      return state.filter(m => !(loadingMessageTypes.includes(m.type) && m.requestId === action.requestId));

    case COMPLEMENTARY_REQUEST:
      return flatMap(state, m => {
        // if we request a complementary, insert the loading message right after the main answer (or the loading message)
        // it is either the loading message (m.mainAnswerMessageId) or already the answer message (m.id)
        // Note: action.mainAnswerMessageId could be undefined, hence we check for it. We had issues when running /tests
        // check: https://veezoo.slack.com/archives/CCX11HLRL/p1725442421581009
        if (
          action.mainAnswerMessageId &&
          (m.mainAnswerMessageId === action.mainAnswerMessageId || m.id === action.mainAnswerMessageId)
        ) {
          return [
            m,
            {
              type: messageTypes.VEEZOO_COMPLEMENTARY_LOADING,
              requestId: action.requestId,
              complementaryForAnswerId: action.mainAnswerMessageId,
              // we hide the complementary loading from the Chat if we still have the veezoo_loading message
              // this gets unhidden when the main answer is loaded (CHATMESSAGE_SUCCESS)
              // we need this, because we add a delay of 2s to show the answer after the rephrase
              // and we don't want to show the complementary loading in the meantime
              isHidden: m.type === messageTypes.VEEZOO_LOADING
            }
          ];
        } else {
          return [m];
        }
      });

    case DISABLE_BOARD_REQUEST:
    case BOARD_REMOVE_REQUEST:
      // Remove all the board messages in the chat when a board gets deleted or disabled
      return state.filter(m => !(m.type === messageTypes.VEEZOO_BOARD_ANSWER_MESSAGE && m.boardId === action.boardId));

    // complementary (or extra / additional) message would display additonal information
    // which might be probably interesting to user
    case COMPLEMENTARY_SUCCESS:
      return flatMap(state, m => {
        // when retrieving the complementary answer replace the loading message
        if (m.type === messageTypes.VEEZOO_COMPLEMENTARY_LOADING && m.requestId === action.requestId) {
          // only add complementary if there is actually an answer attached
          if (action.message.answer && action.message.answer.visualizations.length > 0) {
            return [
              {
                ...action.message,
                isComplementary: true,
                localQuestionId: action.localQuestionId,
                complementaryForAnswerId: action.mainAnswerMessageId,
                // if the loading was hidden, stil hide the complementary
                isHidden: m.isHidden,
                feedback: feedbackStates.NONE
              }
            ];
          } else {
            return [];
          }
        } else {
          // keep the element
          return [m];
        }
      });

    case CHATMESSAGE_HIDE:
      return state.map(message => {
        // also hide the rephrase of a hidden message
        if (message.id === action.messageId || message.rephraseForLocalQuestionId === action.messageId) {
          return {
            ...message,
            type: messageTypes.USER_HIDDEN_MESSAGE,
            previousType: message.type
          };
        } else {
          return message;
        }
      });

    case CHATMESSAGE_UNHIDE:
      return state.map(message => {
        // also unhide the rephrase of a hidden message
        if (message.id === action.messageId || message.rephraseForLocalQuestionId === action.messageId) {
          return {
            ...message,
            type: message.previousType // restore the pre-hidden type
          };
        } else {
          return message;
        }
      });

    case CHATMESSAGE_LOCAL:
      if (action.requestId) {
        return flatMap(state, m => {
          // this is a very special case, we go in here if we have a "special message"
          // i.e. when veezoo says "I don't know the word xyz, but will get you an answer anyhow"
          // that gets displayed before the veezoo answer message.
          // what we do is simply, insert this message before the loading one, and have a few seconds later
          // the actual answer replace the loading message
          if (m.type === messageTypes.VEEZOO_LOADING && m.requestId === action.requestId) {
            // add a mainAnswerMessageId to the local message, so we can replace it later
            return [
              action.message,
              {
                ...m,
                mainAnswerMessageId: action.mainAnswerMessageId
              }
            ];
          } else {
            // keep the element
            return [m];
          }
        });
      } else {
        // this is the standard local message case where it simply gets appended as the last message
        return [...state, action.message];
      }

    // This block duplicates logic from partialAnswers due to complementaries and customer selections not being in partialAnswers.
    case CHATMESSAGE_VISUALIZATION_COUNT_REQUEST: {
      return state.map(item => {
        if (item.answer && item.answer.partialAnswerId === action.partialAnswerId) {
          return {
            ...item,
            answer: {
              ...item.answer,
              visualizations: item.answer.visualizations?.map(visualization => ({
                ...visualization,
                fetchingTotalCount: true
              }))
            }
          };
        }
        return item;
      });
    }

    case CHATMESSAGE_VISUALIZATION_COUNT_SUCCESS: {
      return state.map(item => {
        if (item.answer && item.answer.partialAnswerId === action.partialAnswerId) {
          return {
            ...item,
            answer: {
              ...item.answer,
              visualizations: item.answer.visualizations?.map(visualization => ({
                ...visualization,
                fetchingTotalCount: false,
                summary: replaceTotalCountPlaceholder(visualization.summary, action.rowCount)
              }))
            }
          };
        }
        return item;
      });
    }

    case CHATMESSAGE_VISUALIZATION_COUNT_FAILURE: {
      return state.map(item => {
        if (item.answer && item.answer.partialAnswerId === action.partialAnswerId) {
          return {
            ...item,
            answer: {
              ...item.answer,
              visualizations: item.answer.visualizations?.map(visualization => ({
                ...visualization,
                fetchingTotalCount: false
              }))
            }
          };
        }
        return item;
      });
    }

    case CHATMESSAGE_SET_FEEDBACK:
      // eslint-disable-next-line
      const newState = state.map(message => {
        if (message.id === action.messageId) {
          let newFeedback;

          switch (message.feedback) {
            case feedbackStates.NONE:
              action.isPositive ? (newFeedback = feedbackStates.POS) : (newFeedback = feedbackStates.NEG);
              break;

            case feedbackStates.POS:
              action.isPositive ? (newFeedback = feedbackStates.NONE) : (newFeedback = feedbackStates.NEG);
              break;

            case feedbackStates.NEG:
              action.isPositive ? (newFeedback = feedbackStates.POS) : (newFeedback = feedbackStates.NONE);
              break;

            default:
              newFeedback = feedbackStates.NONE;
              break;
          }

          return {
            ...message,
            feedback: newFeedback
          };
        } else {
          return message;
        }
      });

      return newState;

    default:
      return state;
  }
};
