import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import styles from './invert-button.scss';
import { withTranslation } from 'react-i18next';

const InvertButton = ({ className = '', isInverted = false, onClick, t }) => (
  <div className={clsx(styles.invertButton, isInverted ? styles.isInverted : '', className)} onClick={onClick}>
    <PivotTableChartIcon className={styles.invertButtonIcon} />
    {t('chart.swap-dimensions')}
  </div>
);

InvertButton.propTypes = {
  className: PropTypes.string,
  isInverted: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation('veezoo')(InvertButton);
