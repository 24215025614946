import { useEffect, useRef, memo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import NodeEditorPopover from 'components/NodeEditorPopover';
import LoadingIcon from 'components/loaders/LoadingIcon';

import { TextField } from 'components/v3';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { setEntitiesSidebar, updateEntitySidebarUniqueValue } from 'store/modules/graph/graph';
import { availableGroups, OntologyClass } from 'store/utils/knowledgeGraphOntologyIcons';
import useDebounce from 'utils/debounce';
import { trackEvent } from 'utils/eventTracking';
import services from 'services';

import Skeleton from './Skeleton';
import useStyles from './styles';

import { layouts } from 'config/constants';

const resultLimitPerAPIFetch = 50;

const formatEntitiesCount = (count, t) => {
  const singularSentence = t('entities-sidebar.unique-value-found');
  const pluralSentence = t('entities-sidebar.unique-values-found');

  if (count === 1) return `1 ${singularSentence}`;
  if (count < 1) return `0 ${pluralSentence}`;
  return `${count} ${pluralSentence}`;
};

const BottomMessage = memo(({ hasFetched, totalCount, t }) => {
  const styles = useStyles();
  if (!hasFetched) return null;

  let message = '';

  if (totalCount < 1) {
    message = t('entities-sidebar.no-unique-values-found');
  }

  if (totalCount > resultLimitPerAPIFetch) {
    message = t('entities-sidebar.all-unique-values-loaded');
  }

  return <span className={styles.bottomMessage}>{message}</span>;
});

// The "onClose" property is assigned inside the "BodyContentSidebar" component.
const EntitiesSidebar = ({ onClose, hasEntered, entitiesSidebar, dispatch, t }) => {
  const styles = useStyles();
  const listContainerRef = useRef(null);
  const inputRef = useRef(null);

  const debouncedSearchTerm = useDebounce(entitiesSidebar.inputField, 500);

  const IconComponent =
    availableGroups[entitiesSidebar.ontologyType]?.componentIcon || availableGroups[OntologyClass].componentIcon;

  const hasFetchedAllEntities =
    !entitiesSidebar.isFetching && entitiesSidebar.uniqueValues.length >= entitiesSidebar.uniqueValuesTotalCount;

  const fetchEntities = async (query = '', start = 0, keepOldValues = false) => {
    if (entitiesSidebar.preventFetch) return;

    if (entitiesSidebar.searchTerm.length > 0) {
      trackEvent('KG Entities Searched', { query: entitiesSidebar.searchTerm });
    }

    dispatch(setEntitiesSidebar({ isFetching: true }));
    const result = await services.getEntitiesByClass({
      query,
      start,
      classUri: entitiesSidebar.uri,
      limit: resultLimitPerAPIFetch
    });
    const uniqueValuesData = {};

    if (result.success) {
      uniqueValuesData.uniqueValues = [
        ...(keepOldValues ? entitiesSidebar.uniqueValues : []),
        ...(result.data.results || [])
      ];
      uniqueValuesData.uniqueValuesTotalCount = result.data.hasOwnProperty('resultCount')
        ? result.data.resultCount
        : result.data.totalCount;
    }

    dispatch(setEntitiesSidebar({ isFetching: false, ...uniqueValuesData }));
  };

  const loadMoreOptions = () => {
    if (hasFetchedAllEntities || entitiesSidebar.isFetching) return;

    const container = listContainerRef.current;
    if (container.scrollHeight - container.scrollTop - 1 <= container.clientHeight) {
      const start = entitiesSidebar.uniqueValues.length;
      fetchEntities(entitiesSidebar.searchTerm, start, true);
    }
  };

  const resetSearch = () => dispatch(setEntitiesSidebar({ preventFetch: false, forcedOpenUri: null, inputField: '' }));

  const handleInputChange = event => {
    const value = event.target.value;
    dispatch(setEntitiesSidebar({ preventFetch: false, forcedOpenUri: null, inputField: value }));
  };

  const handleEnterPress = event => {
    if (event.key === 'Enter') {
      dispatch(setEntitiesSidebar({ preventFetch: false, searchTerm: entitiesSidebar.inputField }));
    }
  };

  const handleInfoPanelClose = () => {
    inputRef.current.blur();
    dispatch(setEntitiesSidebar({ forcedOpenUri: null }));
  };

  // Whenever the "debounce" changes, we check if it is equal the current search. If not, we perform a new search.
  useEffect(() => {
    if (debouncedSearchTerm !== entitiesSidebar.searchTerm) {
      dispatch(setEntitiesSidebar({ searchTerm: debouncedSearchTerm }));
    }
  }, [debouncedSearchTerm, entitiesSidebar.searchTerm]);

  //As soon as we get a new search term, we fetch the entities;
  useEffect(() => {
    if (entitiesSidebar.uri) {
      fetchEntities(entitiesSidebar.searchTerm);
    }
  }, [entitiesSidebar.uri, entitiesSidebar.searchTerm]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{entitiesSidebar?.name || '-'}</span>
          <CloseRoundedIcon classes={{ root: styles.closeIcon }} onClick={onClose} />
        </div>
        <span className={styles.entitiesCount}>
          {entitiesSidebar.uniqueValues.length < 1 && entitiesSidebar.isFetching
            ? formatEntitiesCount('??', t)
            : formatEntitiesCount(entitiesSidebar.uniqueValuesTotalCount, t)}
        </span>
      </div>
      <div className={styles.searchContainer}>
        <TextField
          value={entitiesSidebar.inputField}
          ref={inputRef}
          layout={layouts.kgSidebar}
          startIcon={<SearchRoundedIcon />}
          endIcon={entitiesSidebar.inputField.length > 0 ? <CloseRoundedIcon onClick={resetSearch} /> : null}
          onChange={handleInputChange}
          onKeyPress={handleEnterPress}
          placeholder={`${t('search')}...`}
          title={`${t('search')}...`}
        />
      </div>
      <div className={styles.listContainer} onScroll={loadMoreOptions} ref={listContainerRef}>
        <ul className={styles.entitiesList}>
          {entitiesSidebar.uniqueValues.length < 1 && entitiesSidebar.isFetching
            ? [...Array(8)].map((_, index) => (
                <li key={index} className={styles.entitiesListItem}>
                  <Skeleton variant="rectangular" width="100%" height={20} />
                </li>
              ))
            : entitiesSidebar.uniqueValues.map((entity, index) => (
                <NodeEditorPopover
                  key={`${entity.uri}_${index}`}
                  uniqueId={`${entity.uri}_${index}`}
                  PopoverProps={{
                    button: (
                      <li className={styles.entitiesListItem}>
                        <span>{entity.name}</span>
                      </li>
                    ),
                    enableHover: true,
                    preventOpening: entitiesSidebar.isFetching,
                    transitionDuration: 0,
                    buttonSelectedClassName: styles.entitieslistItemHover,
                    forceOpen: hasEntered && entity.uri === entitiesSidebar.forcedOpenUri,
                    onClosed: handleInfoPanelClose
                  }}
                  ContentProps={{
                    node: entity,
                    subtitleIcon: IconComponent,
                    subtitleText: entitiesSidebar?.name,
                    nodeType: entity.type,
                    updateNode: updateEntitySidebarUniqueValue,
                    expandedByDefault: true
                  }}
                />
              ))}
        </ul>
        <div className={styles.loadingContainer}>
          {entitiesSidebar.uniqueValues.length > 0 && entitiesSidebar.isFetching && (
            <LoadingIcon size={20} color="white" />
          )}
          <BottomMessage hasFetched={hasFetchedAllEntities} totalCount={entitiesSidebar.uniqueValuesTotalCount} t={t} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ entitiesSidebar: state.graph.entitiesSidebar });

export default withTranslation('veezoo')(connect(mapStateToProps)(memo(EntitiesSidebar)));
