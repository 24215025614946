import { useRef } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { useStyles } from './ChatMessageMarkdownHtml.styles';
import { useSpecialLinkHandler } from 'components/message/commonFunctions';

// a special markdown HTML container for chat messages which supports running chat-specific actions
// when clicking special hyperlinks (e.g., asking a suggested question)
const ChatMessageMarkdownHtml = ({ username, html, dispatch, t }) => {
  const containerRef = useRef(null);
  const { classes } = useStyles();
  useSpecialLinkHandler(containerRef, username, dispatch, html, t);

  return <div ref={containerRef} className={classes.root} dangerouslySetInnerHTML={{ __html: html }} />;
};

const mapStateToProps = state => ({
  username: state.user.username
});

export default withTranslation('veezoo')(connect(mapStateToProps)(ChatMessageMarkdownHtml));
