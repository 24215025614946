import React, { useEffect } from 'react';
import KnowledgeGraph from 'components/knowledgeGraph/KnowledgeGraph';
import { connect } from 'react-redux';
import Stepper from 'components/Signup/Stepper';
import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';
import Title from 'components/Signup/Title';
import signupStyles from 'pages/Signup/signup.styles.scss';
import { fetchKnowledgeGraphMeta } from 'store/modules/graph/knowledgeGraphMeta';
import { fetchGraph, fetchGraphLayout } from 'store/modules/graph/graph';
import { fetchLanguages } from 'store/modules/user';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'components/v3';
import useMediaQuery from 'utils/mediaQueries';
import { fetchBadNameProblems } from 'store/modules/recipes';
import { layouts } from 'config/constants';

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& #searchBlock': {
      marginLeft: -20
    }
  },
  buttonContainer: {
    marginTop: -40
  }
});

const ConfigureKnowledgeGraph = ({ setStep, language, knowledgeGraphMeta, graph, dispatch }) => {
  const styles = useStyles();

  const isMobile = useMediaQuery();

  // retrieves the data / state required for the visual KG
  useEffect(() => {
    dispatch(fetchKnowledgeGraphMeta());
    dispatch(fetchGraph());
    dispatch(fetchGraphLayout());

    // required s.t. the state sets a current language, which in turn is required to retrieve recipes
    dispatch(fetchLanguages());
  }, []);

  // retrieves the recipes as soon as a current language is set
  useEffect(() => {
    if (language) {
      dispatch(fetchBadNameProblems(language));
    }
  }, [language]);

  return (
    <>
      <StepperContainer
        customContainerStyle={signupStyles.customContainer}
        customStepper={<Stepper step={4} totalSteps={5} />}
      >
        <div className={styles.container}>
          <div>
            <Title>Configure your Knowledge Graph</Title>
            <div className={signupStyles.subTitle} style={{ marginBottom: 20 }}>
              Configure your new Knowledge Graph by improving the names of the concepts and creating relationships. You
              can also edit this later.
            </div>
          </div>
          <KnowledgeGraph
            graph={graph}
            // hide Studio links to prevent users from landing in Studio during signup
            hideStudioLink
            // hide search to keep it simple in first version (and because it needs some adaptations to work)
            hideSearchArea
            // hide the entities search because it opens the KG sidebar, which isn't rendered at this point
            hideEntitiesSearch
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => setStep(step => step + 1)}
            layout={layouts.signup}
            mode="dark"
            size={isMobile ? 'mobile' : ''}
            data-test="configureKgContinue"
          >
            Continue
          </Button>
        </div>
      </StepperContainer>
    </>
  );
};

const mapStateToProps = state => ({
  knowledgeGraphMeta: state.knowledgeGraphMeta,
  graph: state.graph,
  language: state.user.language
});

export default connect(mapStateToProps)(ConfigureKnowledgeGraph);
