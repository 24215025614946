import { hasCustomGroup } from 'store/utils/knowledgeGraphOntologyIcons';

const calculateNodeSize = size => size * 2.1 + 22;

const getVisualNodeLayout = (node, visualNodeLayouts) => {
  return visualNodeLayouts?.find(n => n.uri === node.uri);
};

export const setEdgeStructure = edges => {
  // first we need to group the edges in buckets by their from - to combination for layouting (1-0 and 0-1 are the same group)
  const edgeGroups = edges.reduce((acc, edge) => {
    // we always start from the smaller node (from or to) to the bigger one
    const key = edge.from < edge.to ? `${edge.from}-${edge.to}` : `${edge.to}-${edge.from}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(edge);
    return acc;
  }, {});

  // now that we have the groups conneting the same nodes, we can go through each group and create the edge structure
  return Object.values(edgeGroups)
    .map(edgeGroup => {
      // if it's odd, we draw the first edge straight
      const hasOddNumberOfEdges = edgeGroup.length % 2 !== 0;

      return edgeGroup.map((edge, index) => {
        const showEdgeName = edge.shouldShowEdgeName;
        const baseCurve = 0.1;
        // the curve strength increases every 2 edges (in the odd case, the first edge is straight)
        const curve = baseCurve + Math.floor((index - (hasOddNumberOfEdges ? 1 : 0)) / 2) * baseCurve;

        // we alternate the curve direction
        let edgeType = index % 2 === 0 ? 'curvedCW' : 'curvedCCW';
        // Because we talk clockwise and counter-clockwise, we might need to flip the edge type otherwise the edges would overlap
        // e.g. if we have one edge from 0->1 and one from 1->0
        const needToFlipEdgeType = edge.from > edge.to;
        if (needToFlipEdgeType) {
          if (edgeType === 'curvedCW') {
            edgeType = 'curvedCCW';
          } else {
            edgeType = 'curvedCW';
          }
        }

        let curvedEdgeSettings = { enabled: edgeGroup.length > 1, type: edgeType, roundness: curve };
        // for the first edge, if we have an odd number of edges, we need to make it straight
        if (index === 0 && hasOddNumberOfEdges) {
          curvedEdgeSettings = { enabled: false };
        }

        return {
          ...edge,
          label: showEdgeName && (edge.link.name || ''),
          arrows: { to: { enabled: showEdgeName, type: 'triangle', scaleFactor: 1 } },
          arrowStrikethrough: true,
          font: {
            align: 'top',
            strokeWidth: 0
          },
          endPointOffset: {
            from: 50,
            to: 50
          },
          smooth: curvedEdgeSettings
        };
      });
    })
    .flat();
};

export const setNodeStructure = (nodes, visualNodeLayouts, suggestionsForBadNames) =>
  nodes.map(node => {
    const visualNodeLayout = getVisualNodeLayout(node, visualNodeLayouts);
    const hasSuggestion = Object.keys(suggestionsForBadNames).includes(node.uri);
    let label = node.name || '';
    // vertical offset to compensate for the label being multiline if there is a suggestion for the node
    let vadjust = 0;
    // if there is a suggestion, we "hack" the label s.t. we can render a notification dot and an underline:
    //   - we use a large ASCII dot as the notification dot
    //   - we simulate an underline by repeating small ASCII dots to the approximate length of the label
    //   - there is no support for CSS: in order to style the notification dot and underline, we can only use the few
    //     available HTML tags and then style those via the different 'font' styles below (e.g. 'mono', 'bold' etc.)
    //   - cf. https://github.com/almende/vis/pull/2385 and https://visjs.github.io/vis-network/docs/network/nodes.html#
    if (hasSuggestion) {
      label +=
        // add a notification dot at the end of the label (styled via 'font.mono' below)
        '<code> ●</code>' +
        // on a new line...
        '\n' +
        // ...add a dotted line below the label, trying to match the width of the label (styled via 'font.bold' below)
        '<b>• </b>'.repeat(label.length * 2) +
        // ...followed by a space matching the width of the notification dot, which we don't want underlined (styled via 'font.boldital' below)
        '<b><i> </i></b>';
      vadjust = -8;
    }
    // this is a duplicate of --smart-fixes-color
    const smartFixesColor = '#ffd400';
    return {
      ...node,
      label: label,
      size: calculateNodeSize(visualNodeLayout?.size || 1),
      group: hasCustomGroup(node.ontologyType),
      x: visualNodeLayout?.x,
      y: visualNodeLayout?.y,
      fixed: false,
      borderWidth: 0,
      font: {
        multi: 'html',
        vadjust: vadjust,
        // styles for the notification dot (enclosed in '<code></code>')
        mono: {
          color: smartFixesColor,
          size: 10,
          vadjust: 3 + vadjust
        },
        // styles for the dotted line (enclosed in '<b></b>')
        bold: {
          color: smartFixesColor,
          size: 6,
          vadjust: -2 + vadjust
        },
        // size determining the width of the space at the end of the dotted line (enclosed in '<b><i></></b>')
        boldital: {
          size: 30
        }
      }
    };
  });

// if we don't get any positions from the backend (e.g. due to a timeout),
// we give our best by using physics to let the nodes spread out
export const shouldUsePhysics = visualNodeLayouts => {
  return visualNodeLayouts.length === 0;
};
