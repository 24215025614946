import { PureComponent } from 'react';
import { boundMethod } from 'autobind-decorator';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { messageTypes } from 'store/modules/chat-messages';
import { finishedTutorial } from 'store/modules/tutorial';
import Title from 'components/tutorial/Title';
import scrollElementIntoVisibleArea from 'utils/scroll-element-into-visible-area';
import { PeopleGroupIcon } from '../Icons';
import { tutorialNames } from 'config/constants';
import { saveModes } from '../../store/modules/chat-messages';

const tutorialId = tutorialNames.ADD_TO_CUSTOMER_SELECTION_TUTORIAL;

const propTypes = {
  hasAnswerMessage: PropTypes.bool,
  isAnswerIncludesCustomerSelectionSaveMode: PropTypes.bool,
  isAnswerTutorialUncompleted: PropTypes.bool,
  uncompletedTutorials: PropTypes.array,
  generalOptions: PropTypes.object
};
export class AddToCustomerSelectionTutorial extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      runTutorial: false
    };
  }

  @boundMethod
  callback(tour) {
    const { action, type } = tour;
    const element = document.querySelector(tour.step.target);
    scrollElementIntoVisibleArea(element);

    if (type === EVENTS.TOUR_END) {
      const isCompleted = action !== ACTIONS.SKIP;
      this.props.dispatch(finishedTutorial(tutorialId, isCompleted));
    }
  }

  componentDidUpdate(prevProps) {
    const { isAnswerIncludesCustomerSelectionSaveMode, isAnswerTutorialUncompleted, uncompletedTutorials } = this.props;
    const { runTutorial } = this.state;
    // CustomerSelectionQuestionTutorial will run only once after Answer message with saveModes will appear
    // and only if FirstAnswerTutorial didn't run
    if (
      !runTutorial &&
      isAnswerIncludesCustomerSelectionSaveMode &&
      !isAnswerTutorialUncompleted &&
      uncompletedTutorials.some(tutorial => tutorial.id === tutorialId)
    ) {
      this.setState({
        runTutorial: true
      });
    }
  }

  render() {
    const { runTutorial } = this.state;
    const { t, generalOptions } = this.props;

    const steps = [
      // step 0
      {
        target: 'body',
        disableBeacon: true,
        placement: 'center',
        locale: { next: 'Start' },
        title: <Title text={t('tutorial.add-to-customer-selection.start-title')} icon={<PeopleGroupIcon />} />,
        content: t('tutorial.add-to-customer-selection.start-desc')
      },
      // step 1
      {
        target: '[data-display-tutorial] [data-answer-tutorial="save-mode"]',
        disableBeacon: true, // need to disable icon for every single step
        placement: 'left',
        title: t('tutorial.add-to-customer-selection.save-mode-title'),
        content: t('tutorial.add-to-customer-selection.save-mode-desc')
      }
    ];

    // IMPORTANT! Correct scrolling to target element doesn't work with upgraded version 2.1.1
    return (
      <Joyride
        {...generalOptions}
        ref="joyride"
        steps={steps}
        run={runTutorial} // or some other boolean for when you want to start it
        callback={this.callback}
      />
    );
  }
}

AddToCustomerSelectionTutorial.propTypes = propTypes;

export function mapStateToProps(state) {
  return {
    isAnswerIncludesCustomerSelectionSaveMode: state.chatMessages.some(
      message =>
        message?.answer?.saveModes?.includes(saveModes.CUSTOMER_SELECTION) && // means that saveModes consist ["BOARD", "CUSTOMER_SELECTION"]
        message?.type === messageTypes.VEEZOO_ANSWER_MESSAGE
    ),
    uncompletedTutorials: state.tutorial.uncompletedTutorials,
    isAnswerTutorialUncompleted: state.tutorial.uncompletedTutorials.some(
      tutorial => tutorial.id === tutorialNames.FIRST_ANSWER_TUTORIAL
    )
  };
}

export default withTranslation('veezoo')(connect(mapStateToProps)(AddToCustomerSelectionTutorial));
