import { makeStyles } from '@material-ui/core/styles';

export const useListItemStyles = makeStyles({
  root: {
    paddingTop: '0',
    paddingBottom: '0'
  }
});

export const useListItemTextStyles = makeStyles({
  primary: {
    fontSize: ({ isMobile }) => (isMobile ? '12px' : '14px')
  },
  secondary: {
    fontSize: '12px',
    color: 'var(--normal-gray)'
  }
});

// Styles for the texts in the tree in the left panel
export const useTypographyItemStyles = makeStyles({
  // Main text
  body1: {
    fontSize: ({ isMobile }) => (isMobile ? '12px' : '14px'),
    whiteSpace: 'nowrap',
    marginRight: '10px'
  },
  // Secondary text put to the right
  body2: {
    fontSize: '12px',
    color: 'var(--normal-gray)',
    marginLeft: 'auto',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
});

export const useCheckboxStyles = makeStyles({
  colorSecondary: {
    padding: '6px',
    '&& .MuiSvgIcon-root': {
      fontSize: ({ isMobile }) => (isMobile ? '15px' : '20px')
    },
    '&&.Mui-checked': { color: ({ checkboxColor }) => (checkboxColor ? checkboxColor : '') }
  }
});

export const useListItemIconStyles = makeStyles({
  root: {
    minWidth: ({ isMobile }) => (isMobile ? '15px' : '25px')
  }
});
