import { withTranslation } from 'react-i18next';

import Button from 'components/buttons/Button';
import PropTypes from 'prop-types';
import { forwardRef, useContext } from 'react';
import ReactToPrint from 'react-to-print';
import Dropdown from 'react-bootstrap/Dropdown';
import { MessageListRefContext } from 'components/chat/MessageListRefContext';
import { connect } from 'react-redux';

import { InfoTooltip } from 'components/v3';

import useMediaQuery from 'utils/mediaQueries';

const CustomDropdownToggle = (text, tooltipText) =>
  forwardRef(({ children, onClick }, ref) => {
    const isMobile = useMediaQuery();
    return (
      <InfoTooltip text={tooltipText}>
        <Button
          className="button-secondary"
          ref={ref}
          text={text}
          showOnlyIcon={isMobile}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
          data-test="printButton"
        >
          {children}
        </Button>
      </InfoTooltip>
    );
  });

const PrintButton = ({ t, targetRef }) => {
  const isMobile = useMediaQuery();
  const printChatButton = <Button className="button-secondary" text={t('print.chat')} data-test="printChatButton" />;
  const context = useContext(MessageListRefContext);
  const printMessageButton = (
    <Button
      className="button-secondary"
      showOnlyIcon={isMobile}
      text={t('print.message')}
      data-test="printMessageButton"
    />
  );

  return (
    <Dropdown drop="up" role="menuitem" className="dropup-button-in-message">
      <Dropdown.Toggle as={CustomDropdownToggle(t('print.button'), t('tooltip.print'))}>
        <span className="icon-printer" data-test="printButton" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <ReactToPrint
          trigger={() => (
            <Dropdown.Item eventKey="2" as="button">
              {printChatButton}
            </Dropdown.Item>
          )}
          content={() => context?.current}
        />
        <ReactToPrint
          trigger={() => (
            <Dropdown.Item eventKey="1" as="button">
              {printMessageButton}
            </Dropdown.Item>
          )}
          content={() => targetRef && targetRef.current}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default withTranslation('veezoo')(connect(null)(PrintButton));

PrintButton.propTypes = {
  targetRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  dispatch: PropTypes.func,
  t: PropTypes.func
};
