export const vqlLanguageId = 'vql';

export const vqlMonarchLanguageDefinition = {
  // Default token
  // defaultToken: 'invalid',

  // Keywords
  keywords: ['var', 'by', 'across', 'union', 'not', 'true', 'false', 'this'],

  // Functions
  functions: [
    'select',
    'sum',
    'average',
    'count',
    'max',
    'min',
    'date',
    'dynamic_date',
    'singleton',
    'median',
    'growth',
    'proportion',
    'top',
    'bottom',
    'contains_string',
    'starts_with_string',
    'ends_with_string',
    'first',
    'last',
    'to_date',
    'periodicity'
  ],

  // Type keywords
  typeKeywords: ['kb', 'cb'],

  // Operators
  operators: ['=', '>', '<', '>=', '<=', '!=', ':', '+', '-', '*', '/'],

  // Symbols
  // eslint-disable-next-line no-useless-escape
  symbols: /[=><!~?:&|+\-*\/\^%]+/,

  // Escapes
  escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

  // Tokenizer
  tokenizer: {
    root: [
      // Fully qualified paths like kb.Order or kb.Order.Returned
      [/(kb)(\.([A-Za-z_][A-Za-z0-9_]*))+/, 'type.identifier'],

      // Function calls with arguments
      [
        /([a-zA-Z_][a-zA-Z0-9_]*)(\()/,
        [
          { token: 'function' }, // Function name
          { token: 'delimiter.parenthesis' } // Open parenthesis
        ]
      ],

      // Neutral handling for dots and commas
      [/[.,]/, ''],

      // Keywords, functions, and identifiers
      [
        /[a-zA-Z_]\w*/,
        {
          cases: {
            '@keywords': 'keyword',
            '@functions': 'function',
            '@default': 'identifier'
          }
        }
      ],

      // Whitespace
      { include: '@whitespace' },

      // Delimiters and operators
      // eslint-disable-next-line no-useless-escape
      [/[{}()\[\]]/, '@brackets'],
      [/@symbols/, { cases: { '@operators': 'operator', '@default': '' } }],
      [/[<>](?!@symbols)/, '@brackets'],

      // Numbers
      // eslint-disable-next-line no-useless-escape
      [/\d*\.\d+([eE][\-+]?\d+)?/, 'number.float'],
      [/\d+/, 'number'],

      // String literals
      [/"([^"\\]|\\.)*$/, 'string.invalid'], // Non-terminated string
      [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }]
    ],

    // String states
    string: [
      [/[^\\"]+/, 'string'],
      [/@escapes/, 'string.escape'],
      [/\\./, 'string.escape.invalid'],
      [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }]
    ],

    comment: [[/[^#]+/, 'comment'], [/#/, 'comment']],

    // Whitespace
    whitespace: [[/[ \t\r\n]+/, 'white'], [/#.*$/, 'comment']]
  }
};

export const vqlTheme = {
  base: 'vs', // Use a light theme as the base
  inherit: true,
  rules: [
    { token: 'keyword', foreground: '0000ff' },
    { token: 'function', foreground: '008080' },
    { token: 'type.identifier', foreground: '7c3ad5' },
    { token: 'identifier', foreground: '000000' },
    { token: 'number', foreground: '6897bb' },
    { token: 'string', foreground: 'e91e63' },
    { token: 'operator', foreground: '000000' },
    { token: 'delimiter.bracket', foreground: '000000' },
    { token: 'delimiter.parenthesis', foreground: '000000' },
    { token: 'delimiter.square', foreground: '000000' },
    { token: 'delimiter.curly', foreground: '000000' }
  ],
  colors: {
    'editor.foreground': '#333333' // Neutral dark gray for text
  }
};
