import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import UITextField from '@mui/material/TextField';
import UIInputAdornment from '@mui/material/InputAdornment';

import { useTextFieldStyles } from './TextField.styles';

import { InfoTooltip } from 'components/v3';

import useMediaQuery from 'utils/mediaQueries';
import { layouts } from 'config/constants';

const TextField = forwardRef(
  (
    {
      startIcon,
      endIcon,
      label,
      description,
      value,
      onChange,
      disabledWhenFilled,
      disabled,
      inline,
      shrink,
      required,
      tooltip,
      placeholder,
      multiline,
      title = 'Text Field',
      name = 'textfield',
      layout = layouts.default,
      customClasses = {},
      InputProps = {},
      highlighted = false, // font gets a light-blue color, we use it on Signup when KG already exists, giving a blue color to the KG name.
      ...props
    },
    ref
  ) => {
    const isMobile = useMediaQuery();

    const textFieldClasses = useTextFieldStyles({
      isMobile,
      disabled,
      disabledWhenFilled,
      multiline,
      inline,
      highlighted,
      tooltip,
      customClasses
    });

    const customProps = useMemo(() => {
      const fields = {
        layout: textFieldClasses[layout],
        variant: 'outlined',
        size: 'small',
        autoComplete: ''
      };

      if (layout === layouts.signup) {
        fields.variant = 'standard';
        fields.autoComplete = 'new-password';
        delete fields.size;
      }

      if (layout === layouts.veezoo) {
        fields.variant = 'outlined';
        fields.autoComplete = 'new-password';
        delete fields.size;
      }

      return fields;
    }, [layout, highlighted]);

    const shouldShrink = useMemo(() => !!placeholder || !!tooltip || shrink, [
      placeholder,
      tooltip,
      layout,
      shrink,
      label
    ]);

    const iconFontSize = useMemo(() => {
      if (layout === layouts.signup) return '1.35rem';
      return '0.85rem';
    }, [layout]);

    const buildHelperText = useMemo(() => {
      const text = description ? <span>{description}</span> : <></>;
      const space = description && tooltip ? <>&nbsp;</> : '';
      const infoTooltip = tooltip ? (
        <InfoTooltip layout={layout} text={tooltip} size={iconFontSize} clickableWhenMobile={isMobile} />
      ) : (
        <></>
      );
      return (
        <>
          {infoTooltip}
          {space}
          {text}
        </>
      );
    }, [isMobile, layout, description, tooltip, iconFontSize]);

    const setAdornment = (icon, position) => <UIInputAdornment position={position}>{icon}</UIInputAdornment>;

    return (
      <UITextField
        {...(label
          ? {
              label: <>{`${label} ${required ? '*' : ''}`}</>
            }
          : {})}
        sx={customProps.layout}
        fullWidth={!inline}
        name={name}
        disabled={disabled || disabledWhenFilled}
        variant={customProps.variant}
        size={customProps.size}
        multiline={multiline}
        autoComplete={customProps.autoComplete}
        inputProps={{ ref, title }}
        InputProps={{
          startAdornment: !multiline && startIcon ? setAdornment(startIcon, 'start') : null,
          endAdornment: !multiline && endIcon ? setAdornment(endIcon, 'end') : null,
          ...InputProps
        }}
        InputLabelProps={{
          ...(shouldShrink ? { shrink: shouldShrink } : {})
        }}
        {...(description || tooltip ? { helperText: buildHelperText } : {})}
        {...(value === undefined ? {} : { value })}
        {...(onChange === undefined ? {} : { onChange })}
        {...(placeholder ? { placeholder } : {})}
        {...props}
      />
    );
  }
);

export default TextField;

TextField.propTypes = {
  name: PropTypes.string,
  startIcon: PropTypes.any,
  endIcon: PropTypes.any,
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabledWhenFilled: PropTypes.bool,
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  layout: PropTypes.string,
  inline: PropTypes.bool,
  shrink: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  customClasses: PropTypes.object,
  InputProps: PropTypes.object
};
