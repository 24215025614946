import { withTranslation } from 'react-i18next';

import Button from 'components/buttons/Button';
import { veezooRoutes } from 'components/app/routes.js';

import useMediaQuery from 'utils/mediaQueries';
import KnowledgeGraphIcon from 'svg/knowledge-graph.svg';

import { InfoTooltip } from 'components/v3';

import styles from './button.scss';
import { LinkWithQuery } from 'components/LinkWithQuery';

const KnowledgeGraphButton = ({ t }) => {
  const isMobile = useMediaQuery();

  return (
    <LinkWithQuery to={veezooRoutes.knowledgeGraph}>
      <InfoTooltip text={t('tooltip.open-kg')}>
        <Button className="button-secondary" text={t('tooltip.open-kg')} showOnlyIcon={isMobile}>
          <KnowledgeGraphIcon className={styles.iconColor} />
        </Button>
      </InfoTooltip>
    </LinkWithQuery>
  );
};

export default withTranslation('veezoo')(KnowledgeGraphButton);
