import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { Paper } from '@material-ui/core';
import MarkdownHtml from 'studio/components/MarkdownHtml/MarkdownHtml';

import { useAutocompleteTooltipStyles, useTooltipStyles } from './styles';

const Q2QTooltip = forwardRef(
  ({ children, title, description, placement, tooltipForAutocomplete, open, ...rest }, ref) => {
    const tooltipClasses = tooltipForAutocomplete ? useAutocompleteTooltipStyles() : useTooltipStyles();

    /* The description set in the span is HTML sanitized by the backend */
    const tooltipContent = (
      <Paper elevation={5} className={tooltipClasses.paper}>
        <h4>{title}</h4>
        <MarkdownHtml html={description} />
      </Paper>
    );

    return (
      <Tooltip
        ref={ref}
        title={tooltipContent}
        placement={placement}
        classes={{ tooltip: tooltipClasses.tooltip }}
        interactive
        // if the "open" attribute comes specified from props, it is validated manually here. otherwise it is validated
        // automatically by material-ui's tooltip and doesn't need to be specified;
        // Example: it happens automatically for <PassageSpan /> because it is not present on props,
        // but is validated manually on <DescriptionProps /> because we have added a "prop" validation to it
        // (check the "shouldOpen" validation inside <DescriptionProps />)
        // In fact it wouldn't even need to be specified here at all
        // since this validation would be automatically passed down by {...rest},
        // but we'll specify anyway to make it easier to understand.
        {...(open ? { open } : {})}
        {...rest}
      >
        {children}
      </Tooltip>
    );
  }
);

export default Q2QTooltip;

Q2QTooltip.propTypes = {
  children: PropTypes.any,
  tooltipForAutocomplete: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placement: PropTypes.string
};

Q2QTooltip.defaultProps = {
  title: '',
  description: '',
  placement: 'top'
};
