import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import HelpOutlineIcon from '@mui/icons-material/HelpOutlineOutlined';
import UITooltip from '@mui/material/Tooltip';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import { layouts } from 'config/constants';

import useMediaQuery from 'utils/mediaQueries';

import { useIconStyles, useTooltipStyles, useTooltipContainerStyles } from './InfoTooltip.styles';

const InfoTooltip = forwardRef(
  ({ children, text, layout, placement, clickableWhenMobile, helpOverrideStyles, size, ...rest }, ref) => {
    const isMobile = useMediaQuery();

    const tooltipClasses = useTooltipStyles();
    const helpClasses = useIconStyles({ isMobile, layout, size, helpOverrideStyles });
    const { classes: tooltipContainerClasses } = useTooltipContainerStyles();

    const [open, setOpen] = useState(false);

    const tooltipComponent = (
      <UITooltip
        // "sx" doesn't work for Tooltip balloon, only for root component. In order to reach the balloon we have to use SlotProps.
        slotProps={{ tooltip: { sx: tooltipClasses } }}
        ref={ref}
        title={text}
        placement={placement}
        arrow
        TransitionProps={{ timeout: 0 }}
        {...(clickableWhenMobile ? { open } : {})}
        {...rest}
      >
        {children || <HelpOutlineIcon sx={helpClasses} />}
      </UITooltip>
    );

    if (clickableWhenMobile)
      return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className={isMobile ? tooltipContainerClasses.root : ''} onClick={() => setOpen(!open)}>
            {tooltipComponent}
          </div>
        </ClickAwayListener>
      );

    return tooltipComponent;
  }
);

export default InfoTooltip;

InfoTooltip.propTypes = {
  children: PropTypes.any,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  layout: PropTypes.string,
  placement: PropTypes.string,
  clickableWhenMobile: PropTypes.bool,
  helpOverrideClasses: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helpOverrideStyles: PropTypes.object
};

InfoTooltip.defaultProps = {
  text: '',
  placement: 'top',
  clickableWhenMobile: false,
  layout: layouts.default,
  helpOverrideStyles: {}
};
