import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { InfoTooltip } from 'components/v3';

import Button from 'components/buttons/Button';

import useMediaQuery from 'utils/mediaQueries';

const StopSharingButton = ({ id, tooltipText, t, stopSharingAction }) => {
  const isMobile = useMediaQuery();

  return (
    <InfoTooltip text={tooltipText}>
      <Button
        data-test="shareButton"
        className="button-secondary"
        onClick={() => stopSharingAction(id)}
        showOnlyIcon={isMobile}
        text={t('sharing.stop')}
      >
        <span className="icon-stop-sharing" />
      </Button>
    </InfoTooltip>
  );
};

export default withTranslation('veezoo')(StopSharingButton);

StopSharingButton.propTypes = {
  id: PropTypes.string, // the id of the shareable object, e.g. boardId or customerSelectionId
  stopSharingAction: PropTypes.func, // the action taking id as parameter, which stops sharing the item of this id
  tooltipText: PropTypes.string, // translated text for the tooltip for a shareable object, e.g. t("tooltip.stop-sharing-board")
  t: PropTypes.func,
  dispatch: PropTypes.func
};
