import { useMemo, createRef, memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { collapseMiniWidget } from 'store/modules/expandedMiniWidgets';
import AnswerMessage from './AnswerMessage';
import BaseMessage from './BaseMessage';
import MultiAnswerMessageFooter from './MultiAnswerMessageFooter';
import AnswerMessageHeader from './AnswerMessageHeader';

import styles from './answer-message.scss';
import useMediaQuery from '../../utils/mediaQueries';
import Alert from 'studio/components/Alert';
import { ArrowBack } from '@material-ui/icons';
const MultiAnswerPureMessage = ({
  filters,
  message,
  baseMessageClasses,
  answerId,
  expandedMiniWidget,
  miniBoard,
  title,
  dispatch,
  messageId,
  referenceTo,
  addToBoardCallback,
  scrollTo,
  localQuestionId,
  customFooter,
  summary,
  questionId,
  t
}) => {
  const messageRef = createRef();
  const isMobile = useMediaQuery();
  const expandedMiniWidgetHasAnswer = expandedMiniWidget && expandedMiniWidget.answer;

  const expandedAnswer = useMemo(() => {
    if (expandedMiniWidgetHasAnswer) {
      const customHeader = (
        <Alert inAnswerHeader={true} icon={false}>
          <span data-test="expandedAnswerTitle">
            <span className={styles.linkedBoardName} onClick={() => dispatch(collapseMiniWidget(messageId))}>
              <span className={styles.customHeader}>
                <ArrowBack />
              </span>
              <b>{t('back') + ' • '}</b>
            </span>
            {expandedMiniWidget.title}
          </span>
        </Alert>
      );

      return (
        <AnswerMessage
          answer={expandedMiniWidget.answer}
          initialActiveChartIndex={expandedMiniWidget.activeChartIndex}
          initialChartIsInverted={expandedMiniWidget.isChartInverted}
          customHeader={customHeader}
          // we have now a custom title, so display the filters in the original title as well
          customFilters={expandedMiniWidget.answer.filters}
          answerId={answerId}
          id={messageId}
          referenceTo={referenceTo}
          localQuestionId={localQuestionId}
          addToBoardCallback={addToBoardCallback}
          scrollTo={scrollTo}
          timestamp={message.timestamp}
          isMobile={isMobile}
        />
      );
    }
  }, [
    expandedMiniWidgetHasAnswer,
    expandedMiniWidget?.answer,
    expandedMiniWidget?.answer?.filters,
    expandedMiniWidget?.title,
    expandedMiniWidget?.activeChartIndex,
    messageId,
    answerId,
    referenceTo,
    localQuestionId,
    addToBoardCallback,
    scrollTo,
    message?.timestamp,
    dispatch
  ]);

  // whenever we expand the mini widget and it has an answer we want to scroll to it
  useEffect(() => {
    if (expandedMiniWidgetHasAnswer) {
      scrollTo(messageId);
    }
  }, [expandedMiniWidgetHasAnswer, messageId, scrollTo]);

  const messageContent = (
    <div className={styles.wrap}>
      <div>
        <AnswerMessageHeader
          structuredTitle={title}
          filters={filters}
          answerData={message}
          answerId={answerId}
          id={messageId}
          referenceTo={referenceTo}
          localQuestionId={localQuestionId}
          isMobile={isMobile}
        />
        {summary && <p className={styles.pendingBoard}>{summary}</p>}
        {miniBoard}
      </div>
      <div className={styles.placeholder} />
    </div>
  );

  const footer = useMemo(() => {
    if (customFooter) return customFooter;
    return <MultiAnswerMessageFooter answerId={answerId} messageId={messageId} t={t} targetRef={messageRef} />;
  }, [customFooter, answerId, messageId, t, messageRef]);

  const miniBoardContent = (
    <div
      ref={messageRef}
      id="VeezooAnswer" // note this id here is set so that our hack in Home.js can find it
      data-answer-id={answerId}
      data-question-id={questionId}
    >
      <BaseMessage
        showVeezooIcon
        enableStandardFooter
        baseMessageClasses={baseMessageClasses}
        content={messageContent}
        questionId={questionId}
        localQuestionId={localQuestionId}
        customFooter={footer}
        isFollowUp={!!referenceTo}
        timestamp={message.timestamp}
        feedback={message.feedback}
        messageId={messageId}
        answerId={answerId}
        // enable retries and feedback for multi-answers associated with a question (in particular, multi-answer follow-ups)
        enableRetry={!!questionId}
        enableFeedback={!!questionId}
      />
    </div>
  );

  return expandedMiniWidgetHasAnswer ? expandedAnswer : miniBoardContent;
};

export default connect()(memo(MultiAnswerPureMessage));
