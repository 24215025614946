import { tss } from 'tss-react';

import { layouts } from 'config/constants';

export const useTooltipContainerStyles = tss.create({
  root: {
    display: 'inline-block'
  }
});

export const useTooltipStyles = () => ({
  padding: '6px 12px !important',
  backgroundColor: 'rgba(97, 97, 97, 0.95) !important',
  fontSize: '14px !important',
  fontWeight: 'normal !important',
  color: 'white !important',
  letterSpacing: '0.3px !important'
});

export const useIconStyles = ({ layout, size, helpOverrideStyles }) => {
  let fontSize = '11px';

  if (size) {
    fontSize = size;
  } else if (layout === layouts.signup) {
    fontSize = '14px';
  } else if (layout === layouts.veezoo) {
    fontSize = '15px';
  }

  return {
    '&.MuiSvgIcon-root': {
      fontSize,
      color: layout === layouts.signup ? 'var(--light-blue)' : 'inherit',
      verticalAlign: 'sub',
      marginLeft: '4px',
      ...(helpOverrideStyles.root ? helpOverrideStyles.root : {})
    }
  };
};
