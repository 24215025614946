import React, { memo, useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import classnames from 'classnames';
import ChatBoardWidget from './ChatBoardWidget';
import { ChatActiveContext } from 'components/chat/ChatActiveContext';
import { Responsive } from 'react-grid-layout';

import styles from './mini-board.scss';
import { connect } from 'react-redux';
import Share from '@mui/icons-material/Share';
import { publishBoardChanges, updateEditBoardState } from 'store/modules/board';

import { Button } from 'components/v3';

import useMediaQuery from 'utils/mediaQueries';
import { dispatchResizeEvent } from 'components/split/SplitSidebarView';
import CustomWidthProvider from 'components/board/CustomWidthProvider';

import { layouts as muiLayouts } from 'config/constants';

require('react-grid-layout/css/styles.css');
require('react-resizable/css/styles.css');

const ResponsiveGridLayout = CustomWidthProvider(Responsive);

const isChatActive = ChatActiveContext;

// define an order on widgets with row being the primary sort key and column the secondary one
const compareWidgets = (a, b) => {
  if (a.row < b.row) {
    return -1;
  }
  if (a.row > b.row) {
    return 1;
  }
  if (a.column < b.column) {
    return -1;
  }
  if (a.column > b.column) {
    return 1;
  }
  return 0;
};

const layoutFromMetaAnswers = metaAnswers =>
  metaAnswers.map(metaWidget => ({
    i: metaWidget.id, // id is set upstream and is either the widget id or in the case of a follow-up the partialAnswerId
    x: metaWidget.column,
    y: metaWidget.row,
    w: metaWidget.width,
    h: metaWidget.height
  }));

const Content = memo(
  ({
    dispatch,
    boardId,
    metaPartialAnswers,
    partialAnswers,
    isEditing,
    isSharedAndUnpublished,
    answerId,
    messageId,
    previewMode,
    t
  }) => {
    // If we are in edit mode, the currentMetaAnswers can start to differ from the passed in metaPartial Answers
    // and are hence stateful
    const [currentMetaAnswers, setCurrentMetaAnswers] = useState(metaPartialAnswers);

    const isMobile = useMediaQuery();

    // Initialize also the global state and keep it in sync with the local current meta answers
    useEffect(() => {
      if (isEditing) {
        dispatch(updateEditBoardState(currentMetaAnswers));
      }
    }, [currentMetaAnswers, dispatch, isEditing]);

    // The layout is initialised based on the state of the meta answer and should be updated whenever the currentMetaAnswers change
    // There is also the inverse case where first the layout changes, and then needs to up-date the meta answers
    // i.e. onLayoutChange when you reorder or resize an item, in that case we don't want to update the layout again afterwards
    // or we'd end up in an infite loop

    // We keep track of:
    // - layout: the layout of the grid for the current breakpoint
    //    used to give the widgets their current width and height
    // - layouts: the layouts for all breakpoints, to avoid issues when switching between breakpoints (e.g. when resizing the browser window)
    //    See https://github.com/react-grid-layout/react-grid-layout/issues/1663 for more details
    // Our two breakpoints are:
    // - sm: normal window size, with 12 grid columns
    // - xs: small/mobile window size, where everything is shown in one grid column
    // We let the ResponsiveGridLayout handle setting the xs breakpoint layout
    const initialLayout = layoutFromMetaAnswers(currentMetaAnswers);
    const [layout, setLayout] = useState(initialLayout);
    const [layouts, setLayouts] = useState({ sm: initialLayout });

    // Recompute initial layout (in case we are reverting back) or the original widgets changed (e.g. got persisted)
    useEffect(() => {
      setCurrentMetaAnswers(metaPartialAnswers);
      const updatedInitialLayout = layoutFromMetaAnswers(metaPartialAnswers);
      setLayout(updatedInitialLayout);
      setLayouts({
        sm: updatedInitialLayout
      });
    }, [isEditing, metaPartialAnswers]);

    // The React Grid library tells us the layout changed
    // Updated the current meta answers based on possible order or size changes
    const onLayoutChange = useCallback(
      (nextLayout, allLayouts) => {
        // Always keep the layout states in sync with the current situation
        setLayout(nextLayout);
        setLayouts(allLayouts);

        // Update the current state only if we are in edit mode.
        // Otherwise resizing the browser window will change the meta state of the rows
        if (isEditing) {
          const updatedMetaAnswers = currentMetaAnswers.map(mpa => {
            // The xs layout always has width=1, which we wouldn't want to store
            const storableLayout = allLayouts['sm'];
            const l = storableLayout.find(l => mpa.id === l.i);
            return { ...mpa, row: l.y, column: l.x, width: l.w, height: l.h };
          });
          setCurrentMetaAnswers(updatedMetaAnswers);
        }
      },
      [currentMetaAnswers, isEditing]
    );

    // Remove the widget from the local i.e. current meta answer state
    const onWidgetDelete = useCallback(
      widgetId => {
        setCurrentMetaAnswers(currentMetaAnswers => currentMetaAnswers.filter(m => m.id !== widgetId));
      },
      [currentMetaAnswers]
    );

    const onWidgetTitleChange = useCallback(
      (widgetId, newTitle) => {
        setCurrentMetaAnswers(currentMetaAnswers =>
          currentMetaAnswers.map(m => (m.id === widgetId ? { ...m, title: newTitle } : m))
        );
      },
      [currentMetaAnswers]
    );

    const onWidgetVisualizationChange = useCallback(
      (widgetId, newType) => {
        const visualizationOptions = metaPartialAnswers.find(m => m.id === widgetId && m.type === newType)
          ?.visualizationOptions;
        setCurrentMetaAnswers(currentMetaAnswers =>
          // we cannot just update the type, but also need to find the correct visualization options for that type (if they exist in the current meta answers)
          currentMetaAnswers.map(m =>
            m.id === widgetId ? { ...m, type: newType, visualizationOptions: visualizationOptions } : m
          )
        );
      },
      [currentMetaAnswers]
    );

    const inlinePublishButton = (
      <div className={styles.flexChildren}>
        <span className={styles.attentionMessage}>{t('board.is-unpublished-message')}</span>
        <Button
          layout={muiLayouts.veezoo}
          noBorders
          onClick={() => dispatch(publishBoardChanges(boardId))}
          icon={<Share />}
          iconOnly={isMobile}
        >
          {t('board.publish')}
        </Button>
      </div>
    );

    if (currentMetaAnswers?.length > 0) {
      return (
        // Note we're using the 'ChatBoard' class here for conditional css styling (e.g. if a chart is in a chatboard or not)
        <div className={classnames(styles.boardContainer, 'chatBoard')}>
          {!previewMode && isSharedAndUnpublished && inlinePublishButton}
          <ResponsiveGridLayout
            className="layout"
            breakpoints={{ sm: 768, xs: 0 }}
            layouts={layouts}
            draggableCancel='[data-css-selector="cancel-draggable"]'
            isDraggable={!previewMode && isEditing}
            isResizable={!previewMode && isEditing}
            onLayoutChange={onLayoutChange}
            // we had an issue on mobile which showed charts in the board with around half width
            // the issue was caused because the "size" of the chart was determined on the wrong
            // layout e.g. it should have used sizing of single column but for a split second used the one with 12 columns.
            // by sending a resize event after the breakpoint change we can force a correct re-render of the charts.
            onBreakpointChange={dispatchResizeEvent}
            containerPadding={[0, 0]} // remove padding inside container in x and y direction.
            cols={{ sm: 12, xs: 1 }} // on smaller window sizes, we want to show everything in one column
            rowHeight={100}
          >
            {// We make a copy and sort it according to the order in which they appear
            // If we would not do this, when initialising the widgets would still move around
            [...currentMetaAnswers].sort(compareWidgets).map(metaAnswer => {
              const answerWidget = partialAnswers.find(
                partialAnswer =>
                  partialAnswer.answerId === answerId && partialAnswer.interpretationId === metaAnswer.interpretationId
              );

              const layoutOfWidget = layout.find(l => l.i === metaAnswer.id);
              const width = layoutOfWidget.w;
              const height = layoutOfWidget.h;
              return (
                <div key={metaAnswer.id}>
                  <ChatBoardWidget
                    id={metaAnswer.id}
                    title={metaAnswer.title}
                    understood={metaAnswer.understood}
                    isEditing={isEditing}
                    initialVisualizationType={metaAnswer.type}
                    customVisualizationOptions={metaAnswer.visualizationOptions}
                    answer={answerWidget}
                    answerId={answerId}
                    onWidgetDelete={onWidgetDelete}
                    onWidgetTitleChange={onWidgetTitleChange}
                    onWidgetVisualizationChange={onWidgetVisualizationChange}
                    height={height}
                    width={width}
                    messageId={messageId}
                    hasFailed={metaAnswer.hasFailed || answerWidget?.hasFailed}
                    errorMessage={metaAnswer.errorMessage || answerWidget?.errorMessage}
                    isUnauthorized={metaAnswer.isUnauthorized}
                    previewMode={previewMode}
                    t={t}
                  />
                </div>
              );
            })}
          </ResponsiveGridLayout>
        </div>
      );
    }

    return null;
  }
);

const ChatBoard = ({
  metaPartialAnswers,
  dispatch,
  answerId,
  boardId,
  partialAnswers,
  isEditing,
  messageId,
  isSharedAndUnpublished,
  previewMode,
  t
}) => {
  // Ensure that the charboard metapartial answer ALWAYS have a unique id attached (either widgetid or partialanswerid)
  // Depending if it's a multi answer (board-like answer) or a saved board
  const transformedMetaPartialAnswers = metaPartialAnswers.map(w => ({ ...w, id: w.id || w.partialAnswerId }));
  return (
    <>
      {isChatActive ? (
        <Content
          dispatch={dispatch}
          boardId={boardId}
          metaPartialAnswers={transformedMetaPartialAnswers}
          isEditing={isEditing}
          isSharedAndUnpublished={isSharedAndUnpublished}
          partialAnswers={partialAnswers}
          answerId={answerId}
          messageId={messageId}
          previewMode={previewMode}
          t={t}
        />
      ) : null}
    </>
  );
};

export default withTranslation('veezoo')(connect()(memo(ChatBoard)));
