export default function svgNode(color, opacity = 1, icon = null, iconColor) {
  const svg =
    '<svg xmlns="http://www.w3.org/2000/svg" height="100" width="100">' +
    // circle with specified color and opacity
    '<circle cx="50" cy="50" r="50" fill="' +
    color +
    '" opacity="' +
    opacity +
    '" /> ' +
    // black circle border with reduced opacity
    '<circle cx="50" cy="50" r="50" fill="none" stroke="black" stroke-width="7" opacity="0.22" /> ' +
    // the icon, if specified, with reduced opacity and rendered using a mask to allow setting the icon color
    (icon
      ? '<mask id="icon-mask" x="0" y="0" width="1" height="1"> ' +
        '  <image x="25" y="25" width="50" height="50" href="' +
        icon +
        '" opacity="1.0" /> ' +
        '</mask> ' +
        '<circle cx="50" cy="50" r="50" fill="' +
        iconColor +
        '" mask="url(#icon-mask)" /> '
      : '') +
    '</svg>';
  return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
}
