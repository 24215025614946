import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { InfoTooltip } from 'components/v3';
import Button from 'components/buttons/Button';
import styles from 'components/sidebar/sidebar.scss';

const ChatTab = ({ selectedChatClass, handleSwitchModes, t, kgMeta }) => (
  <Link
    data-overview-tutorial="step-2"
    className={styles.link}
    onClick={handleSwitchModes}
    id="ChatIconHook"
    to={'/chat' + (kgMeta?.id ? `?id=${kgMeta.id}` : '')}
  >
    <InfoTooltip text={t('tooltip.chat-icon')} placement="right">
      <Button className={selectedChatClass} text={t('sidebar.chat')}>
        <span className="icon-chat" />
      </Button>
    </InfoTooltip>
  </Link>
);

const mapStateToProps = state => ({ kgMeta: state.knowledgeGraphMeta.meta });

export default connect(mapStateToProps)(ChatTab);
