import { makeStyles } from '@material-ui/core';

import { iconPositions } from './index';

const spinTime = '1s';

const root = {
  minWidth: ({ width, showOnlyIcon }) => (width ? width : showOnlyIcon ? 35 : 110),
  width: ({ fullWidth, width, showOnlyIcon }) => {
    if (width) return width;
    if (showOnlyIcon) return 35;
    if (fullWidth) return '100%';
    return 'initial';
  },
  padding: ({ showOnlyIcon }) => (showOnlyIcon ? 10 : '10px 16px'),
  height: ({ height, showOnlyIcon }) => (height ? height : showOnlyIcon ? 35 : 36),
  borderRadius: ({ noBorderRadius }) => (noBorderRadius ? 0 : 4),
  border: ({ bordered, dark, secondary }) =>
    bordered && !dark && !secondary ? 'solid thin var(--light-gray) !important' : 'none',
  fontSize: 14,
  fontWeight: 500,
  textTransform: 'none'
};

export default makeStyles({
  light: {
    ...root,
    backgroundColor: 'transparent',
    color: ({ secondary }) => (secondary ? 'var(--secondary-color)' : 'var(--primary-color)'),
    '&:hover': {
      opacity: 0.7,
      backgroundColor: 'var(--lightest-gray)'
    }
  },
  dark: {
    ...root,
    backgroundColor: ({ secondary }) => (secondary ? 'var(--secondary-color)' : 'var(--primary-color)'),
    color: 'white !important',
    '&:hover': {
      opacity: 0.7,
      backgroundColor: ({ secondary }) => (secondary ? 'var(--secondary-color)' : 'var(--primary-color)'),
      color: 'white'
    },
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: 'lightgray'
    }
  },
  icon: {
    width: 20,
    height: 20,
    '& > path': {
      fill: ({ dark, secondary }) => (dark ? 'white' : secondary ? 'var(--secondary-color)' : 'var(--primary-color)')
    }
  },
  arrowIcon: {
    width: 15,
    height: 15,
    marginLeft: 8,
    '& > path': { fill: 'var(--light-medium-gray)' }
  },
  label: {
    justifyContent: 'space-evenly'
  },
  labelSpan: ({ hasIcon, iconPosition }) => ({
    ...(hasIcon ? (iconPosition === iconPositions.left ? { marginLeft: 7 } : { marginRight: 7 }) : {})
  }),
  loadingContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    minHeight: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center'
  },
  loadingIcon: {
    marginLeft: 8,
    width: 15,
    height: 15,
    animation: `$spin ${spinTime} linear infinite`,
    '& path': {
      fill: 'var(--light-medium-gray)'
    }
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
});
