import { PureComponent } from 'react';
import { boundMethod } from 'autobind-decorator';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { connect } from 'react-redux';

import { HeartIcon } from '../Icons';
import Title from 'components/tutorial/Title';
import { finishedTutorial } from 'store/modules/tutorial';

import { withTranslation } from 'react-i18next';
import { tutorialNames } from 'config/constants';

const tutorialId = tutorialNames.ADDED_WIDGET_TUTORIAL;

export class AddWidgetTutorial extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      runTutorial: false
    };
  }

  @boundMethod
  callback(tour) {
    const { action, type } = tour;

    if (type === EVENTS.TOUR_END) {
      const isCompleted = action !== ACTIONS.SKIP;
      this.props.dispatch(finishedTutorial(tutorialId, isCompleted));
    }
  }

  // Todo: change to componentDidUpdate
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.state.runTutorial &&
      nextProps.isAddingBoardWidget &&
      this.props.uncompletedTutorials.some(tut => tut.id === tutorialId)
    ) {
      this.setState({ runTutorial: true });
    }
  }

  render() {
    const { t } = this.props;

    // Helper function to select the target of the tutorial based on the selectors
    const getPriorityTarget = selectors => {
      const selectorList = selectors.split(',').map(selector => selector.trim());
      for (const selector of selectorList) {
        const element = document.querySelector(selector);
        if (element) return element;
      }
      return null;
    };

    const steps = [
      {
        target: getPriorityTarget(
          '[data-addwidget-tutorial="knowledge-graph-sidebar-open"], [data-addwidget-tutorial="sidebar-collapsed"], [data-addwidget-tutorial="step-1"]'
        ),
        disableBeacon: true,
        showProgress: false,
        spotlightClicks: true,
        placement: 'right',
        locale: { last: t('close') },
        content: t('tutorial.widget.1'),
        title: <Title text={t('tutorial.widget.1-title')} icon={<HeartIcon />} />
      }
    ];
    return (
      <Joyride
        {...this.props.generalOptions}
        ref="joyride"
        steps={steps}
        run={this.state.runTutorial} // or some other boolean for when you want to start it
        callback={this.callback}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isAddingBoardWidget: state.network.isAddingBoardWidget,
    uncompletedTutorials: state.tutorial.uncompletedTutorials
  };
};

export default withTranslation('veezoo')(connect(mapStateToProps)(AddWidgetTutorial));
