import { useEffect, useState, useMemo, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Pagination from './table-plot/Pagination';

import Popover from '@material-ui/core/Popover';

import ColumnFilter from './table-plot/ColumnFilter/ColumnFilter';

import { textSort } from 'utils/arrayUtils';

import styles from './table-plot.scss';

import CodeDialog from 'components/shared/CodeDialog';

import { tableColumnTypes } from 'config/constants';

import { trackEvent } from 'utils/eventTracking';

import EditFilterButton from './table-plot/EditFilterButton';
import { connect } from 'react-redux';
import { parseNumber, formatNumber } from 'utils/numberFormat';
import { MenuOpen } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { chatIconButtonStyle } from 'components/message/BaseMessage';
import NumberColumnStatisticsFooter from './table-plot/NumberColumnStatisticsFooter';
import ReactTableUI from 'components/chart/ReactTableUI';
import { getRgba } from '../../utils/colorUtils';

import { InfoTooltip } from 'components/v3';

const initialFilterState = {
  open: false,
  column: null,
  position: { top: 0, left: 0 }
};

const DEFAULT_PAGE_SIZE_MAX = 10;

// Compute how much space the largest number requires to limit width of the bar
const getPaddingForNumberBar = (data, accessor, user) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const maxLength = Math.max(
    ...data.map(row => {
      const formattedNumber = formatNumber(accessor(row), user.numberFormat);
      return formattedNumber?.length || 0;
    })
  );
  // "123456" needs about 48px space, "1" needs about 13px
  return maxLength * 7 + 6;
};

// Render cells from number columns using a custom width bar depending on other values in the cell
const numberCellBarRenderer = (row, user) => {
  const numberBarPadding = row.column.numberBarPadding;
  const number = parseNumber(row.value);
  const minValue = row.column.minValue;
  const maxValue = row.column.maxValue;

  const [zeroStartFraction, valueFraction] = getFractionsForValueInRange(number, minValue, maxValue);
  const [zeroStartPercentage, percentageSize] = [zeroStartFraction * 100, valueFraction * 100];

  const gradient =
    number >= 0
      ? `transparent ${zeroStartPercentage}%,
       ${row.column.positiveNumberColor} ${zeroStartPercentage}%,
       ${row.column.positiveNumberColor} ${zeroStartPercentage + percentageSize}%,
       transparent ${zeroStartPercentage + percentageSize}%`
      : `transparent ${zeroStartPercentage - percentageSize}%,
      ${row.column.negativeNumberColor} ${zeroStartPercentage - percentageSize}%,
      ${row.column.negativeNumberColor} ${zeroStartPercentage}%,
      transparent ${zeroStartPercentage}%`;

  const formattedNumber = formatNumber(number, user.numberFormat);

  return isNaN(number) ? (
    <div className={styles.alignRight}>—</div>
  ) : (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          background: `linear-gradient(90deg, ${gradient})`,
          width: `calc(100% - ${numberBarPadding}px)`
        }}
      >
        <div style={{ background: '#ffffffd4' }}>&nbsp;</div>
      </div>
      <div className={styles.alignRight} style={{ flexGrow: 1 }} title={formattedNumber}>
        {formattedNumber}
      </div>
    </div>
  );
};

// Render cells from number columns using a custom color depending on a specified min/max range
const numberCellColorRenderer = (row, user) => {
  const number = parseNumber(row.value);
  const minValue = row.column.minValue;
  const maxValue = row.column.maxValue;

  // if min == max, they are all the same values and we don't want color, i.e. alpha = 0
  // we take the second fraction, which is the relative magnitude of the given value within the range
  const alpha = minValue !== maxValue ? getFractionsForValueInRange(number, minValue, maxValue)[1] : 0;

  const positiveNumberColor = row.column.positiveNumberColor;
  const negativeNumberColor = row.column.negativeNumberColor;
  const cellColor = number >= 0 ? positiveNumberColor : negativeNumberColor;
  const cellColorWithAlpha = getRgba(cellColor, alpha);

  const formattedNumber = formatNumber(number, user.numberFormat);

  return isNaN(number) ? (
    <div className={`${styles.alignRight} ${styles.pivotColorBox}`}>—</div>
  ) : (
    <div
      className={`${styles.alignRight} ${styles.pivotColorBox}`}
      title={formattedNumber}
      style={{ '--dynamic-bg-color': cellColorWithAlpha }}
    >
      {formattedNumber}
    </div>
  );
};

// Determines two fractions (between 0 and 1), for a given value and a range:
// - zeroStartFraction: The fraction representing the position of zero within the whole range.
// - valueFraction: The fraction representing the relative magnitude of the given value within the range,
//                  getting closer to zero as the value approaches zero.
const getFractionsForValueInRange = (value, minValue, maxValue) => {
  // Force the range of possible points to include 0
  const scaleMin = Math.min(minValue, 0);
  const scaleMax = Math.max(maxValue, 0);
  const scaleDiff = scaleMax - scaleMin;
  let zeroStartFraction, valueFraction;

  if (minValue !== maxValue) {
    const positionInFractionFromLeft = x => (x - scaleMin) / scaleDiff;
    zeroStartFraction = positionInFractionFromLeft(0);
    valueFraction = Math.abs(positionInFractionFromLeft(value) - zeroStartFraction);
  } else {
    // If min == max, they are all the same values and we want one bar with full width (except if it's 0)
    zeroStartFraction = value >= 0 ? 0 : 1;
    valueFraction = value === 0 ? 0 : 1;
  }

  return [zeroStartFraction, valueFraction];
};

// Get the min and max of a number column
const getColumnMinMax = (data, accessor) => {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
  }
  const values = data.map(row => parseNumber(accessor(row))).filter(number => !isNaN(number));
  return [Math.min(...values), Math.max(...values)];
};

// Get the min and max over all numbers in the table
const getTableMinMax = (data, columns) => {
  // we first need the identifiers of the number columns to know where to get the values from
  const numberColumns = columns.filter(column => column.type === tableColumnTypes.NUMBER);
  const numberColumnIdentifiers = numberColumns.map(column => column.identifier);
  const allNumberColumnValues = data.flatMap(row => numberColumnIdentifiers.map(identifier => row[identifier]));
  const allNumbers = allNumberColumnValues.map(number => parseNumber(number)).filter(number => !isNaN(number));
  return [Math.min(...allNumbers), Math.max(...allNumbers)];
};

const getColors = (isPivotTable, isPerformanceDegradationIndicator) => {
  let positiveNumberColor, negativeNumberColor;
  if (!isPivotTable) {
    const grey = window.Highcharts.theme.colors[0];
    const blue = window.Highcharts.theme.colors[1];
    [positiveNumberColor, negativeNumberColor] = [grey, blue];
  } else {
    const green = '#d2e3c3';
    const red = '#ddbfbf';
    // if isPerformanceDegradationIndicator, bigger values are worse, so we swap green and red
    [positiveNumberColor, negativeNumberColor] = isPerformanceDegradationIndicator ? [red, green] : [green, red];
  }
  return [positiveNumberColor, negativeNumberColor];
};

const computeState = json => {
  if (json?.data) {
    return {
      data: json.data,
      columns: json.columns,
      isPivotTable: json.isPivotTable,
      isPerformanceDegradationIndicator: json.isPerformanceDegradationIndicator,
      loading: false
    };
  } else {
    return {
      data: [],
      columns: [],
      isPivotTable: false,
      isPerformanceDegradationIndicator: false,
      loading: true
    };
  }
};

const findDefaultExpandedRepresentedCandidates = columns => {
  // we talk about represented candidates (e.g. order) and representers (e.g. order amount)
  // a represented candidate can have a specific column for itself (e.g. order id) and then additional representer information (e.g. order amount)
  // or it may only have representer information (e.g. order amount etc., no order id column)
  // in this last case there is no way to collapse/expand, so its representers should always be expanded (shown)

  // take the distinct represented candidates
  const representedCandidates = [...new Set(columns.map(column => column.representedCandidate))];
  // these are represented candidates that have no column for themselves, so we want to expand their representers by default
  const representedCandidatesShowOnlyRepresenters = representedCandidates.filter(
    representedCandidate => !columns.find(column => column.identifier === representedCandidate)
  );
  // if we only have one column with representers, we want to expand it by default
  //  e.g. we are showing a list of customers (+ representers, e.g. age), but no other additional column
  //  instead of just showing the names, we want to expand it completely
  // if we were showing a list of customers and their total profit, we don't want to expand it by default
  //  so the user can see what matters
  // Concretely, we check for number of distinct representedCandidate < 1
  const columnsThatAreNotRepresenters = columns
    .filter(column => !column?.representedCandidate)
    .map(column => column.identifier);

  // Additionally, if there's just a single number column that has representers (in particular proportion),
  //  we want to expand its representers, even if there are other columns that are not representers
  const representedNumberCandidates = representedCandidates.filter(representedCandidate => {
    return columns.some(
      column => column.identifier === representedCandidate && column.type === tableColumnTypes.NUMBER
    );
  });

  if (columnsThatAreNotRepresenters.length <= 1) {
    return [...representedCandidatesShowOnlyRepresenters, ...representedCandidates];
  } else if (representedNumberCandidates.length <= 1) {
    return [...representedCandidatesShowOnlyRepresenters, ...representedNumberCandidates];
  } else {
    return representedCandidatesShowOnlyRepresenters;
  }
};

const initialCodeDialogState = { modalTitle: '', text: '', isOpen: false };

const ReactTableCustomized = ({ user, meta, dispatch, ...props }) => {
  const { json, defaultPageSize, t, isInAnswerMessage, answer } = props;

  const [state, setState] = useState(computeState(json));
  const [filterState, setFilterState] = useState(initialFilterState);
  const [expandedRepresentedCandidates, setExpandedRepresentedCandidates] = useState([]);
  const [stringViewDialog, setStringViewDialog] = useState(initialCodeDialogState);

  const columnToggleStyle = chatIconButtonStyle({ width: '26px', height: '26px' });

  useEffect(() => {
    if (json?.data) {
      setState(computeState(json));
    }
    const defaultExpandedRepresenters = findDefaultExpandedRepresentedCandidates(state.columns);
    setExpandedRepresentedCandidates(defaultExpandedRepresenters);
  }, [json?.data, state.columns]);

  const getColumnWidth = (column, data, accessor) => {
    data = data.slice(0, defaultPageSize);
    if (typeof accessor === 'string' || accessor instanceof String) {
      accessor = d => d[accessor]; // eslint-disable-line no-param-reassign
    }
    const minWidth = 100;
    const maxWidth = 250;
    const pxPerLetter = 9; // Longest letter "W" takes 11.5px, average is ~6px, so 9 should be plenty for most cases
    const baseWidth = 15; // Add a constant value to make smaller columns wider to account for padding
    const rowLengths = data.map(row => {
      const formattedValue =
        column.type === tableColumnTypes.NUMBER
          ? formatNumber(accessor(row), user.numberFormat)
          : String(accessor(row));
      return formattedValue?.length || 0;
    });
    const cellLength = Math.max(...rowLengths, column.title.length);
    return Math.max(Math.min(cellLength * pxPerLetter + baseWidth, maxWidth), minWidth);
  };

  const tableHeader = (title, column, representedColumn, columnHasRepresenters) => {
    const isExpanded = expandedRepresentedCandidates.includes(column.identifier);
    // there may not be a represented column, but the column itself may represent another concept
    // this is the case when a concept is ShowOnlyRepresenters,
    // e.g. Customer Interests could be represented by Customer and the Interest, but there is no Customer Interest column
    const representsAnotherColumn = !!column.representedName || !!representedColumn;
    const toggleRepresenters = e => {
      // we don't want to e.g. sort the column when clicking the expand button
      e.stopPropagation();
      if (expandedRepresentedCandidates.includes(column.identifier)) {
        setExpandedRepresentedCandidates(expandedRepresentedCandidates.filter(id => id !== column.identifier));
      } else {
        setExpandedRepresentedCandidates([...expandedRepresentedCandidates, column.identifier]);
      }
    };

    return (
      <div className={styles.titleContainer}>
        <div className={representsAnotherColumn ? styles.title : styles.onlyOneTitle}>
          {representsAnotherColumn && (
            <div title={title} data-test="column-title" className={styles.columnHeaderTitleLight}>
              {column.representedName || representedColumn.title}
            </div>
          )}
          <div title={title} data-test="column-title">
            {column.title}
          </div>
        </div>
        {column.isFilterable && (
          <div className={styles.icon} data-testclass="editFilterButton">
            <EditFilterButton t={t} onClick={e => openHeaderFilter(e, column)} />
          </div>
        )}
        <div className={styles.icon}>
          <span className={styles.filterArrow} />
        </div>
        {columnHasRepresenters && (
          <InfoTooltip text={isExpanded ? t('table.show-fewer-columns') : t('table.show-more-columns')}>
            <IconButton onClick={toggleRepresenters} classes={columnToggleStyle} size="small">
              {isExpanded ? <MenuOpen /> : <MenuOpen style={{ transform: 'rotate(180deg)' }} />}
            </IconButton>
          </InfoTooltip>
        )}
      </div>
    );
  };

  const showStringViewModal = ({ title, row }) => {
    trackEvent('Opened STRING view modal on table row to better view string format', {
      username: user.username,
      email: user.email,
      knowledgeGraph: { id: meta.id, name: meta.name }
    });
    setStringViewDialog({ modalTitle: title, text: row.value, isOpen: true });
  };

  const renderColumn = (
    data,
    column,
    isPivotTable,
    isPerformanceDegradationIndicator,
    tableMinMax,
    representedColumn,
    defaultPageSize,
    tableContainsNumberColumn,
    columnHasRepresenters
  ) => {
    const { title, identifier, type } = column;
    let tableColumn;

    switch (type) {
      case tableColumnTypes.STRING:
        tableColumn = {
          Header: tableHeader(title, column, representedColumn, columnHasRepresenters),
          accessor: identifier,
          sortable: true,
          sortType: (a, b) => textSort(a.values[identifier], b.values[identifier]),
          [tableContainsNumberColumn ? 'width' : 'minWidth']: getColumnWidth(column, data, r => r[identifier]),
          Cell: row =>
            row.value === undefined || row.value === null ? (
              '—'
            ) : (
              <span className={styles.stringRow} title={row.value} onClick={() => showStringViewModal({ title, row })}>
                {row.value}
              </span>
            ),
          width: getColumnWidth(column, data, r => r[identifier])
        };
        break;
      case tableColumnTypes.CATEGORICAL:
      case tableColumnTypes.BOOLEAN:
      case tableColumnTypes.CASE_IDENTIFIER:
      case tableColumnTypes.DATE_INTERVAL:
        tableColumn = {
          Header: tableHeader(title, column, representedColumn, columnHasRepresenters),
          accessor: identifier,
          sortable: true,
          sortType: (a, b) => textSort(a.values[identifier], b.values[identifier]),
          [tableContainsNumberColumn ? 'width' : 'minWidth']: getColumnWidth(column, data, r => r[identifier]),
          Cell: row =>
            row.value === undefined || row.value === null ? '—' : <span title={row.value}>{row.value}</span>,
          ...(isPivotTable &&
            data.length > 1 &&
            data.length < 2500 && {
              Footer: () => {
                return <strong>Total</strong>;
              }
            }),
          width: getColumnWidth(column, data, r => r[identifier])
        };
        break;

      case tableColumnTypes.DATE_TIME:
        tableColumn = {
          sortType: (a, b) => textSort(a.values[identifier], b.values[identifier]),
          width: getColumnWidth(column, data, r => r[identifier]),
          Cell: row => (row.value === undefined || row.value === null ? '—' : <div title={row.value}>{row.value}</div>)
        };
        break;

      case tableColumnTypes.NUMBER: {
        // if it's a pivot table, we use the global min/max over all number columns for the cell color
        // otherwise, we use the min/max of the column for the bar size in each cell
        const [minValue, maxValue] = isPivotTable ? tableMinMax : getColumnMinMax(data, r => r[identifier]);
        const [positiveNumberColor, negativeNumberColor] = getColors(isPivotTable, isPerformanceDegradationIndicator);
        tableColumn = {
          minValue,
          maxValue,
          positiveNumberColor,
          negativeNumberColor,
          numberBarPadding: getPaddingForNumberBar(data, r => r[identifier], user),
          sortType: (a, b) => {
            const numberA = parseNumber(a.values[identifier]);
            const numberB = parseNumber(b.values[identifier]);
            // force non-numbers to the bottom
            if (isNaN(numberA) && isNaN(numberB)) return 0;
            if (isNaN(numberA)) return 1;
            if (isNaN(numberB)) return -1;
            return numberB - numberA;
          },
          width: getColumnWidth(column, data, r => r[identifier]), // number columns are allowed to take all the remaining space available due to their special styling
          Cell: row => (isPivotTable ? numberCellColorRenderer(row, user) : numberCellBarRenderer(row, user)),
          ...(data.length > 1 &&
            data.length < 2500 && {
              Footer: table => {
                return (
                  <NumberColumnStatisticsFooter
                    data={table.data}
                    rowIdentifier={identifier}
                    numberFormat={user.numberFormat}
                    isPivotTable={isPivotTable}
                    t={t}
                  />
                );
              }
            })
        };
        break;
      }

      case tableColumnTypes.LINK:
        tableColumn = {
          sortType: (a, b) => textSort(a.values[identifier]?.value, b.values[identifier]?.value),
          width: getColumnWidth(column, data, r => r[identifier]?.value),
          Cell: row =>
            row.value === undefined || row.value === null ? (
              '—'
            ) : !row.value.link ? (
              row.value.value
            ) : (
              <a
                href={row.value.link}
                title={column.tooltip}
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {row.value.value}
              </a>
            )
        };
        break;

      default:
      // do nothing
    }

    // merge in the shared table column properties
    tableColumn = {
      type: type, // Pass the column type to the table column for further rendering
      Header: tableHeader(title, column, representedColumn, columnHasRepresenters),
      accessor: identifier,
      ...tableColumn
    };

    return tableColumn;
  };

  // Structure of one column (example):
  // {
  //     "title": "Kanton",
  //     "identifier": "Kanton_sufF",
  //     "type": "categorical",
  //     "representedCandidate": "Kunde_e7Lr",
  //     "representedRelation": "kb:Kunde.Kanton.relation",
  //     "isFilterable": true,
  //     "classUri": "kb:Kunde.Kanton"
  // }
  const tableColumns = useMemo(() => {
    if (state.columns) {
      // If we have multiple different table relations present (or columns without table relation)
      // We want to collapse the representers in the table header and make them expandable
      // via a button in the o.g. cell

      // we do not want to have long / lat columns in the table
      const columnsWithoutGeo = state.columns.filter(
        column => ![tableColumnTypes.LONGITUDE, tableColumnTypes.LATITUDE].includes(column.type)
      );

      const collapsedRepresenters = columnsWithoutGeo.filter(
        column => column.representedCandidate && !expandedRepresentedCandidates.includes(column.representedCandidate)
      );
      const columnsToRender = columnsWithoutGeo.filter(column => !collapsedRepresenters.includes(column));

      // Number columns grow to as much space as they have available (if there's spare space)
      // i.e. we set fixed widths to everything that is not a number column and only min-widths on number columns
      // if the table contains no number columns, every column is allowed to grow by using min-width instead of width
      const tableContainsNumberColumn = columnsToRender.some(column => column.type === tableColumnTypes.NUMBER);

      // get the global min and max values over all number columns in the table, will only be used for pivot tables
      const tableMinMax = getTableMinMax(json.data, columnsToRender);

      // see whether this table is a pivot table, for styling purposes
      const isPivotTable = state.isPivotTable;

      // see whether a higher value in this data is worse
      const isPerformanceDegradationIndicator = state.isPerformanceDegradationIndicator;

      return columnsToRender.map(column => {
        const columnHasRepresenters = columnsWithoutGeo.some(
          representer => representer.representedCandidate && representer.representedCandidate === column.identifier
        );
        const representedColumn = columnsWithoutGeo.find(
          represented => column.representedCandidate && column.representedCandidate === represented.identifier
        );
        return renderColumn(
          json.data,
          column,
          isPivotTable,
          isPerformanceDegradationIndicator,
          tableMinMax,
          representedColumn,
          defaultPageSize,
          tableContainsNumberColumn,
          columnHasRepresenters
        );
      }); // here is loading slice of data depends on defaultPageSize prop
    }
    return [];
  }, [state.columns, json, expandedRepresentedCandidates]);

  const customClassName = isInAnswerMessage ? styles.noMarginTable : '';

  const handleFilterClose = () => setFilterState(prev => ({ ...prev, open: false }));

  const openHeaderFilter = async (e, column) => {
    e.persist();
    e.stopPropagation();
    const [left, top] = [e.pageX, e.pageY];
    setFilterState({ position: { left, top }, column, open: true });
  };

  const updatePositionRef = useRef(null);

  const columnAccessorsToStretch = useMemo(() => {
    return tableColumns.filter(column => column.type === tableColumnTypes.NUMBER).map(column => column.accessor);
  }, [tableColumns]);

  return (
    // we have special styling for pivot tables to make them more compact
    <div className={state.isPivotTable ? styles.pivotTableContainer : styles.tableContainer}>
      <Popover
        open={filterState.open}
        anchorReference="anchorPosition"
        anchorPosition={filterState.position}
        onClose={handleFilterClose}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        action={updatePositionRef}
      >
        <ColumnFilter
          answer={answer}
          filterState={filterState}
          handleFilterClose={handleFilterClose}
          updatePositionRef={updatePositionRef}
        />
      </Popover>
      <CodeDialog
        title={stringViewDialog.modalTitle}
        open={stringViewDialog.isOpen}
        onClose={() => setStringViewDialog(initialCodeDialogState)}
        code={stringViewDialog.text}
        // if the text contains no newlines, we want to wrap it to make it more readable
        // if it contains newlines, we want to keep the "structure", e.g. for exceptions in metaveezoo
        monacoEditorOptions={{ wordWrap: stringViewDialog.text.includes('\n') ? 'off' : 'on' }}
        hideLineNumbers
      />
      <ReactTableUI
        {...props}
        className={customClassName}
        data={state.data}
        columns={tableColumns}
        columnAccessorsToStretch={columnAccessorsToStretch}
        sortable={true}
        loading={state.loading}
        loadingText={t('loading')}
        previousText={t('back')}
        nextText={t('next')}
        noDataText={t('no-data-found')}
        pageText={t('page')}
        ofText={t('of')}
        rowsText={t('rows')}
        showPageJump={true}
        filterable={false}
        PaginationComponent={Pagination}
      />
    </div>
  );
};

const mapStateToProps = state => ({ user: state.user, meta: state.knowledgeGraphMeta.meta });
const ReactTableCustomizedTranslated = withTranslation('veezoo')(connect(mapStateToProps)(ReactTableCustomized));

const defaultProps = {
  className: '-highlight',
  minRows: 0,
  showPagination: true,
  showPaginationBottom: true,
  showPageSizeOptions: true,
  resizable: true
};

export const TablePlotFull = props => (
  <>
    <ReactTableCustomizedTranslated
      {...defaultProps}
      {...props}
      pageSizeOptions={[10, 25, 50, 100]}
      defaultPageSize={DEFAULT_PAGE_SIZE_MAX}
      loading={false}
    />
  </>
);

export const TablePlotWidget = props => {
  // we want to calculate the ideal number of rows to show in the table, to avoid overlapping elements and too much blank space
  // first, we see how much available space we have for the rows
  // a horizontal scrollbar removes some available space for the table rows, so we need to know whether it's visible or not
  const [isHorizontalScrollbarVisible, setIsHorizontalScrollbarVisible] = useState(false);
  // by default, the table has a pagination height of 33px
  // however, if the table width is too small, the pagination will wrap to the next line and take up more space
  // the initial state is important, since it will be initially rendered for a split-second
  const [tablePaginationHeight, setTablePaginationHeight] = useState(33);
  // this is the footer with the average/total/etc
  const hasStatisticsTableFooter =
    props?.json?.columns?.some(column => column.type === tableColumnTypes.NUMBER) && props?.json?.data?.length < 2500;
  const isPivotTable = props?.json?.isPivotTable;
  // this is the total height we have available (c.f. https://github.com/react-grid-layout/react-grid-layout#grid-item-heights-and-widths)
  const widgetHeight = props.height * 110 - 10;
  // the title height might change depending on the width of the table, and we monitor it in ChatBoardWidget.js
  // there are 5.5 extra pixels compared to the monitored titleHeight element
  const widgetTitleHeight = props.titleHeight + 5.5;
  const tableStatisticsFooterHeight = hasStatisticsTableFooter ? (isPivotTable ? 35 : 52) : 0;
  const tableHeaderHeight = 35;
  const horizontalScrollbarHeight = isHorizontalScrollbarVisible ? 8 : 0;
  const widgetButtonsFooterHeight = 27;
  const singleTableRowHeight = 29;

  const reservedHeight =
    widgetTitleHeight +
    tableStatisticsFooterHeight +
    tableHeaderHeight +
    horizontalScrollbarHeight +
    tablePaginationHeight +
    widgetButtonsFooterHeight;
  const availableHeight = widgetHeight - reservedHeight - 3; // we don't want the table to be too close to the widget buttons
  // number of rows we should show in the table, make sure it's at least 1
  const dynamicWidgetPageSize = Math.max(Math.floor(availableHeight / singleTableRowHeight), 1);
  const leftoverHeight = availableHeight - dynamicWidgetPageSize * singleTableRowHeight;
  const paginationSpacerHeight = Math.max(leftoverHeight, 0);
  return (
    <ReactTableCustomizedTranslated
      {...defaultProps}
      {...props}
      pageSizeOptions={[dynamicWidgetPageSize, 5, 10, 25, 50, 100]}
      defaultPageSize={dynamicWidgetPageSize}
      showPageSizeOptions={false} // this saves space, and we anyway show a dynamic number of rows
      loading={false}
      // the table can share information to let us better compute the available space for rows
      setIsHorizontalScrollbarVisible={setIsHorizontalScrollbarVisible}
      setTablePaginationHeight={setTablePaginationHeight}
      paginationSpacerHeight={paginationSpacerHeight}
    />
  );
};

ReactTableCustomized.propTypes = {
  t: PropTypes.func,
  defaultPageSize: PropTypes.number,
  json: PropTypes.object,
  isInAnswerMessage: PropTypes.bool,
  user: PropTypes.object,
  answer: PropTypes.object
};
