import React, { forwardRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import UITextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import UIInputAdornment from '@mui/material/InputAdornment';

import { useComponentStyles, useSelectStyles, useMenuItemStyles } from './Select.styles';

// TODO: When InfoTooltip is refactored to version 3, we should replace this import.
import { InfoTooltip } from 'components/v3';
import ArrowIcon from 'svg/v2/chevron_down.svg';

import useMediaQuery from 'utils/mediaQueries';
import { layouts } from 'config/constants';

const getAdornment = icon => <UIInputAdornment position="start">{icon}</UIInputAdornment>;

const Select = forwardRef(
  (
    {
      name = 'select',
      description = '',
      options = [],
      displayEmpty = false,
      noBorders = false,
      highlighted = false, // font gets a light-blue color, we use it on Signup when Language already exists, giving a blue color to the language.
      disabled = false,
      layout = layouts.default,
      shrink = false,
      required = false,
      label,
      startIcon,
      value,
      onChange,
      tooltip,
      placeholder = <>&nbsp;</>,
      defaultValue = '',
      'data-test': dataTest,
      SelectProps = {},
      title = 'Select Component',
      additionalInputProps = {},
      sx = {},
      ...props
    },
    ref
  ) => {
    const isMobile = useMediaQuery();

    const componentSyles = useComponentStyles();
    const selectStyles = useSelectStyles({ isMobile, highlighted, disabled, noBorders, tooltip, sx });
    const menuItemStyles = useMenuItemStyles();

    const customProps = useMemo(() => {
      const fields = {
        layout: selectStyles[layout],
        variant: 'outlined',
        size: 'small',
        autoComplete: ''
      };

      if (layout === layouts.signup) {
        fields.variant = 'standard';
        fields.autoComplete = 'new-password';
        delete fields.size;
      }

      if (layout === layouts.veezoo) {
        fields.variant = 'outlined';
        fields.autoComplete = 'new-password';
        delete fields.size;
      }

      return fields;
    }, [layout, highlighted]);

    const shouldShrink = useMemo(() => !!placeholder || !!tooltip || layout !== layouts.signup || shrink, [
      placeholder,
      layout,
      shrink,
      tooltip
    ]);

    const formattedOptions = useMemo(() => (displayEmpty ? [{ label: placeholder, value: '' }, ...options] : options), [
      placeholder,
      displayEmpty,
      options
    ]);

    const renderValue = useCallback(
      value => {
        if (!value || value === '') return <span className={componentSyles.labelForEmptyValue}>{placeholder}</span>;
        const currentOptions = formattedOptions.find(option => option.value === value);
        if (currentOptions) return currentOptions.label;
        return '';
      },
      [formattedOptions, placeholder]
    );

    const iconFontSize = useMemo(() => {
      if (layout === layouts.signup) return '1.35rem';
      return '0.85rem';
    }, [layout]);

    const buildHelperText = useMemo(() => {
      const text = description ? <span>{description}</span> : <></>;
      const space = description && tooltip ? <>&nbsp;</> : '';
      const infoTooltip = tooltip ? (
        <InfoTooltip layout={layout} text={tooltip} size={iconFontSize} clickableWhenMobile={isMobile} />
      ) : (
        <></>
      );
      return (
        <>
          {infoTooltip}
          {space}
          {text}
        </>
      );
    }, [isMobile, layout, description, tooltip, iconFontSize]);

    return (
      <UITextField
        select
        fullWidth
        {...(label
          ? {
              label: <>{`${label} ${required ? '*' : ''}`}</>
            }
          : {})}
        sx={customProps.layout}
        InputProps={{
          startAdornment: startIcon ? getAdornment(startIcon) : null
        }}
        SelectProps={{
          IconComponent: ArrowIcon,
          ...(displayEmpty ? { displayEmpty } : {}),
          renderValue,
          MenuProps: {
            PopoverClasses: { root: 'select-popover' },
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoFocus: false
          },
          SelectDisplayProps: { 'data-test': dataTest },
          ...SelectProps
        }}
        name={name}
        helperText={buildHelperText}
        disabled={disabled}
        variant={customProps.variant}
        size={customProps.size}
        inputProps={{ ref, title, ...additionalInputProps }}
        InputLabelProps={{
          ...(shouldShrink ? { shrink: shouldShrink } : {})
        }}
        autoComplete={customProps.autoComplete}
        // If value is undefined, the component is uncontrolled and defaultValue is used (We use this mode on forms).
        // Otherwise, component is controlled and "value" and "onChange" should be provided by props.
        {...(value === undefined ? { defaultValue } : { value, onChange })}
        {...props}
      >
        {formattedOptions.map(({ label, value, disabled: optionDisabled }, index) => (
          <MenuItem
            key={`item_${index}`}
            value={value}
            disabled={optionDisabled}
            sx={{ ...menuItemStyles[layout], ...(value === '' ? { opacity: 0.5 } : {}) }}
          >
            {label}
          </MenuItem>
        ))}
      </UITextField>
    );
  }
);

export default Select;

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool
    })
  ),
  displayEmpty: PropTypes.bool,
  noBorders: PropTypes.bool,
  startIcon: PropTypes.any,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  layout: PropTypes.string,
  shrink: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  'data-test': PropTypes.string,
  SelectProps: PropTypes.object,
  title: PropTypes.string,
  sx: PropTypes.object,
  additionalInputProps: PropTypes.object
};
