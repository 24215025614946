import clsx from 'clsx';
import { tss } from 'tss-react';
import useStyles from 'styles/JSS/Popover';
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { Button, Select, TextField } from 'components/v3';

import UISkeleton from '@mui/material/Skeleton';

import services from 'services';
import { handleError } from 'services/http';

import { layouts } from 'config/constants';

const useComponentStyles = tss.create({
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  argumentText: {
    fontSize: '11pt',
    marginLeft: '2pt',
    marginRight: '2pt'
  },
  targetText: {
    fontSize: '11pt',
    fontWeight: 600,
    marginLeft: '2pt',
    marginRight: '2pt'
  },
  templateContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'baseline',
    rowGap: '6px',
    padding: '4px 16px'
  }
});

const computationSelectSx = {
  '&.MuiTextField-root': {
    width: 160
  }
};

const Skeleton = () => <UISkeleton variant="rectangular" sx={{ width: '100%', height: 33 }} />;

const capitalize = text => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const TemplateFilter = ({
  answerId,
  interpretationId,
  candidateId,
  representedRelation,
  onConfirm,
  onCancel,
  t
}) => {
  const popoverStyles = useStyles();

  const { classes: componentStyles } = useComponentStyles();

  // title of the filter edit popup
  const [title, setTitle] = useState('');
  // current values of the variable arguments
  const [argumentValues, setArgumentValues] = useState(null);
  // available computation templates (maps computation URIs to templates)
  const [templates, setTemplates] = useState(null);
  // available computations for dropdown
  const [availableComputations, setAvailableComputations] = useState([]);
  // currently selected computation in dropdown
  const [selectedComputation, setSelectedComputation] = useState('');

  const isLoading = templates === null;

  const onChange = (item, value) => {
    const newArgumentValues = new Map([...argumentValues, [item, value]]);
    setArgumentValues(newArgumentValues);
  };

  const parseTemplates = rawTemplates => {
    const templateMap = new Map(rawTemplates.map(template => [template.computationUri, template]));

    // NOTE: there may be no original computation (namely, if the template filter is used to create a new filter)
    const original = rawTemplates.find(template => template.isOriginal);
    const originalArgumentValues = new Map();
    if (original) {
      for (const passage of original.passages) {
        if (passage.type === 'VARIABLE') {
          originalArgumentValues.set(passage.argumentIndex, passage.value);
        }
      }
    }
    const availableComputations = rawTemplates.map(template => ({
      label: template.computationName,
      value: template.computationUri
    }));

    // select the original computation, or the first available one if no original computation exists
    const selectedComputation = original?.computationUri || availableComputations[0].value;
    return [templateMap, selectedComputation, originalArgumentValues, availableComputations];
  };

  const loadTemplates = async () => {
    const result = await services.getFilterTemplates(answerId, interpretationId, candidateId, representedRelation);
    if (result.success) {
      const rawTemplates = result.data.templates;
      const title = capitalize(result.data.computationTargetName);
      const [parsedTemplates, originalComputationUri, originalData, computations] = parseTemplates(rawTemplates);
      setTitle(title);
      setAvailableComputations(computations);
      setSelectedComputation(originalComputationUri);
      setArgumentValues(originalData);
      setTemplates(parsedTemplates);
    } else {
      handleError(result);
    }
  };

  const getEditMessage = () => {
    return templates
      .get(selectedComputation)
      .passages.map(passage => {
        if (passage.type === 'VARIABLE') {
          if (passage.dataType === 'STRING') {
            // add quotation marks
            return `"${argumentValues.get(passage.argumentIndex)}"`;
          } else {
            return argumentValues.get(passage.argumentIndex);
          }
        } else {
          return passage.text;
        }
      })
      .join(' ');
  };

  const handleConfirm = () => {
    const message = getEditMessage();
    let constructedArguments = [];

    argumentValues.forEach((value, index) => {
      constructedArguments.push({ argumentIndex: index, value: value });
    });

    // after all validations, if we don't have any arguments, we add an empty one.
    // At this point, if the argument was a number it wouldn't have reached this point,
    // because the button would be disabled.
    // If it has reached, it means it's a string and should have at least one item.
    if (constructedArguments.length < 1) {
      constructedArguments.push({ argumentIndex: 1, value: '' });
    }

    const payload = {
      computationUri: selectedComputation,
      arguments: constructedArguments
    };
    onConfirm(payload, message);
  };

  const checkAllFieldsValid = () => {
    return (
      !isLoading &&
      templates.get(selectedComputation).passages.every(passage => {
        if (passage.type === 'VARIABLE') {
          if (passage.dataType === 'NUMBER') {
            // do not allow empty number arguments
            return argumentValues.get(passage.argumentIndex)?.length > 0;
          }
          // for string arguments, any value is OK (including empty strings)
          return true;
        } else {
          return true;
        }
      })
    );
  };

  const allFieldsValid = checkAllFieldsValid();

  const getEditComponentsFromTemplate = uri => {
    return templates.get(uri).passages.map((passage, index) => {
      if (passage.type === 'FIXED') {
        return (
          <span className={componentStyles.targetText} key={`passage_${index}`}>
            {index === 0 ? capitalize(passage.text) : passage.text}
          </span>
        );
      } else if (passage.type === 'TEXT') {
        return (
          <span className={componentStyles.argumentText} key={`passage_${index}`}>
            {index === 0 ? capitalize(passage.text) : passage.text}
          </span>
        );
      } else if (passage.type === 'VARIABLE') {
        return (
          <TextField
            layout={layouts.veezoo}
            customClasses={{
              'MuiTextField-root': {
                width: 'fit-content'
              }
            }}
            key={`passage_${index}`}
            type={passage.dataType.toLowerCase()}
            value={argumentValues.get(passage.argumentIndex) || ''}
            onFocus={() => {}}
            onChange={e => onChange(passage.argumentIndex, e.target.value)}
            onKeyDown={() => {}}
            error={false}
            data-test="input-argument"
          />
        );
      }
    });
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  return (
    <div className={clsx(popoverStyles.container, popoverStyles.width400)}>
      <div className={clsx(popoverStyles.section, popoverStyles.title, componentStyles.titleBar)}>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <div>
              <span className={popoverStyles.innerTitle}>{title}</span>
            </div>
            <div>
              <Select
                layout={layouts.veezoo}
                sx={computationSelectSx}
                options={availableComputations}
                value={selectedComputation}
                onChange={e => setSelectedComputation(e.target.value)}
                data-test="selector"
              />
            </div>
          </>
        )}
      </div>
      <>
        <hr className={popoverStyles.hr} />
        <div className={componentStyles.templateContainer}>
          {isLoading ? <Skeleton /> : getEditComponentsFromTemplate(selectedComputation)}
        </div>
        <div className={clsx(popoverStyles.section, popoverStyles.flexContainer)}>
          <div className={clsx(popoverStyles.verticalPadding8, popoverStyles.flexGrow1)}>
            <Button layout={layouts.veezoo} fullWidth onClick={onCancel}>
              {t('cancel')}
            </Button>
          </div>
          <div className={clsx(popoverStyles.verticalPadding8, popoverStyles.flexGrow1)}>
            <Button
              layout={layouts.veezoo}
              mode="dark"
              fullWidth
              onClick={handleConfirm}
              disabled={!allFieldsValid}
              data-test="modification-apply"
            >
              {t('apply')}
            </Button>
          </div>
        </div>
      </>
    </div>
  );
};

export default withTranslation('veezoo')(TemplateFilter);
