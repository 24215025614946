import services from 'services';

export const MODIFY_VISUALIZATION_REQUEST = 'MODIFY_VISUALIZATION_REQUEST';
export const MODIFY_VISUALIZATION_SUCCESS = 'MODIFY_VISUALIZATION_SUCCESS';
export const MODIFY_VISUALIZATION_FAILURE = 'MODIFY_VISUALIZATION_FAILURE';

export const DISCARD_LAST_MODIFIED_VISUALIZATION = 'DISCARD_LAST_MODIFIED_VISUALIZATION';

export const modifyVisualizationLlm = ({
  partialAnswerId,
  visualizationType,
  inputText,
  theme,
  currentChartOptions,
  previousInputTexts
}) => {
  return dispatch => {
    dispatch({ type: MODIFY_VISUALIZATION_REQUEST, partialAnswerId, visualizationType });
    const payload = {
      partialAnswerId,
      visualizationType,
      inputText,
      theme,
      currentChartOptions,
      previousInputTexts
    };
    services
      .modifyVisualization(payload)
      .then(result => {
        if (result.success && result.data?.chartOverride) {
          return result.data.chartOverride;
        } else {
          dispatch({
            type: MODIFY_VISUALIZATION_FAILURE,
            partialAnswerId,
            visualizationType
          });
          const errorMessage = result?.data?.error || result.statusText;
          throw Error(errorMessage);
        }
      })
      .then(chartOverride => {
        dispatch({
          type: MODIFY_VISUALIZATION_SUCCESS,
          chartOverride,
          mergeWithPrevious: true,
          inputText,
          partialAnswerId,
          visualizationType
        });
      })
      .catch(error => console.log('ERROR', error));
  };
};

export const discardLastModifiedVisualization = ({ partialAnswerId, visualizationType }) => {
  return {
    type: DISCARD_LAST_MODIFIED_VISUALIZATION,
    partialAnswerId,
    visualizationType
  };
};

export const modifiedVisualizations = (state = [], action) => {
  switch (action.type) {
    case MODIFY_VISUALIZATION_REQUEST:
      return state;

    case MODIFY_VISUALIZATION_SUCCESS:
      return [
        ...state,
        {
          partialAnswerId: action.partialAnswerId,
          visualizationType: action.visualizationType,
          inputText: action.inputText,
          chartOverride: action.chartOverride,
          mergeWithPrevious: action.mergeWithPrevious
        }
      ];

    case MODIFY_VISUALIZATION_FAILURE:
      return state;

    case DISCARD_LAST_MODIFIED_VISUALIZATION:
      // Iterate backward to find the last item that matches the criteria
      for (let i = state.length - 1; i >= 0; i--) {
        if (
          state[i].partialAnswerId === action.partialAnswerId &&
          state[i].visualizationType === action.visualizationType
        ) {
          return [...state.slice(0, i), ...state.slice(i + 1)];
        }
      }
      return state; // Return the original state if no match found

    default:
      return state;
  }
};
