import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CheckCircle from '@mui/icons-material/CheckCircle';

import Button from '@material-ui/core/Button';

import styles from './index.module.scss';

const ResultDialog = ({ uploadResult, handleClose }) => {
  const messages =
    uploadResult &&
    (uploadResult === 'Failed' ? (
      <span className={styles.failure}>Import failed before uploading any sub-graphs</span>
    ) : (
      <List>
        {uploadResult.messages.map((content, index) => {
          return (
            <ListItem key={index}>
              <div className={styles.listItem}>
                {content.description && <span className={styles.description}>{content.description}</span>}
                <br />
                <div className={styles.messageContainer}>
                  {content.message && <span className={styles.message}>{content.message}</span>}
                  {content.violatedConstraints && (
                    <ul className={styles.ul}>
                      {content.violatedConstraints.map((constraint, index) => (
                        <li className={styles.message} key={`constraint_${index}`}>
                          Table <span>{constraint.table}</span>, column <span>{constraint.column}</span>;
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <ListItemSecondaryAction>
                {content.isSuccess && (
                  <span className={styles.success}>
                    <CheckCircle size={25} />
                  </span>
                )}

                {!content.isSuccess && <span className={styles.failure}>Failure</span>}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    ));

  return (
    <Dialog open={!!uploadResult} onClose={handleClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <div className={styles.title}>Import Result</div>
      <DialogContent dividers={true}>{messages}</DialogContent>
      <DialogActions>
        <Button className={styles.button} onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResultDialog;
