import React, { useMemo, useCallback, useRef, memo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import NodeEditorPopover from 'components/NodeEditorPopover';
import { InfoTooltip } from 'components/v3';
import SmartFixIndicator from 'components/SmartFix/SmartFixIndicator';

import ListItem from '@material-ui/core/ListItem';

import { availableGroups, OntologyClass } from 'store/utils/knowledgeGraphOntologyIcons';
import { setEntitiesSidebar, updateNode } from 'store/modules/graph/graph';

import { trackEvent } from 'utils/eventTracking';

import { graphNodeTypes } from 'config/constants';

import styles from './styles.scss';
import { ExpandLess, ExpandMore, ViewHeadlineRounded } from '@material-ui/icons';
import TableIcon from 'svg/table.svg';
import { SvgIcon } from '@material-ui/core';

const SidebarListItem = ({
  sidebarNode,
  uniqueId,
  entitiesSidebar,
  level,
  isExpandable,
  onExpandClick,
  onShowDataClick,
  isExpanded,
  isEmbedded,
  numberOfEntityMatches,
  searchTerm,
  forceOpen,
  onOpened,
  onClosed,
  hasWritePermission,
  suggestionsForBadNames,
  dispatch,
  t
}) => {
  const isSearching = searchTerm?.length > 0;
  const infoPanelRef = useRef({ close: null });
  const IconComponent = useMemo(
    () => availableGroups[sidebarNode.ontologyType]?.componentIcon || availableGroups[OntologyClass].componentIcon,
    [sidebarNode]
  );
  const isChild = level > 0;
  const isExpandableChild = isExpandable && isChild;
  const expandableChildMargin = isExpandableChild ? { marginLeft: '10px' } : {};
  const subClassPadding = level === 2 ? { paddingLeft: '47px' } : {};
  const subClassIconMargin = isExpandableChild ? { marginLeft: '0' } : {};
  const listItemHoverStyle = useMemo(
    () => (entitiesSidebar.isOpen && entitiesSidebar.uniqueId === uniqueId ? styles.sidebarItemHover : ''),
    [sidebarNode?.uri, entitiesSidebar.isOpen, entitiesSidebar.uri]
  );

  const hasBadName = sidebarNode.uri in suggestionsForBadNames;

  const toggleEntitiesSidebar = useCallback(
    event => {
      event.stopPropagation();
      const { name, uri, ontologyType } = sidebarNode;

      const openSidebar = () => {
        trackEvent('KG Entities Sidebar Opened', { nodeName: name, uri });
        if (infoPanelRef.current.close) {
          infoPanelRef.current.close();
        }
        dispatch(
          setEntitiesSidebar({
            isEmbedded: isEmbedded,
            isOpen: true,
            name,
            uri,
            uniqueId,
            ontologyType,
            searchTerm: searchTerm,
            inputField: searchTerm
          })
        );
      };

      if (!entitiesSidebar.isOpen) {
        openSidebar();
      } else {
        if (entitiesSidebar.uri !== uri) {
          dispatch(setEntitiesSidebar({ isOpen: false }));
          setTimeout(() => openSidebar(), [15]);
        }
        dispatch(setEntitiesSidebar({ isOpen: false }));
      }
    },
    [sidebarNode, entitiesSidebar.isOpen, entitiesSidebar.uri, uniqueId, dispatch, isEmbedded]
  );

  const handleShowDataClick = e => {
    e.stopPropagation(); // otherwise the expand gets triggered when clicking it
    onShowDataClick(e);
  };

  const handleExpandClick = e => {
    e.stopPropagation(); // otherwise the expand gets triggered when clicking it
    onExpandClick(e);
  };

  const button = (
    <ListItem
      button
      key={uniqueId}
      className={clsx(
        !isExpandable ? styles.kgSidebarItemNonClickable : styles.kgSidebarItem,
        isChild ? styles.kgSidebarChildItem : styles.kgSidebarParentItem,
        listItemHoverStyle
      )}
      style={subClassPadding}
      onClick={handleExpandClick}
      data-test={isChild ? 'KnowledgeGraphSidebarChildConceptItem' : 'KnowledgeGraphSidebarConceptItem'}
    >
      {isExpandable &&
        (isExpanded ? (
          <ExpandLess className={styles.expandButton} onClick={handleExpandClick} style={expandableChildMargin} />
        ) : (
          <ExpandMore className={styles.expandButton} onClick={handleExpandClick} style={expandableChildMargin} />
        ))}
      {!isExpandable && <IconComponent className={styles.kgSidebarIcon} style={subClassIconMargin} />}
      <div
        className={isChild ? styles.kgSidebarChildItemText : styles.kgSidebarParentItemText}
        data-test="KnowledgeGraphSidebarConceptItemName"
      >
        <span className={styles.ellipsisSpan}>
          <span className={styles.innerSpan}>{sidebarNode.sidebarName || sidebarNode.name}</span>
        </span>
      </div>

      {!isChild && (
        <InfoTooltip text={t('entities-sidebar.show-table')} placement="top">
          <div className={styles.hiddenListIcon} onClick={handleShowDataClick}>
            <SvgIcon component={TableIcon} viewBox={TableIcon().props.viewBox} style={{ height: 11 }} />
          </div>
        </InfoTooltip>
      )}
      {numberOfEntityMatches > 0 && (
        <InfoTooltip text={t('entities-sidebar.show-matches')} placement="top">
          <div className={styles.chipButton} onClick={toggleEntitiesSidebar}>
            {numberOfEntityMatches}
          </div>
        </InfoTooltip>
      )}
      {sidebarNode?.type === graphNodeTypes.CLASS && !isSearching && (
        <InfoTooltip text={t('entities-sidebar.search-unique-values')} placement="top">
          <div className={styles.hiddenListIcon} onClick={toggleEntitiesSidebar}>
            <ViewHeadlineRounded />
          </div>
        </InfoTooltip>
      )}

      {hasWritePermission && hasBadName && (
        <div>
          <SmartFixIndicator />
        </div>
      )}
    </ListItem>
  );

  const ontologyTypeTranslationKey =
    sidebarNode.ontologyType && availableGroups[sidebarNode.ontologyType]?.translationKey;
  const subtitleText = ontologyTypeTranslationKey && t(`ontology.${ontologyTypeTranslationKey}`);

  return (
    <NodeEditorPopover
      uniqueId={uniqueId}
      PopoverProps={{
        button,
        forceOpen,
        onOpened,
        onClosed,
        preventOpening: entitiesSidebar.isOpen,
        enableHover: !forceOpen,
        buttonSelectedClassName: styles.sidebarItemHover,
        closeFromOutside: handleClose => (infoPanelRef.current.close = handleClose)
      }}
      ContentProps={{
        subtitleText,
        node: sidebarNode,
        subtitleIcon: IconComponent,
        updateNode: updateNode,
        nodeType: sidebarNode.type,
        expandedByDefault: true
      }}
    />
  );
};

const mapStateToProps = (state, props) => ({
  entitiesSidebar: state.graph.entitiesSidebar,
  hasWritePermission: state.knowledgeGraphMeta.meta.hasWritePermission,
  suggestionsForBadNames: state.recipes.suggestionsForBadNames
});

export default withTranslation('veezoo')(connect(mapStateToProps)(memo(SidebarListItem)));
