import { post } from 'services/http';

const modifyVisualization = ({
  partialAnswerId,
  visualizationType,
  inputText,
  theme,
  currentChartOptions,
  previousInputTexts
}) => {
  const fullPayload = {
    partialAnswerId,
    visualizationType,
    inputText,
    theme,
    currentChartOptions,
    previousInputTexts,
    sessionId: window.veezoo.sessionId
  };
  return post(`/answers/partials/${partialAnswerId}/visualizations/edit`, JSON.stringify(fullPayload));
};

export default { modifyVisualization };
