import { makeStyles } from 'tss-react/mui';

import { useTheme } from '@mui/material/styles';

const fontFamily = 'var(--font-family)';
const highlightedSignup = 'var(--light-blue)';

export const useComponentStyles = makeStyles()(() => ({
  labelForEmptyValue: {
    opacity: 0.5
  }
}));

const menuItemCommonStyles = {
  display: 'flex !important',
  justifyContent: 'flex-start !important',
  padding: '6px 16px !important',
  '&.Mui-selected': {
    backgroundColor: 'rgba(0, 0, 0, 0.08) !important'
  }
};

export const useMenuItemStyles = () => ({
  signup: {
    ...menuItemCommonStyles,
    fontSize: '16px',
    fontFamily
  },
  veezoo: {
    ...menuItemCommonStyles,
    fontSize: '12px !important',
    fontFamily
  },
  default: { ...menuItemCommonStyles }
});

export const useSelectStyles = ({ isMobile, highlighted, disabled, noBorders, tooltip, sx }) => {
  const theme = useTheme();

  return {
    signup: {
      '&.MuiTextField-root': {
        width: '100%'
      },
      '& .MuiSelect-select': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontFamily,
        fontSize: isMobile ? '16px' : '20px',
        color: highlighted ? highlightedSignup : 'inherit',
        padding: '6px 0',
        display: 'flex',
        alignItems: 'center'
      },
      '& .MuiInputLabel-root': {
        fontFamily,
        fontSize: isMobile ? '16px' : '20px',
        whiteSpace: 'nowrap',
        '&&+.MuiInput-formControl': {
          marginTop: '16px'
        }
      },
      '& .MuiInput-underline:before': {
        borderBottom: disabled ? 'solid thin var(--disabled-color)' : 'solid thin black'
      },
      '& .MuiFormHelperText-root': {
        fontSize: '12px'
      },
      '& .MuiFormHelperText-contained': {
        display: 'flex',
        alignItems: 'center'
      },
      '& .MuiInput-input.Mui-disabled': {
        color: highlighted ? highlightedSignup : 'rgba(0, 0, 0, 0.18)',
        WebkitTextFillColor: highlighted ? highlightedSignup : 'rgba(0, 0, 0, 0.18)',
        ...(disabled ? { disabled } : {})
      },

      '& .MuiSelect-icon': {
        top: 'initial',
        width: '13.5px',
        height: '13.5px',
        marginRight: '4px',
        '& > path': {
          fill: ({ disabled }) => (disabled ? 'var(--disabled-color)' : 'var(--primary-color)')
        }
      }
    },
    veezoo: {
      '&.MuiTextField-root': {
        ...(sx['&.MuiTextField-root'] ? { ...sx['&.MuiTextField-root'] } : {})
      },
      '& .MuiOutlinedInput-input': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontFamily,
        fontSize: '14px',
        padding: noBorders ? '0' : '8px 12px',
        color: 'inherit',
        lineHeight: '16px',
        height: noBorders ? '20px' : '32px',
        boxSizing: 'border-box',
        ...(theme.isDark || noBorders ? {} : { backgroundColor: 'white' }),
        '&:focus': {
          ...(noBorders ? { backgroundColor: 'transparent' } : {})
        }
      },
      '& .MuiInputLabel-root': {
        fontFamily,
        fontSize: '12px',
        fontWeight: 400
      },
      '& .MuiOutlinedInput-root': {
        marginTop: noBorders ? 0 : '4px'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        top: 0,
        border: noBorders ? 'solid 0px transparent' : 'solid thin var(--light-gray)',
        borderRadius: '4px',
        '& > legend': {
          display: 'none'
        }
      },
      ...(noBorders
        ? {
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent !important',
              borderWidth: '0 !important'
            }
          }
        : {}),
      '& .MuiInputLabel-shrink': {
        transform: 'none !important',
        position: 'static'
      },
      '& .MuiFormHelperText-root': {
        fontSize: '12px'
      },

      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: 'transparent'
      },
      '& .MuiSelect-icon': {
        top: 'initial',
        width: '13.5px',
        height: '13.5px',
        marginRight: '4px',
        '& > path': {
          fill: ({ disabled }) => (disabled ? 'var(--disabled-color)' : 'var(--primary-color)')
        }
      }
    },
    default: {
      '& .MuiOutlinedInput-input': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: ({ highlighted }) => (highlighted ? theme.palette.primary.main : 'inherit'),
        padding: '8.5px 14px',
        height: '1.4375em',
        display: 'flex',
        alignItems: 'center'
      },
      '& .MuiOutlinedInput-input.Mui-disabled': {
        backgroundColor: 'transparent',
        ...(highlighted ? { color: theme.palette.primary.main, WebkitTextFillColor: theme.palette.primary.main } : {})
      },
      '& .MuiOutlinedInput-root': {
        ...(theme.isDark ? {} : { backgroundColor: 'white' })
      },
      '& .MuiFormHelperText-contained': {
        ...(tooltip ? { marginLeft: 0 } : {}),
        display: 'flex',
        alignItems: 'center'
      },
      '& .MuiInput-root.Mui-disabled': {
        backgroundColor: 'transparent',
        ...(highlighted ? { color: theme.palette.primary.main } : {})
      },
      '& .MuiSelect-icon': {
        top: 'initial',
        width: '13.5px',
        height: '13.5px',
        marginRight: '4px',
        '& > path': {
          fill: ({ disabled }) => (disabled ? 'var(--disabled-color)' : 'var(--primary-color)')
        }
      }
    }
  };
};
