import { withTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/v3';

import Button from './Button';
import { trackEvent } from 'utils/eventTracking';
import { addLocalMessage } from 'store/modules/chat-messages';
import PropTypes from 'prop-types';

const SmartActionButton = ({ action, iconClassName, t, dispatch }) => (
  <InfoTooltip text={t('tooltip.smart-action')}>
    <Button
      className="button-secondary"
      onClick={() => {
        trackEvent('Smart Action', { action });
        if (action.openInNewTab) {
          window.open(action.url);
        } else {
          dispatch(addLocalMessage('veezoo', action.title, [], undefined, false, action.url));
        }
      }}
      text={action.title}
      data-test="smartActionListButton"
    >
      <span className={iconClassName} />
    </Button>
  </InfoTooltip>
);

export default withTranslation('veezoo')(SmartActionButton);

SmartActionButton.propTypes = {
  action: PropTypes.object,
  iconClassName: PropTypes.string,
  dispatch: PropTypes.func,
  t: PropTypes.func
};
