import React, { useMemo, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/core';

import { Button } from 'components/v3';
import MilestonesChecklist from 'components/MilestonesChecklist';

import BaseMessage from './BaseMessage';

import { signout } from 'store/modules/network';
import { runOverviewTutorial } from 'store/modules/tutorial';

import { openSupportMailWithFreshId } from 'utils/supportMail';
import useMediaQuery from 'utils/mediaQueries';

import ChatIcon from 'svg/chat.svg';
import PlayIcon from 'svg/play.svg';
import KnowledgeGraphIcon from 'svg/knowledge-graph.svg';
import SupportIcon from 'svg/support.svg';
import QuestionIcon from 'svg/question-white.svg';
import AddIcon from 'svg/plus_4.svg';

import styles from './welcome-message.scss';
import withEmbedded from 'root/hocs/withEmbedded';
import { changeUrlGivenCurrentQueryParams } from 'components/LinkWithQuery';
import { possibleStates } from 'config/constants';
import { useSpecialLinkHandler } from './commonFunctions';

const muiStyles = {
  root: {
    marginRight: '15px',
    height: '40px',
    fontSize: '16px'
  },
  titleSkeleton: {
    marginBottom: 15,
    width: '100%',
    maxWidth: 300,
    height: 35
  },
  descriptionSkeleton: {
    marginBottom: 20,
    width: '100%',
    maxWidth: 520,
    height: 25
  }
};

const TitleSkeleton = props => <Skeleton variant="rect" {...props} />;
const DescriptionSkeleton = props => <Skeleton variant="rect" {...props} />;

const lightButtonCss = {
  root: {
    border: 'solid thin var(--accent-very-light-color)',
    backgroundColor: 'var(--accent-very-light-color)',
    minWidth: 'auto'
  }
};

const WelcomeMessage = ({
  meta,
  t,
  isFetching,
  user,
  isOpenDemoUser,
  loadingLanguage,
  classes,
  dispatch,
  isEmbedded,
  shouldShowDiscoveryButton
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery();
  const welcomeHtmlRef = useRef(null);
  useSpecialLinkHandler(welcomeHtmlRef, user.username, dispatch, meta.htmlDescription, t);

  const showOverviewTutorial = () => dispatch(runOverviewTutorial());

  const logoutAndGoToSignup = () => dispatch(signout(true));

  const getTitleAccordingToLocalTime = userName => {
    // maps wall clock hours to translation keys (for example, at 05:00 use "early", and at 10:00 use "morning")
    const titleKeysForLocalHours = [
      [0, 'night'],
      [5, 'early'],
      [8, 'morning'],
      [12, 'lunch'],
      [13, 'afternoon'],
      [17, 'evening'],
      [22, 'late']
    ];

    // get the wall clock hour (an integer between 0 and 23) according to the browser's local time zone
    const hoursLocalTime = new Date().getHours();

    // find the bucket corresponding to the wall clock hour
    let idx = titleKeysForLocalHours.length - 1;
    while (idx > 0 && titleKeysForLocalHours[idx][0] > hoursLocalTime) {
      idx--;
    }
    const key = `welcome-message.title.${titleKeysForLocalHours[idx][1]}`;
    return t(key, { user: userName });
  };

  const content = useMemo(
    () => (
      <>
        <header data-test="welcomeMessage" data-kgid={meta.id}>
          {loadingLanguage || isFetching ? (
            <>
              <TitleSkeleton classes={{ root: classes.titleSkeleton }} />
              <DescriptionSkeleton classes={{ root: classes.descriptionSkeleton }} />
            </>
          ) : (
            <>
              <h2 className={styles.title}>{getTitleAccordingToLocalTime(user.username)}</h2>
              <p
                ref={welcomeHtmlRef}
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: isFetching
                    ? '&nbsp;'
                    : meta.htmlDescription || t('welcome-message.default-description', { name: meta.name })
                }}
              />
            </>
          )}

          {!isMobile && <MilestonesChecklist t={t} />}
        </header>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonGroup}>
            {shouldShowDiscoveryButton && !isMobile && (
              <div className={styles.buttonBox}>
                <Button
                  layout="veezoo"
                  size="small"
                  mode="dark"
                  icon={<ChatIcon className={classes.buttonIcon} />}
                  onClick={() => changeUrlGivenCurrentQueryParams(history, { showDialogue: possibleStates.discovery })}
                  data-test="welcomeMessageDiscoveryButton"
                >
                  {t('welcome-message.what-can-i-ask')}
                </Button>
              </div>
            )}
            {meta.hasKnowledgeGraphSupport && !isMobile && (
              <div className={styles.buttonBox}>
                <Button
                  layout="veezoo"
                  size="small"
                  mode="dark"
                  icon={<KnowledgeGraphIcon className={classes.buttonIcon} />}
                  onClick={() => {
                    const urlParams = isEmbedded
                      ? { showDialogue: possibleStates.knowledgeGraph }
                      : { kgSidebar: true, visualMode: true };
                    changeUrlGivenCurrentQueryParams(history, urlParams);
                  }}
                >
                  {t('welcome-message.what-data-is-there')}
                </Button>
              </div>
            )}
          </div>
          {isOpenDemoUser && (
            <div className={styles.buttonBox}>
              <Button
                layout="veezoo"
                size="small"
                mode="dark"
                icon={<AddIcon className={classes.buttonIcon} />}
                onClick={logoutAndGoToSignup}
              >
                {t('connect-your-data')}
              </Button>
            </div>
          )}
          {!isEmbedded && (
            <div className={styles.buttonGroup}>
              <div className={styles.buttonBox}>
                <Button
                  layout="veezoo"
                  size="small"
                  classes={lightButtonCss}
                  icon={<PlayIcon className={classes.buttonIcon} />}
                  onClick={showOverviewTutorial}
                >
                  Tutorial
                </Button>
              </div>
              <div className={styles.buttonBox}>
                <Button
                  layout="veezoo"
                  size="small"
                  classes={lightButtonCss}
                  icon={<SupportIcon className={classes.buttonIcon} />}
                  onClick={() => openSupportMailWithFreshId(meta.htmlSupport, user.username)}
                >
                  {t('welcome-message.support')}
                </Button>
              </div>
              <div className={styles.buttonBox}>
                <Button
                  layout="veezoo"
                  size="small"
                  classes={lightButtonCss}
                  icon={<QuestionIcon className={classes.buttonIcon} />}
                  onClick={undefined}
                  href={meta.linkToDocumentation}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Docs
                </Button>
              </div>
            </div>
          )}
        </div>
      </>
    ),
    [
      meta.id,
      meta.htmlDescription,
      meta.title,
      meta.name,
      loadingLanguage,
      isFetching,
      isMobile,
      isOpenDemoUser,
      shouldShowDiscoveryButton,
      t
    ]
  );

  return <BaseMessage content={content} enableStandardFooter={false} />;
};

const mapStateToProps = state => ({
  meta: state.knowledgeGraphMeta.meta,
  shouldShowDiscoveryButton:
    (state.discovery.topics && state.discovery.topics?.length > 0) ||
    (state.knowledgeGraphMeta.meta.hasWritePermission && state.user.isLlmParserEnabled),
  user: state.user,
  isFetching: state.knowledgeGraphMeta.isFetching,
  isOpenDemoUser: state.user.isOpenDemoUser,
  loadingLanguage: state.user.loadingLanguage
});

export default withTranslation('veezoo', { withRef: true })(
  connect(mapStateToProps)(withStyles(muiStyles)(withEmbedded(memo(WelcomeMessage))))
);

WelcomeMessage.propTypes = {
  meta: PropTypes.object,
  user: PropTypes.object,
  classes: PropTypes.object,
  t: PropTypes.func,
  isFetching: PropTypes.bool,
  loadingLanguage: PropTypes.bool,
  isOpenDemoUser: PropTypes.bool,
  shouldShowDiscoveryButton: PropTypes.bool,
  isEmbedded: PropTypes.bool
};
