import { memo } from 'react';
import PropTypes from 'prop-types';

import WarningIcon from 'svg/warning.svg';
import LoadingIcon from 'svg/v2/loading.svg';
import PencilEditIcon from 'svg/v2/pencil_edit.svg';

import { InfoTooltip } from 'components/v3';

import styles from './styles.scss';

const EditIcon = ({ loading, warning }) => {
  if (loading) return <LoadingIcon data-test="editLoader" className={styles.loadingIcon} />;
  if (warning)
    return (
      <InfoTooltip text="An error ocurred while saving." placement="right">
        <div>
          <WarningIcon className={styles.warningIcon} />
        </div>
      </InfoTooltip>
    );

  return <PencilEditIcon className={styles.pencilIcon} />;
};

export default memo(EditIcon);

EditIcon.propTypes = {
  loading: PropTypes.bool,
  warning: PropTypes.bool
};

EditIcon.defaultProps = {
  loading: false,
  warning: false
};
