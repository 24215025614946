import { memo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  messageTypes,
  fetchMessage,
  addLocalMessage,
  addNegativeFeedbackMessage,
  askLocalQuestion,
  fetchRetriedQuestion
} from 'store/modules/chat-messages';
import { finishedTutorial, completeFirstAccessTutorial } from 'store/modules/tutorial';
import { giveFeedBackComment } from 'store/modules/recentFeedback';
import useMediaQuery from 'utils/mediaQueries';
import { tutorialNames } from 'config/constants';

import QuestionPills, {
  DATA_WRONG_PILL,
  IS_FOLLOW_UP_PILL,
  MISUNDERSTOOD_PILL,
  SHITTY_VISUALISATION_PILL,
  TRY_AGAIN_PILL
} from 'components/pills/QuestionPills';

import Input from 'components/input/Input';
import FollowUpInfo from 'components/pills/FollowUpInfo';

const MessageComposer = ({
  messages,
  followUpState,
  username,
  recentFeedback,
  boardWidgets,
  isLastMessageHidden,
  hasFinishedFirstAccessTutorial,
  uncompletedTutorials,
  dispatch,
  t
}) => {
  const isMobile = useMediaQuery();

  const standardFeedback = text => {
    dispatch(addNegativeFeedbackMessage('veezoo', recentFeedback));
    dispatch(giveFeedBackComment(recentFeedback.answerId, text));
  };

  const reAskWithFollowUp = localQuestionId => {
    dispatch(addLocalMessage('veezoo', t('thanks-for-feedback-follow-up')));

    // find local message of this answer id
    const questionMessage = messages.find(msg => msg.id === localQuestionId);
    const question = questionMessage.question; // eslint-disable-line
    const linkedEntities = questionMessage.linkedEntities; // eslint-disable-line

    // this is messy, do it nicer
    let previousQuestionIndex = messages.indexOf(questionMessage) - 1;
    let previousInterpretationId, previousAnswerId;
    while (previousQuestionIndex >= 0) {
      const msg = messages[previousQuestionIndex];
      if (msg.type === messageTypes.VEEZOO_ANSWER_MESSAGE && !msg.isComplementary) {
        previousInterpretationId = msg.answer.interpretationId;
        previousAnswerId = msg.answerId;
        break;
      }
      previousQuestionIndex -= 1;
    }

    // Fetch and display the answer.
    dispatch(
      fetchMessage(
        question,
        localQuestionId,
        {
          linkedEntities,
          referenceTo: { interpretationId: previousInterpretationId, answerId: previousAnswerId }
        },
        t
      )
    );
  };

  const dispatchMessageAndReset = (
    message,
    isQuestionPill,
    isLlmSuggestion,
    isSuggested,
    isVoice,
    linkedEntities,
    answerId,
    interpretationId
  ) => {
    // don't dispatch an empty message
    if (message === '') return true;
    if (recentFeedback.wasNegative) return standardFeedback(message);

    let referenceTo = null;
    let disableFollowUpParam = {};

    // if reference to answerId and possibly interpretationId exists for pill
    if (answerId !== undefined) {
      referenceTo = {
        answerId,
        interpretationId
      };
    } else if (followUpState) {
      // if question is followUp
      referenceTo = followUpState;
    } else if (isLastMessageHidden) {
      // if last message is hidden and we're not in an explicit follow-up
      disableFollowUpParam = { disableFollowUp: true };
    }

    const otherParams = {
      isQuestionPill,
      isLlmSuggestion,
      isSuggested,
      isVoice,
      linkedEntities,
      referenceTo,
      ...disableFollowUpParam
    };

    dispatch(askLocalQuestion(username, message, otherParams, t));
  };

  const handleQuestionPillClick = suggestion => {
    switch (suggestion.type) {
      case SHITTY_VISUALISATION_PILL:
      case DATA_WRONG_PILL:
      case MISUNDERSTOOD_PILL:
        // for now just give the standard feedback (and log it)
        standardFeedback(suggestion.text);
        break;

      case IS_FOLLOW_UP_PILL:
        dispatch(giveFeedBackComment(recentFeedback.answerId, suggestion.text));
        reAskWithFollowUp(recentFeedback.messageId);
        break;

      case TRY_AGAIN_PILL:
        dispatch(giveFeedBackComment(recentFeedback.answerId, suggestion.text));
        dispatch(fetchRetriedQuestion(recentFeedback.questionId, recentFeedback.localQuestionId, t));
        break;

      // ask as if it would be a normal question
      default:
        if (suggestion.referenceTo && suggestion.referenceTo.answerId !== undefined) {
          // if pill is follow-up grab references from follow-up pill
          dispatchMessageAndReset(
            suggestion.text,
            true,
            suggestion.isLlmSuggestion,
            false,
            false,
            suggestion.linkedPassages,
            suggestion.referenceTo.answerId,
            suggestion.referenceTo.interpretationId
          );
        } else {
          // if pill is not any follow-up
          dispatchMessageAndReset(
            suggestion.text,
            true,
            suggestion.isLlmSuggestion,
            false,
            false,
            suggestion.linkedPassages
          );
        }
    }
  };

  const onSubmit = (message, isSuggestion, isVoice, linkedEntities) => {
    dispatchMessageAndReset(message, false, false, isSuggestion, isVoice, linkedEntities);
  };

  const dismissFirstAccessTutorial = () => {
    if (!hasFinishedFirstAccessTutorial) {
      dispatch(completeFirstAccessTutorial());
    }
    if (uncompletedTutorials.some(tutorial => tutorial.id === tutorialNames.OVERVIEW_TUTORIAL)) {
      dispatch(finishedTutorial(tutorialNames.OVERVIEW_TUTORIAL, true));
    }
  };

  return (
    <div
      id="message-composer"
      data-overview-tutorial="message-composer"
      data-whatcaniask-tutorial="message-composer"
      onClick={dismissFirstAccessTutorial}
    >
      {followUpState && (
        <FollowUpInfo boardWidgets={boardWidgets} followUpState={followUpState} messages={messages} t={t} />
      )}
      <QuestionPills onClick={handleQuestionPillClick} />
      <Input onSubmit={onSubmit} isMobile={isMobile} />
    </div>
  );
};

const mapStateToProps = state => ({
  username: state.user.username,
  hasFinishedFirstAccessTutorial: state.tutorial.hasFinishedFirstAccessTutorial,
  uncompletedTutorials: state.tutorial.uncompletedTutorials,
  messages: state.chatMessages,
  isLastMessageHidden: state.chatMessages[state.chatMessages.length - 1]?.type === messageTypes.USER_HIDDEN_MESSAGE,
  boardWidgets: state.boardWidgets,
  followUpState: state.followUpState,
  recentFeedback: state.recentFeedback,
  suggestions: state.recentFeedback.wasNegative ? [] : state.inputAnalysis.suggestions // disable autocomplete if in neg. feedback mode
});

export default withTranslation('veezoo')(connect(mapStateToProps)(memo(MessageComposer)));
